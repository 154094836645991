<template>
  <TheSection
    label="Save As Copy"
    help="With the Saved Modules, you can save a module specific customizations and use them again in different pages."
  >
    <Unsaved>
      <ValidationObserver ref="form">
        <div class="pm-space-y-3 pm-p-3">
          <ValidationProvider name="Name" rules="required">
            <PField label="Name">
              <PTextbox
                v-model="alias"
                placeholder="e.g. My Fav Module"
                theme="underline"
              />
            </PField>
          </ValidationProvider>

          <PButton
            size="sm"
            :loading="saving"
            label="Save"
            theme="gray"
            @click.native="save"
          />
        </div>
      </ValidationObserver>
    </Unsaved>
  </TheSection>
</template>

<script>
import TheSection from "../slideover/Section.vue";
import Unsaved from "./Unsaved.vue";
import saveModuleAsCopy from "@/gql/module/saveModuleAsCopy.gql";

export default {
  props: {
    moduleId: Number,
  },
  components: {
    TheSection,
    Unsaved,
  },
  data() {
    return {
      alias: null,
      saving: false,
    };
  },

  inject: ["PAGE_DATA"],

  methods: {
    async save() {
      const valid = await this.$refs.form.validate();
      if (!valid) return;

      try {
        this.saving = true;

        await this.$apollo.mutate(saveModuleAsCopy, {
          workspaceId: this.PAGE_DATA.workspaceId,
          moduleId: this.moduleId,
          alias: this.alias,
        });

        this.$notify({
          message: "Module saved as a copy.",
          type: "success",
        });
      } catch (err) {
        this.$sentry.catch(err);
        this.$notify({
          message:
            "There was an error while saving a module as a copy. Please refresh the page & try again.",
          type: "danger",
          duration: 0,
        });
        console.error(err);
      }

      this.saving = false;
    },
  },
};
</script>
