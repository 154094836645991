<template>
  <IAccordion title="Repeater" open>
    <INumber
      label="Items"
      :min="propValueFinder('min')"
      :max="propValueFinder('max')"
      prop="count"
      :side-effect="sideEffect"
    />
  </IAccordion>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      propValueFinder: "editor/activeSegmentPropValue",
    }),
  },

  methods: {
    sideEffect() {
      return {
        config: {
          refreshLayers: true,
        },
      };
    },
  },
};
</script>
