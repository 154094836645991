<template>
  <component
    :is="theTag"
    class="button"
    :type="theTag == 'button' ? 'button' : null"
    :class="classList"
  >
    <Icon class="button__loader" v-if="loading" name="MdiLoading"></Icon>
    <Icon class="button__icon" v-if="icon" :name="icon"></Icon>
    <span class="button__label">{{ label }}</span>
  </component>
</template>

<script>
export default {
  props: {
    label: String,
    icon: String,
    theme: {
      type: String,
      default: "primary",
    },
    loading: Boolean,
    size: {
      type: String,
      default: "md",
    },
    full: Boolean,
    tag: {
      type: String,
      default: "button",
    },
  },
  computed: {
    theTag() {
      if (this.$attrs.href) {
        return `a`;
      } else if (this.$attrs.for) {
        return `label`;
      } else {
        return this.tag;
      }
    },
    classList() {
      const classList = [`button--${this.theme}`, `button--${this.size}`];
      if (this.loading) classList.push(`button--loading`);
      if (this.full) classList.push(`button--full`);
      if (this.icon && !this.label) classList.push(`button--icon`);
      return classList;
    },
  },
};
</script>

<style lang="scss">
.button {
  background-color: var(--bg);
  color: var(--text);
  outline: none !important;
  @apply pm-inline-flex pm-items-center pm-justify-center pm-rounded-sm pm-font-bold pm-leading-none pm-relative pm-select-none;

  &:hover {
    background-color: var(--bgHover);
    color: var(--textHover);
  }

  &:disabled {
    @apply pm-cursor-not-allowed;
  }
}

.button__icon {
  @apply pm-mr-2;
}

.button__loader {
  @apply pm-animate-spin pm-absolute pm-inset-0 pm-m-auto;
}

.button--md {
  @apply pm-h-9 pm-text-xs pm-px-5;
  .icon {
    @apply pm-w-5 pm-h-5;
  }
}

.button--sm {
  @apply pm-h-7 pm-text-xxs pm-px-3;
  .icon {
    @apply pm-w-4 pm-h-4;
  }
}

.button--loading {
  .button__label,
  .button__icon {
    @apply pm-invisible;
  }
}

.button--full {
  @apply pm-w-full pm-justify-center;
}

.button--icon {
  @apply pm-p-0;
  .button__icon {
    @apply pm-mr-0;
  }
  &.button--md {
    @apply pm-w-9;
  }
  &.button--sm {
    @apply pm-w-7;
  }
}

.button--primary {
  --bg: theme("colors.primary.500");
  --bgHover: theme("colors.primary.700");
  --text: theme("colors.white");
  --textHover: theme("colors.white");
}

.button--warning {
  --bg: theme("colors.warning.500");
  --bgHover: theme("colors.warning.700");
  --text: theme("colors.white");
  --textHover: theme("colors.white");
}
.button--danger {
  --bg: theme("colors.danger.500");
  --bgHover: theme("colors.danger.700");
  --text: theme("colors.white");
  --textHover: theme("colors.white");
}

.button--gray {
  --bg: theme("colors.white");
  --bgHover: theme("colors.gray.100");
  --text: theme("colors.gray.500");
  --textHover: theme("colors.gray.500");
  @apply pm-border-gray-300 pm-border;
}
</style>
