<template>
  <div class="color-picker" :class="{ 'color-picker--wide': wide }">
    <div class="pm-divide-y pm-divide-gray-200">
      <!-- Custom color picker -->
      <IAccordion title="Custom Color" open>

        <!-- <p class="pm-text-sm pm-text-gray-500 pn-py-1">Custom Color</p> -->
        <label class="pm-cursor-pointer">
          <IuiColor :value="value" @update="update()">
            <template #color>
              <input @input="selectCustom" :value="colorCode" type="color"
                class="pm-absolute pm-opacity-0 pm-pointer-events-none" />
              <span class="color pm-h-7 pm-w-7 pm-inline-block pm-mr-2"
                :class="{ [`color--${selected?.color}`]: selected?.color, 'color--default': !selected }"
                :style="{ backgroundColor: colorCode }">
              </span>
            </template>
          </IuiColor>
        </label>

      </IAccordion>

      <!-- Brand -->
      <IAccordion title="Choose From Palette" open>

        <div>
          <!-- <p class="pm-text-sm pm-text-gray-500 pm-pb-1">Choose From Palette</p> -->
          <p class="pm-text-xs pm-text-gray-500">Brand Colors</p>
          <div v-for="(shades, color) in brandPalettes" :key="`color-${color}`">
            <div class="color-picker__palette">
              <ColorView v-for="(code, shade) in shades" :key="`${color}-${code}`" :label="`Brand - ${shade}`" :active="selected && selected.color == color && selected.shade == shade
    " @click.native="select(color, shade)" :value="`${color}-${shade}`" />
            </div>
          </div>
        </div>

        <!-- Palette -->
        <div class="pm-mt-2">
          <p class="pm-text-xs pm-text-gray-500">Color Palette</p>
          <div v-for="(shades, color) in palettes" :key="`color-${color}`">
            <!-- <p class="pm-text-xs">
              {{ $options.startCase(color) }}
            </p> -->
            <div class="color-picker__palette">
              <ColorView v-for="(code, shade) in shades" :key="`${color}-${code}`"
                :label="`${$options.startCase(color)} - ${shade}`" :active="selected && selected.color == color && selected.shade == shade
    " @click.native="select(color, shade)" :value="`${color}-${shade}`" />
            </div>
          </div>
        </div>

        <!-- Neutral -->
        <div class="pm-mt-2" v-if="neutral">
          <p class="pm-text-xs pm-text-gray-500">Neutral Colors</p>
          <div class="color-picker__palette">
            <ColorView v-for="color in $options.neutralColors" :key="color" :label="$options.startCase(color)"
              :active="selected && selected.color == color" @click.native="select(color)" :value="color" />
          </div>
        </div>

      </IAccordion>
    </div>
  </div>
</template>

<script>
import { startCase, debounce } from "lodash-es";
import colors from "@/config/colors";
import mixin from "./color";
import ColorView from "./ColorView";
import { IuiColor, IuiField } from "inspector-ui"


export default {
  mixins: [mixin],
  startCase,
  neutralColors: colors.neutral,

  components: {
    ColorView,
    IuiColor,
    IuiField
  },

  props: {
    shade: {
      type: Boolean,
      default: true,
    },
    neutral: {
      type: Boolean,
      default: true,
    },
    wide: {
      type: Boolean,
    },
  },
  data() {
    return {
      customColorTimer: null
    }
  },

  computed: {
    hasShade() {
      return (
        this.shade &&
        this.colors &&
        this.selected &&
        this.selected.color &&
        !this.$options.neutralColors.includes(this.selected.color)
      );
    },

    brandPalettes() {
      return {
        primary: this.colors.primary,
        secondary: this.colors.secondary,
      };
    },

    //Color with diffrent shades
    palettes() {
      const palette = {};
      for (var color in this.colors) {
        if (
          typeof this.colors[color] == "object" &&
          !["primary", "secondary"].includes(color)
        ) {
          palette[color] = this.colors[color];
        }
      }
      return palette;
    },
  },

  methods: {
    select(color, shade) {
      if (shade && this.shade) {
        this.$emit("input", color + "-" + shade);
      } else {
        this.$emit("input", color);
      }
    },
    selectCustom: debounce(function (e) {
      this.$emit("input", `[${e.target.value}]`);
    }, 500),
    update() {
      this.$emit("input");
    }
  },
};
</script>

<style lang="scss">
.color-picker {


  &--wide {
    .color-picker__palette {
      @apply pm-grid-cols-10;
    }
  }
}

.color-picker__palette {
  @apply pm-grid pm-grid-cols-10;
}
</style>
