<template>
  <div>
    <div class="pm-divide-y pm-border-b">
      <IntroSection
        icon="PhRocketLaunchDuotone"
        title="Let's Get Started."
        desc="When you select any layer from an editor, the customization options will appear here."
      />

      <IntroSection
        title="Keyboard Shortcuts"
        :action="shortcutHandler"
        icon="IcTwotoneKeyboardAlt"
        link-label="View Shortcuts"
        desc="Work faster with these keyboard shortcuts."
      />

      <IntroSection
        icon="PhDeviceMobileDuotone"
        title="Mobile First."
        desc="Being a mobile-first editor, you should design pages on mobile first and adjust design for larges pages."
      />

      <IntroSection
        icon="PhGearSixDuotone"
        title="Global Settings."
        desc="Colors & Fonts are saved globally so when you change it from settings, it will be updated everywhere."
      />

      <IntroSection
        icon="PhYoutubeLogoDuotone"
        title="Learn with YouTube."
        desc="We've created short videos for each features of Pagemaker to help you quickly learn."
        link-label="Visit Channel"
        url="https://www.youtube.com/pagemaker"
      />

      <IntroSection
        icon="PhUsersDuotone"
        title="Join Community."
        desc="Our Facebook group has 500+ members who're using Pagemaker regularly. Be a part of community to discuss & get quick help."
        link-label="Join Facebook Group"
        url="https://www.facebook.com/groups/pagemaker"
      />
    </div>
    <div class="pm-px-4 pm-py-2 pm-text-xxs pm-text-gray-500">
      Version: {{ $options.version }}
    </div>
  </div>
</template>

<script>
import { version } from "../../../package.json";
import IntroSection from "./IntroSection.vue";

export default {
  components: {
    IntroSection,
  },
  version,
  methods: {
    shortcutHandler() {
      this.$modal.open("shortcut-modal");
    },
  },
};
</script>
