<template>
  <TheSection
    label="Save As Global"
    help="With the Global Modules, you can save a module specific customizations and use them again in different pages. Any changes you make in Global Module will be applied to all the instances."
  >
    <Unsaved>
      <div class="pm-space-y-3 pm-p-3" v-if="category == 'user-global'">
        <p class="pm-text-xxs pm-text-warning-700">This is a global module.</p>
        <PField label="Global Name">
          <p class="pm-text-xs">{{ name }}</p>
        </PField>
        <PButton
          size="sm"
          :loading="saving"
          label="Detach from Global"
          theme="gray"
          @click.native="detach"
        />
      </div>

      <ValidationObserver v-else ref="form">
        <div class="pm-space-y-3 pm-p-3">
          <ValidationProvider name="Name" rules="required">
            <PField label="Name">
              <PTextbox
                v-model="alias"
                placeholder="e.g. My Fav Module"
                theme="underline"
              />
            </PField>
          </ValidationProvider>

          <PButton
            size="sm"
            :loading="saving"
            label="Save"
            theme="gray"
            @click.native="save"
          />
        </div>
      </ValidationObserver>
    </Unsaved>
  </TheSection>
</template>

<script>
import bus from "@/bus";
import TheSection from "../slideover/Section.vue";
import Unsaved from "./Unsaved.vue";
import saveModuleAsGlobal from "@/gql/module/saveModuleAsGlobal.gql";
import detachGlobalModule from "@/gql/module/detachGlobalModule.gql";

export default {
  props: {
    moduleId: Number,
    category: String,
    name: String,
  },
  components: {
    TheSection,
    Unsaved,
  },
  data() {
    return {
      alias: null,
      saving: false,
    };
  },

  inject: ["PAGE_DATA"],

  methods: {
    async save() {
      const valid = await this.$refs.form.validate();
      if (!valid) return;

      try {
        this.saving = true;
        await this.$apollo.mutate(saveModuleAsGlobal, {
          workspaceId: this.PAGE_DATA.workspaceId,
          moduleId: this.moduleId,
          alias: this.alias,
        });

        this.$notify({
          message: "Module saved as a Global.",
          type: "success",
        });
        bus.$emit("reload");
      } catch (err) {
        this.$sentry.catch(err);
        this.$notify({
          message:
            "There was an error while saving a module as a global. Please refresh the page & try again.",
          type: "danger",
          duration: 0,
        });
        console.error(err);
      }

      this.saving = false;
    },

    async detach() {
      try {
        this.saving = true;

        await this.$apollo.mutate(detachGlobalModule, {
          workspaceId: this.PAGE_DATA.workspaceId,
          templateId: this.PAGE_DATA.templateId,
          moduleId: this.moduleId,
        });

        this.$notify({
          message: "Module detached from Global.",
          type: "success",
        });
        bus.$emit("reload");
      } catch (err) {
        this.$sentry.catch(err);
        this.$notify({
          message:
            "There was an error while detaching a module from global. Please refresh the page & try again.",
          type: "danger",
          duration: 0,
        });
        console.error(err);
      }

      this.saving = false;
    },
  },
};
</script>
