<template>
    <div>
        <div class="tab-wraper pm-flex pm-relative pm-divide-x pm-border pm-rounded-sm pm-overflow-hidden" :class="{'pm-w-fit': !fullWidth}">
            <button v-for="tab in tabs" :key="tab.value" class="pm-h-5 pm-px-3 pm-font-medium pm-text-xxs pm-flex-grow pm-flex pm-items-center pm-justify-center pm-text-gray-400 hover:pm-text-gray-500 !pm-outline-none"
                :class="{ 'pm-text-primary-500 pm-bg-gray-100 hover:pm-text-primary-500': tab.value == value }" @click="$emit('change', tab.value)">
                {{ tab.label }}
            </button>
        </div>
        <template v-for="tab in tabs">
            <slot :name="tab.value" v-if="value === tab.value"></slot>
        </template>
    </div>
</template>

<script>

export default {
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
    }
};
</script>
