import { mapGetters, mapActions } from "vuex";
import segmentConfig from "@/config/segments";
import { getPropVariantConfigByTag } from "@pagemakerhq/segments/src-utils/index";
import { get } from "lodash-es";
const checkVariableRegex = /:::[\w\[\]\.]+(\|\|\|)?(https?:)?[^(:::)]*:::/
const variableRegex = /:::[\w\[\]\.]+(\|\|\|)?(https?:)?[^(:::)]*:::/g;

export default {
  props: {
    label: String,
    desc: String,
    prop: {
      type: String,
      required: true,
    },
    sideEffect: Function,
    disabled: Boolean,
    responsiveCheck: {
      type: Boolean,
      default: true,
    },
    valueInput: Function,
    valueOutput: Function,
    propsOutput: Function,
  },

  computed: {
    ...mapGetters({
      modifiers: "editor/modifiers",
      props: "editor/activeSegmentProps",
      tag: "editor/activeSegmentTag",
      isHover: "editor/isHover",
      propValueFinder: "editor/activeSegmentPropValue",
    }),

    variants() {
      return getPropVariantConfigByTag(this.tag, this.prop);
    },

    hasHover() {
      return this.variants.includes("hover");
    },

    hasResponsive() {
      return this.variants.includes("responsive");
    },

    isDisabled() {
      if (this.disabled) {
        return true;
      } else if (this.isLocked) {
        return true;
      } else if (this.isHover && !this.hasHover) {
        return true;
      }
      return false;
    },

    propValue() {
      return this.propValueFinder(this.prop);
    },

    value() {
      /**
       * If the value needs to be changed before passed to input element.
       * By default it gets from the prop value.
       */
      const re = /^\[.+\]$/
      if (this.valueInput) {
        return this.valueInput(this.propValue, this.props);
      } else {
        return re.test(this.propValue)?this.getCustomValue(this.propValue):this.propValue;
      }
    },

    inputClassList() {
      return {
        "i-field--non-responsive": this.responsiveCheck && !this.hasResponsive,
        "i-field--disabled": this.isDisabled,
      };
    },

    localLocked() {
      return this.props?.locked || [];
    },

    locked() {
      return segmentConfig[this.tag]?.locked || [];
    },

    isLocked() {
      return (
        this.localLocked.includes(this.prop) || this.locked.includes(this.prop)
      );
    },
  },

  methods: {
    ...mapActions({
      updateSegment: "modules/updateSegment",
    }),

    /**
     * This is just a wrapper for updateMultiple
     * to quickly update a single value.
     *
     * If key is not passed, we'll assume that multiple props are being updated.
     *
     * @param {String} key Prop key to update
     * @param {String,Boolean,Array,Number} value Value to Set.
     * @param {Object} config Additional Config
     */
    update(key, value, config = {}) {
      if (this.valueOutput) {
        value = this.valueOutput(
          value, // newValue
          this.propValue // oldValue
        );
      }
      this.updateMultiple({ [key]: value }, config);
    },

    /**
     * updateMultiple updates multiple props at a time
     * @param {Object} props : Set of props to update
     * @param {Object} config.isPreset: Additional Config
     */
    async updateMultiple(props, config = {}) {
      if (this.propsOutput) {
        props = this.propsOutput(props);
      }
      this.updateSegment({ props, config });

      /**
       * When the prop is updated, we might need to update other props dependent on it.
       * We can use sideEffect in that case
       */
      if (this.sideEffect) {
        const effect = await this.sideEffect(props);
        if (effect) {
          this.updateSegment({ props: effect.props, config: effect.config });
        }
      }
    },

    getCustomValue(color) {
      if(!color) return color
      const customValueRE = /^\[|\]$/g
      return color.replace(customValueRE, '');
    },
    replaceVariableValue(value) {
      if(typeof value != "string") return value
      if(!checkVariableRegex.test(value)) return value

      const matches = value.match(variableRegex)
      for (let i = 0; i < matches.length; i++) {
        let match = matches[i]
        /**
         * Split the match to get the @variableKey and @defaultValue (:::media[0].url|||https://example.com/image.jpg:::)
         */
        const [variableKey, defaultValue] = match.replace(/:::/g, '').split('|||')
        const variableValue = get((this.variables ?? {}), variableKey, defaultValue ?? match)
        value = value.replace(match, variableValue)
      }
      return value
    },
  },
};
