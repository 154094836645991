<template>
  <div
    class="
      pm-inline-flex
      pm-border-l
      pm-border-gray-200
      pm-divide-x
      pm-divide-gray-200
      pm-flex-grow-0
    "
  >
    <TheButton
      v-tooltip="tooltip('Save Changes')"
      icon="MdiContentSave"
      @click.native="save"
      :loading="saving"
    />
    <TheButton
      v-tooltip="tooltip('Preview Page')"
      icon="MdiEye"
      :href="HEADER.draftUrl"
      :target="`pm-campaign-preview-${PAGE_DATA.campaignId}`"
    />
    <TheButton
      v-tooltip="tooltip('Save Changes & Publish Page')"
      :loading="publishing"
      icon="MdiRocketLaunch"
      @click.native="publish"
    />
     <TheButton
      v-if="PAGE_DATA.type == 'shopify_page'"
      v-tooltip="tooltip('Preview Shopify Page')"
      :href="HEADER.shopifyUrl"
      target="_blank"
      icon="MdiShopify"
    />
    <TheButton
      v-tooltip="tooltip('Exit Editor')"
      icon="MdiLogoutVariant"
      @click.native="exit"
    />
  </div>
</template>

<script>
import bus from "@/bus";
import tinykeys from "tinykeys";
import { mapMutations } from "vuex";
import fetchTemplate from "@/api/fetchTemplate";
import TheButton from "./Button";

export default {
  components: {
    TheButton,
  },

  inject: ["PAGE_DATA", "HEADER"],

  data() {
    return {
      saving: false,
      publishing: false,
      tinyKeysInstance: null,
    };
  },

  mounted() {
    bus.$on("save:end", () => {
      /**
       * Reload & Restarts were creating issues in event binding
       * and creating errors so for now whole page reload is added
       */
      this.setInterruptReload(false);
      this.$nextTick(async () => {
        await this.refetchModules();
        this.saving = false;
        this.publishing = false;
      });
    });

    tinykeys(window, {
      "$mod+s": (event) => {
        event.preventDefault();
        this.save();
      },
      "$mod+p": (event) => {
        event.preventDefault();
        window.open(this.HEADER.draftUrl);
      },
      "Shift+$mod+P": (event) => {
        event.preventDefault();
        this.publish();
      },
      "$mod+Escape": (event) => {
        event.preventDefault();
        this.exit();
      },
    });
  },
  methods: {
    ...mapMutations({
      updateModules: "modules/update",
      setInterruptReload: "editor/setInterruptReload",
      setUnsaved: "editor/setUnsaved",
    }),

    exit() {
      bus.$emit("exit");
    },

    save() {
      this.saving = true;
      bus.$emit("save", false);
    },

    publish() {
      this.$confirm({
        title: "Publish Page?",
        message:
          "All the changes you've made will be saved and page will be live instantly. Are you sure you want to publish?",
        okLabel: "Yes, Publish",
        okTheme: "primary",
        ok: () => {
          this.publishing = true;
          bus.$emit("save", true);
        },
      });
    },

    tooltip(content) {
      return {
        content,
        delay: {
          show: 1000,
        },
      };
    },

    /**
     * In template, there may be some new modules which do not have database ID
     * Some modules may be deleted too.
     * So after the template save action, we need to fetch the Database ID of the modules to keep in sync.
     * Hence we refetch the template modules again and reset the modules data.
     * The segments are kept as-it-is to keep performance in mind.
     * If we update the segments too, it will be very heavy opration as the whole page's reactivity will be triggered.
     */
    refetchModules() {
      return fetchTemplate(this.PAGE_DATA)
        .then(async (res) => {
          const { modules } = res;
          this.updateModules(modules);
          this.setUnsaved(false);
          /**
           * Wait for few seconds here to make sure that the VueX and DOM
           * has been updated properly before user can send another save request.
           * If the save request is sent again before the DOM is updated,
           * duplicate UUID error will occur.
           */
          await new Promise((resolve) => {
            setTimeout(() => {
              resolve();
            }, 2000);
          });
        })
        .catch((err) => {
          this.$sentry.catch(err);
          console.error(err);
          this.$notify({
            title: "There was an error!",
            message:
              "Please refresh & try again. If the issue persist, contact our support.",
            type: "danger",
            duration: 3000,
          });
        });
    },
  },
};
</script>
