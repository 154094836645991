<template>
  <IuiRadiusLock
    :value="value"
    :disabled="isDisabled"
    :orientation="orientation"
    @click="isDisabled ? null : update(prop, !value)"
  />
</template>

<script>
import inspectorInput from "@/mixins/inspector-input";
import {IuiRadiusLock} from "inspector-ui"

export default {
  components: {
    IuiRadiusLock
  },
  mixins: [inspectorInput],
  props: {
    orientation: {
      type: String,
      required: true
    }
  }
};
</script>

