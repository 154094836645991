<template>
  <Portal v-if="isOpen" to="confirm-container" slim>
    <div class="confirm">
      <div class="confirm__overlay"></div>
      <div class="confirm__wrapper">
        <ModalShell
          size="sm"
          :title="title"
          :close="() => (isOpen = false)"
          footer
        >
          <template #default>
            <div class="pm-p-4">
              <p>{{ message }}</p>
            </div>
          </template>

          <template #footer-actions>
            <PButton
              :disabled="okWaiting"
              :label="cancelLabel"
              :theme="cancelTheme"
              @click.native="cancel && cancel()"
            />
            <PButton
              :loading="okWaiting"
              :disabled="okWaiting"
              :label="okLabel"
              :theme="okTheme"
              @click.native="callOk()"
            />
          </template>
        </ModalShell>
      </div>
    </div>
  </Portal>
</template>

<script>
import bus from "../bus";
import ModalShell from "./modal/Shell";

export default {
  data() {
    return {
      isOpen: false,
      title: null,
      message: null,
      okLabel: null,
      okTheme: null,
      cancelLabel: null,
      cancelTheme: null,
      ok: null,
      cancel: null,
      okWaiting: false,
    };
  },

  components: {
    ModalShell,
  },

  mounted() {
    bus.$on("confirm", (data = {}) => {
      Object.assign(
        this,
        {
          title: "Confirm",
          message: "Are you sure you want to do this action?",
          okLabel: "Okay",
          okTheme: null,
          cancelLabel: "Cancel",
          cancelTheme: "gray",
          ok: () => {
            this.isOpen = false;
          },
          cancel: () => {
            this.isOpen = false;
          },
        },
        data
      );
      this.isOpen = true;
    });
  },

  methods: {
    async callOk() {
      this.okWaiting = true;
      await this.ok();
      this.okWaiting = false;
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss">
.confirm {
  @apply pm-fixed pm-inset-0 pm-z-50;
}
.confirm__overlay {
  @apply pm-fixed pm-inset-0 pm-bg-black pm-bg-opacity-80;
}
.confirm__wrapper {
  @apply pm-absolute pm-inset-0 pm-z-50 pm-m-auto pm-flex pm-items-center;
}
</style>
