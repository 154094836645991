<template>
  <Modal
    id="module-list"
    #default="{ close, data }"
    @close="
      activeTag = null;
    "
  >
    <ModalShell size="fs" :close="close">

      <template #default>
        <div class="module-list">
          <!-- Tags -->
          <aside v-if="!data.category" class="module-list__tags">
            <Tags :items="tags" v-model="activeTag" />
          </aside>

          <!-- Modules -->
          <div
            ref="modulesContainer"
            class="pm-relative pm-h-full pm-flex-grow pm-overflow-auto"
          >
            <VList
              endpoint="true"
              :per-page="18"
              :request-handler="fetchModulesList"
              :params="{ workspaceId: PAGE_DATA.workspaceId }"
              :filters="{
                category: data.category,
                tagId: +data.category ? undefined : activeTag,
              }"
              @afterLoad="afterLoad()"
              :paginationHistory="false"
            >
              <template #loading>
                <div class="module-list__container">
                  <ModuleShimmer v-for="n in 9" :key="`loading-${n}`" />
                </div>
              </template>

              <template #loading-page>
                <LoaderBar
                  class="pm-sticky pm-top-0 pm-left-0 pm-right-0 pm-z-20 pm-w-full"
                />
              </template>
              <template #empty>
                <VListSearch class="search pm-px-2 pm-py-3" />
                <div class="pm-p-8 pm-text-center">
                  <p>No modules found</p>
                </div>
              </template>

              <template #default="{ items, refresh }">
                <VListSearch class="search pm-px-2 pm-py-3" />
                <div class="module-list__container">
                  <Module
                    v-for="item in items"
                    :key="`${item.name}-${item.id}`"
                    :id="item.id"
                    :name="item.name"
                    :themes="item.themes"
                    :preview-image="item.previewImage"
                    :alias="item.alias"
                    :category="item.category"
                    :insert-index="data.insertIndex"
                    @deleteSuccess="refresh"
                  />
                </div>

                <div class="pm-pb-24">
                  <Pagination />
                </div>
              </template>
            </VList>
          </div>
        </div>
      </template>
    </ModalShell>
  </Modal>
</template>

<script>
import Pagination from "../Pagination";
import LoaderBar from "../LoaderBar";
import Tags from "./Tags";
import Module from "./Module";
import ModuleShimmer from "./ModuleShimmer";
import list from "@/gql/module/list.gql";
import tagList from "@/gql/tag/list.gql";

export default {
  inject: ["PAGE_DATA"],
  data() {
    return {
      activeTag: null,
      tags: null,
    };
  },
  components: {
    Pagination,
    LoaderBar,
    Tags,
    Module,
    ModuleShimmer,
  },

  async created() {
    this.tags = await this.fetchModuleTags();
  },

  methods: {
    afterLoad() {
      this.$refs.modulesContainer.scrollTop = 0;
    },
    fetchModuleTags() {
      return this.$queryPublic(tagList, {
        perPage: -1,
        type: "module",
      })
        .then((res) => res.data.tags.data)
        .then((res) => {
          return res.map((item) => {
            return {
              name: item.name,
              icon: item.icon,
              id: item.id,
            };
          });
        });
    },

    fetchModulesList(payload) {
      const { search, pagination, sort, filters, params } = payload;

      const themes = (data) => {
        return data.map((item) => {
          return {
            name: item.name,
            image: item.previewImage,
            width: item.previewImageWidth,
            height: item.previewImageHeight,
          };
        });
      };

      var queryParams = {
        workspaceId: this.PAGE_DATA.workspaceId,
        search,
        page: pagination.page,
        perPage: pagination.perPage,
        filters,
        sort:
          sort.by === undefined || sort.order === undefined ? undefined : sort,
      };

      return this.$apollo
        .query(list, queryParams)
        .then((res) => res.data.modules)
        .then((res) => {
          return {
            items: res.data.map((item) => {
              return {
                id: item.id,
                name: item.name,
                version: item.version,
                by: item.by,
                alias: item.alias,
                themes: themes(item.themes),
                category: item.category,
                previewImage: item.previewImage,
              };
            }),
            count: res.pagination.total,
          };
        });
    },
  },
};
</script>

<style lang="scss">
.module-list {
  @apply pm-flex pm-h-full pm-flex-nowrap pm-overflow-hidden;
}

.module-list__tags {
  width: 160px;
  @apply pm-flex-shrink-0 pm-overflow-auto pm-border-r pm-border-gray-200;
}

.module-list__container {
  @apply pm-relative pm-grid pm-grid-cols-3 pm-gap-8 pm-p-8;
}

.module-list .search input {
  @apply pm-w-full pm-h-8
}
</style>
