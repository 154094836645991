<template>
    <div class="slider pm-flex pm-items-center pm-h-7">
      <!-- Icon -->
      <div v-if="icon" class="slider__icon pm-text-gray-500 pm-items-center">
        <Icon :name="icon" v-tooltip="tooltip" />
      </div>

      <!-- Slider -->
      <div class="slider__container">
        <VueSlider
          :contained="true"
          :data="options"
          :min="min"
          :max="max"
          :height="2"
          :value="value || null"
          tooltip="none"
          :hide-label="true"
          :marks="false"
          @change="$emit('update', $event)"
        />
      </div>

      <!-- Value -->
      <div class="slider__value pm-text-xs pm-font-medium pm-text-center" :class="{ 'slider__value--reset pm-flex pm-justify-end': canReset }">
        <ButtonIcon
          icon="MdiClose"
          class="slider__delete pm-flex-shrink-0 pm-hidden slider__value--reset-hover:pm-flex"
          @click.native="$emit('reset')"
        />
        <p class="pm-flex-grow slider__value--reset-hover:pm-hidden">{{ display }}</p>
      </div>
    </div>
</template>

<script>

import ButtonIcon from "./ButtonIcon.vue";

export default {

  components: {

    ButtonIcon,
  },

  props: {


    icon: String,
    options: [Array, Object],
    min: Number,
    max: Number,
    tooltip: String,
    canReset: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Number,
      default: null,
    },
    display: {
      type: String,
      default: null,
    },
  }
};
</script>

<style lang="scss">
.slider {
  .vue-slider-rail {
    @apply pm-bg-gray-200;
  }
  .vue-slider-marks {
    display: none;
  }
  .vue-slider-dot-handle {
    box-shadow: none;
    @apply pm-border-2 pm-border-gray-300;
  }
}

.slider__icon {

  flex: 0 0 18px;
}

.slider__container {
  flex: 1 1 auto;
}

.slider__value {
  flex: 0 0 28px;
}

.slider__value--reset {

  &:hover {
    .slider__delete {
      display: flex;
    }
    p {
      display: none;
    }
  }
}
</style>
