<template>
  <IuiField inline :label="label" :responsive="hasResponsive" :disabled="isDisabled" >
    <IuiSwitch
      :value="value"
      @click="update(prop, !value)"
    />
  </IuiField>
</template>

<script>
import inspectorInput from "@/mixins/inspector-input";
import { IuiField, IuiSwitch } from "inspector-ui"

export default {
  components: {
    IuiSwitch,
    IuiField
  },
  mixins: [inspectorInput],
};
</script>