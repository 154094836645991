<template>
  <IAccordion title="Link" prop-group="link">
    <div class="pm-space-y-3">
      <ISelect
        label="Link To"
        placeholder="None"
        :options="[
          {
            label: 'Other Page',
            value: 'otherPage',
          },
          {
            label: 'URL',
            value: 'url',
          },
        ]"
        prop="linkTo"
        :side-effect="() => linkToSideEffect()"
      />

      <template v-if="propValueFinder('linkTo')">
        <!-- Other Page -->
        <ISelect
          v-if="propValueFinder('linkTo') == 'otherPage'"
          label="Select Page"
          prop="link"
          :request="pageList"
        />

        <!-- URL -->
        <ITextbox
          v-else-if="propValueFinder('linkTo') == 'url'"
          label="URL"
          prop="link"
          rules="url"
        />

        <ISelect
          :options="$options.target"
          label="Open Link In"
          prop="linkTarget"
        />
      </template>
    </div>
  </IAccordion>
</template>

<script>
import { mapGetters } from "vuex";
import { target } from "@/config/prop-values";
import list from "@/gql/page/list.gql";
export default {
  target,

  inject: ["PAGE_DATA"],

  computed: {
    ...mapGetters({
      propValueFinder: "editor/activeSegmentPropValue",
    }),
  },

  methods: {
    pageList() {
      return this.$apollo
        .query(list, {
          workspaceId: this.PAGE_DATA.workspaceId,
          campaignId: this.PAGE_DATA.campaignId,
          templateId: this.PAGE_DATA.templateId,
        })
        .then(
          ({
            data: {
              pages: { data },
            },
          }) => {
            return data.map((page) => {
              return {
                label: page.name,
                value: `:::page_id:::${page.id}:::`,
              };
            });
          }
        );
    },

    linkToSideEffect() {
      return {
        props: {
          link: null,
        },
      };
    },
  },
};
</script>
