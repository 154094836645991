<template>
  <div class="pm-flex pm-flex-nowrap pm-items-center">
    <ButtonIcon @click.native="$emit('minus')" icon="MdiMinus" :disabled="disabled" />
    <p class="pm-px-2 pm-text-xs pm-font-medium">{{ value }}</p>
    <ButtonIcon @click.native="$emit('plus')" icon="MdiPlus" :disabled="disabled" />
  </div>
</template>

<script>

import ButtonIcon from "./ButtonIcon.vue";

export default {
  components: {
    ButtonIcon,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  }
};
</script>
