<template>
  <div class="unsaved-check" :class="{ 'unsaved-check--true': isUnsaved }">
    <div
      class="pm-text-xxs pm-m-1 pm-p-3 pm-bg-warning-100 pm-text-warning-700"
      v-if="isUnsaved"
    >
      There are unsaved changes on the page. You need to save the page before
      using this feature.
    </div>
    <div class="unsaved-check__content">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      isUnsaved: "editor/isUnsaved",
    }),
  },
};
</script>

<style lang="scss">
.unsaved-check--true {
  .unsaved-check__content {
    @apply pm-relative;
    &:after {
      content: "";
      @apply pm-absolute pm-inset-0 pm-bg-white pm-bg-opacity-50 pm-cursor-not-allowed;
    }
  }
}
</style>
