import Vue from "vue";
import bus from "@/bus";
import { $apollo, $apolloUpload, apolloPublicClient } from "@/plugins/apollo";
import gql from "graphql-tag";

Vue.prototype.$notify = (data) => bus.$emit("notify", data);
Vue.prototype.$modal = {
  open: (id, scope) => bus.$emit("modal-open", id, scope),
  close: (id, scope) => bus.$emit("modal-close", id, scope),
};
Vue.prototype.$confirm = (data) => bus.$emit("confirm", data);
Vue.prototype.$slideover = {
  open: (id) => bus.$emit("slideover-open", id),
  close: (id) => bus.$emit("slideover-close", id),
  toggle: (id) => bus.$emit("slideover-toggle", id),
};
Vue.prototype.$apollo = $apollo;
Vue.prototype.$apolloUpload = $apolloUpload;
Vue.prototype.$queryPublic = (query, variables) => {
  return apolloPublicClient.query({
    query: gql`
      ${query}
    `,
    variables,
  });
};

Vue.prototype.$mutationPublic = (mutation, variables) => {
  return apolloPublicClient.mutate({
    mutation: gql`
      ${mutation}
    `,
    variables,
  });
};