<template>
  <IAccordion title="Margin" prop-group="margin">
    <IuiBox
      class="pm-w-full pm-border"
      :x-locked="xLocked"
      :y-locked="yLocked"
      :all-locked="allLocked"
    >
      <template v-slot:top>
        <ISpaceInput :options="$options.m" :value-input="(value) => valueInput(value, 'y')" :has-negative="true"
          :props-output="(props) => propsOutput(props, 'mt', 'y')" prop="mt" name="top-space"
          :heading="topMarginHeading"
          class="pm-bg-gray-100"
          :class="{ '!pm-bg-primary-50': isActive('top', 'bottom', yLocked) }"
          @show="active = 'top'"
          @hide="active = active === 'top'?null:active"
        />
      </template>
      <template v-slot:right>
        <ISpaceInput :options="$options.m" :value-input="(value) => valueInput(value, 'x')" :has-negative="true"
          :props-output="(props) => propsOutput(props, 'mr', 'x')" prop="mr" name="right-space"
          :heading="rightMarginHeading"
          :class="{ '!pm-bg-primary-50': isActive('right', 'left', xLocked) }"
          @show="active = 'right'"
          @hide="active = active === 'right'?null:active"
        />
      </template>
      <template v-slot:bottom>
        <ISpaceInput :options="$options.m" :value-input="(value) => valueInput(value, 'y')" :has-negative="true"
          :props-output="(props) => propsOutput(props, 'mb', 'y')" prop="mb" name="bottom-space"
          :heading="bottomMarginHeading"
          class="pm-bg-gray-100"
          :class="{ '!pm-bg-primary-50': isActive('bottom', 'top', yLocked) }"
          @show="active = 'bottom'"
          @hide="active = active === 'bottom'?null:active"
        />
      </template>
      <template v-slot:left>
        <ISpaceInput :options="$options.m" :value-input="(value) => valueInput(value, 'x')" :has-negative="true"
          :props-output="(props) => propsOutput(props, 'ml', 'x')" prop="ml" name="left-space"
          :heading="leftMarginHeading"
          :class="{ '!pm-bg-primary-50': isActive('left', 'right', xLocked) }"
          @show="active = 'left'"
          @hide="active = active === 'left'?null:active"
        />
      </template>
      <template>
        <div class="pm-flex pm-relative pm-justify-center pm-items-center pm-h-full pm-aspect-1">
          <SpaceLock 
            v-tooltip="{content: 'Lock Horizontal Margin', delay:$options.d}"
            prop="mx"
            :value-input="(value) => (value == null ? false : 1)"
            orientation="x"
            :value-output="(value) => (value == false ? null : 1)"
            :side-effect="(props) => lockSideEffect(props)"
          />
          <SpaceLock
            v-tooltip="{content: 'Lock Vertical Margin', delay:$options.d}"
            prop="my"
            :value-input="(value) => (value == null ? false : 1)"
            orientation="y"
            :value-output="(value) => (value == false ? null : 1)"
            :side-effect="(props) => lockSideEffect(props)"
          />
          <SpaceLock
            v-tooltip="{content: 'Lock Margin', delay:$options.d}"
            prop="m"
            :value-input="(value) => (value == null ? false : 1)"
            orientation="all"
            :value-output="(value) => (value == false ? null : 1)"
            :side-effect="(props) => lockSideEffect(props)"
          />
        </div>
      </template>
    </IuiBox>
  </IAccordion>
</template>

<script>
import { mapGetters } from "vuex";
import { spacing, tooltipDalay } from "@/config/prop-values";
import SpaceLock from "../inputs/SpaceLock";
import { IuiBox } from "inspector-ui";

export default {
  m: spacing,
  d: tooltipDalay,

  data() {
    return {
      active: null,
    };
  },
  components: {
    IuiBox,
    SpaceLock
  },

  computed: {
    ...mapGetters({
      propValueFinder: "editor/activeSegmentPropValue",
    }),

    allLocked() {
      return this.propValueFinder("m") != null;
    },

    xLocked() {
      return this.propValueFinder("mx") != null;
    },

    yLocked() {
      return this.propValueFinder("my") != null;
    },
    topMarginHeading() {
      if (this.yLocked) return "Vertical Margin"
      if (this.allLocked) return "Margin"
      return "Top Margin"
    },
    bottomMarginHeading() {
      if (this.yLocked) return "Vertical Margin"
      if (this.allLocked) return "Margin"
      return "Bottom Margin"
    },
    rightMarginHeading() {
      if (this.xLocked) return "Horizontal Margin"
      if (this.allLocked) return "Margin"
      return "Right Margin"
    },
    leftMarginHeading() {
      if (this.xLocked) return "Horizontal Margin"
      if (this.allLocked) return "Margin"
      return "Left Margin"
    },
  },

  methods: {
    lockSideEffect(props) {
      if (props.m == 1) {
        props.ml = null;
        props.mr = null;
        props.mt = null;
        props.mb = null;
        props.mx = null;
        props.my = null;
      }
      if (props.mx == 1) {
        props.ml = null;
        props.mr = null;
        props.m = null;
      }
      if (props.my == 1) {
        props.m = null;
        props.mt = null;
        props.mb = null;
      }
      return { props };
    },

    valueInput(value, dir) {
      if (this.allLocked) {
        return this.propValueFinder("m");
      } else if (dir == "x" && this.xLocked) {
        return this.propValueFinder("mx");
      } else if (dir == "y" && this.yLocked) {
        return this.propValueFinder("my");
      } else {
        return value;
      }
    },

    propsOutput(props, prop, dir) {
      if (this.allLocked) {
        return {
          ...props,
          ml: null,
          mr: null,
          mt: null,
          mb: null,
          my: null,
          mx: null,
          m: props[prop],
        };
      } else if (dir == "x" && this.xLocked) {
        return {
          ...props,
          ml: null,
          mr: null,
          m: null,
          mx: props[prop],
        };
      } else if (dir == "y" && this.yLocked) {
        return {
          ...props,
          mt: null,
          mb: null,
          m: null,
          my: props[prop],
        };
      } else {
        return props;
      }
    },
    isActive(side, oppositeSide, lock) {
      return this.active === side || (this.active === oppositeSide && lock) || (this.active && this.allLocked)
    }
  },
};
</script>
