<template>
  <IAccordion title="Row" open>
    <div class="field-group g-3">
      <Gap />

      <template v-for="(col, i) in propValueFinder('cols')">
        <INumber
          :key="`col-${i}`"
          :label="`Column ${i + 1} Size`"
          :min="0"
          :max="12"
          prop="cols"
          :value-input="(value) => value[i]"
          :value-output="(a, b) => colSizeChange(a, b, i)"
        />
      </template>
    </div>
  </IAccordion>
</template>

<script>
import { mapGetters } from "vuex";
import Gap from "@/components/inspector/common/Gap";

export default {
  components: {
    Gap,
  },
  computed: {
    ...mapGetters({
      propValueFinder: "editor/activeSegmentPropValue",
    }),
  },
  methods: {
    colSizeChange(newValue, oldValue, index) {
      oldValue = [...oldValue];
      oldValue[index] = newValue;
      return oldValue;
    },
  },
};
</script>
