<template>
  <div v-if="editor" class="text-editor">
    <button
      class="text-editor__button"
      v-for="n in 6"
      :key="`h-${n}`"
      @click="command('heading', n)"
      :class="{ 'text-editor__button--active': isHeadingActive(n) }"
    >
      <component :is="`IconMdiFormatHeader${n}`" />
    </button>

    <button
      @click="command('bold')"
      class="text-editor__button"
      :class="{ 'text-editor__button--active': editor.isActive('bold') }"
    >
      <IconMdiFormatBold />
    </button>

    <button
      @click="command('italic')"
      class="text-editor__button"
      :class="{ 'text-editor__button--active': editor.isActive('italic') }"
    >
      <IconMdiFormatItalic />
    </button>

    <button
      @click="command('underline')"
      class="text-editor__button"
      :class="{ 'text-editor__button--active': editor.isActive('underline') }"
    >
      <IconMdiFormatUnderline />
    </button>

    <button
      @click="command('strike')"
      class="text-editor__button"
      :class="{ 'text-editor__button--active': editor.isActive('strike') }"
    >
      <IconMdiFormatStrikethrough />
    </button>

    <VPopover ref="colorPicker" placement="bottom-end">
      <template #default>
        <div class="text-editor__button">
          <Color
            class="pm-h-4 pm-w-4 pm-rounded"
            :value="color()"
            v-slot="{ value }"
          >
            <IconBxBxsColorFill v-if="!value" />
          </Color>
        </div>
      </template>

      <!-- This will be the content of the popover -->
      <template #popover>
        <div class="pm-w-72 pm-rounded-md pm-border pm-bg-white pm-shadow-lg">
          <PColorPicker wide :value="color()" @input="changeColor($event)" />
        </div>
      </template>
    </VPopover>

    <VPopover ref="link" placement="bottom-end">
      <template #default>
        <button
          class="text-editor__button"
          :class="{ 'text-editor__button--active': editor.isActive('link') }"
        >
          <IconMdiLink />
        </button>
      </template>

      <template #popover>
        <div
          class="pm-rounded-md pm-border pm-border-solid pm-bg-white pm-shadow-lg"
        >
          <input
            type="url"
            :value="editor.getAttributes('link')?.href"
            placeholder="URL"
            @change="setLink($event)"
            class="pm-w-52 pm-p-1 pm-text-xs pm-text-black"
          />
        </div>
      </template>
    </VPopover>
  </div>
</template>

<script>
import Color from "@/components/interfaces/color-picker/Color.vue";

export default {
  props: {
    editor: Object,
  },

  components: {
    Color,
  },

  methods: {
    color() {
      return this.editor.getAttributes("tailwind")?.color?.replace("text-", "");
    },

    isHeadingActive(value) {
      return this.editor.isActive("heading", { level: value });
    },

    onSelectionUpdate(editor) {
      /**
       * Tiptap flashes the selection when mark is added so selection
       * becomes null and again set. nextTick helps here to get
       * the later state.
       */
      this.$nextTick(() => {
        const {
          state: {
            selection: { empty },
          },
        } = editor;
        if (empty) {
          this.$refs.colorPicker?.hide();
          this.$refs.link?.hide();
        }
      });
    },

    changeColor(value) {
      this.editor.commands.setTailwindColor(`text-${value}`);
    },

    setLink(e) {
      const url = e.target.value;

      if (url === null) {
        return;
      }

      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();
        return;
      }

      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },

    command(name, value) {
      switch (name) {
        case "heading":
          this.editor.chain().focus().toggleHeading({ level: value }).run();
          break;

        case "bold":
          this.editor.chain().focus().toggleBold().run();
          break;

        case "italic":
          this.editor.chain().focus().toggleItalic().run();
          break;

        case "underline":
          this.editor.chain().focus().toggleUnderline().run();
          break;

        case "strike":
          this.editor.chain().focus().toggleStrike().run();
          break;
      }
    },
  },
};
</script>

<style lang="scss">
/**
TipTap Editor Styling for pm-text
**/
.text-editor {
  @apply pm-bg-gray-900 pm-shadow-xl;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
}

.text-editor__button {
  @apply pm-flex pm-h-8 pm-w-8 pm-flex-shrink-0 pm-items-center pm-justify-center pm-text-gray-500;
  border: none;
  background-color: transparent;
  outline: none !important;
  &:hover {
    @apply pm-bg-gray-800;
  }
  svg {
    @apply pm-h-4 pm-w-4;
  }
}

.text-editor__button--active {
  color: rgba(255, 255, 255, 1) !important;
}

.text--variable {
  &:hover {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>
