<template>
  <component
    :is="$attrs.href ? 'a' : 'button'"
    type="button"
    class="header-button"
    :class="{
      'header-button--active': active,
      'header-button--loading': loading,
    }"
    :disabled="loading"
  >
    <Icon class="header-button__icon" :name="loading ? 'MdiLoading' : icon" />
  </component>
</template>

<script>
export default {
  props: {
    label: String,
    icon: String,
    active: Boolean,
    loading: Boolean,
  },
};
</script>

<style lang="scss">
.header-button {
  width: calc(var(--pmHeaderSize) - 1px);
  height: calc(var(--pmHeaderSize) - 1px);
  outline: none !important;
  @apply pm-flex pm-items-center pm-justify-center pm-text-gray-500 hover:pm-text-gray-600;

  &--active {
    @apply pm-text-primary-500 pm-bg-gray-100 hover:pm-text-primary-500;
  }

  &--loading {
    @apply pm-cursor-wait pm-text-primary-500;
    .icon {
      @apply pm-animate-spin;
    }
  }
  &:disabled {
    @apply pm-text-gray-300 pm-cursor-not-allowed;
  }
}

.header-button__icon {
  @apply pm-w-4 pm-h-4;
}
</style>
