<template>
  <IAccordion title="Background Image" prop-group="background-image">
    <div class="pm-space-y-3">
      <IMedia class="mt-3" prop="bgImage" />

      <ISelect label="Size" prop="bgSize" :options="$options.bgSize" />

      <ISelect label="Repeat" prop="bgRepeat" :options="$options.bgRepeat" />

      <!-- Position -->
      <IPositionButton
        label="Position"
        prop="bgPosition"
      />

      <!-- Shift -->
      <div class="pm-grid pm-grid-cols-2 pm-gap-2">
        <ITextbox label="Position X" :disabled="!isX" prop="bgX" />
        <ITextbox class="mt-3" label="Position Y" :disabled="!isY" prop="bgY" />
      </div>

      <!-- Opacity -->
      <ISlider
        label="Opacity"
        :options="$options.opacity"
        prop="bgImageOpacity"
      />
    </div>
  </IAccordion>
</template>

<script>
import { mapGetters } from "vuex";
import { startCase } from "lodash-es";
import theme from "@/config/tailwind-theme";
import { opacity } from "@/config/prop-values";

const bgRepeat = [
  "repeat",
  "no-repeat",
  "repeat-x",
  "repeat-y",
  "repeat-round",
  "repeat-space",
].map((item) => {
  return {
    label: startCase(item),
    value: item,
  };
});

const bgSize = Object.keys(theme.backgroundSize).map((key) => {
  return {
    label: startCase(key),
    value: key,
  };
});

export default {

  bgRepeat,
  bgSize,
  opacity,

  computed: {
    ...mapGetters({
      propValue: "editor/activeSegmentPropValue",
    }),
    positionDirections() {
      return this.propValue("bgPosition")?.split("-") || [];
    },
    isX() {
      return (
        this.positionDirections.includes("left") ||
        this.positionDirections.includes("right")
      );
    },
    isY() {
      return (
        this.positionDirections.includes("top") ||
        this.positionDirections.includes("bottom")
      );
    },
  },
};
</script>
