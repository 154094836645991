<template>
  <Modal id="variable-info-modal" #default="{ close }">
    <ModalShell title="Variables" size="lg" :close="close">
      <div class="pm-p-4">
        <div
          class="pm-rounded-md pm-overflow-hidden pm-border-gray-100 pm-border-2"
        >
          <table class=" pm-w-full">
            <tr class="odd:pm-bg-gray-100" v-for="(variable, index) in variables" :key="index">
              <td class="pm-p-4 pm-text-sm">
                <p>{{ variable.name }}</p>
                <p v-if="variable.description" class="pm-text-xxs">({{ variable.description }})</p>
              </td>
              <td class="pm-p-4 pm-text-right pm-text-gray-500 pm-text-sm">
                {{ variable.key }}
              </td>
            </tr>
          </table>
        </div>
        <p class="pm-text-xs pm-py-4 pm-text-danger-500">* Where n is the index of review and there is maximum 5 reviews.</p>
      </div>
    </ModalShell>
  </Modal>
</template>

<script>
import OS from "platform-detect/os.mjs";
export default {
  computed: {
    cmdOrCtrl() {
      return OS.macos ? "Command" : "Ctrl";
    },

    variables() {
      return [
        {
          name: "Name of product",
          key: ':::productName:::',
        },
        {
          name: "Product description",
          key: ':::desc:::',
        },
        {
          name: "Product features (n)",
          description: "Where n is the index of productFeatures.",
          key: ':::productFeatures[n]:::',
        },
        {
          name: "Product rating",
          key: ':::productRating:::',
        },
        {
          name: "Product review count",
          key: ':::reviewCount:::',
        },
        {
          name: "Product review user name of (n) review",
          description: "Where n is the index of review and there is maximum 5 reviews.",
          key: ':::reviews[n].username:::',
        },
        {
          name: "Product review rating of (n) review",
          description: "Where n is the index of review and there is maximum 5 reviews.",
          key: ':::reviews[n].rating:::',
        },
        {
          name: "Product review title of (n) review.",
          description: "Where n is the index of review and there is maximum 5 reviews.",
          key: ':::reviews[n].title:::',
        },
        {
          name: "Product review text of (n) review",
          description: "Where n is the index of review and there is maximum 5 reviews.",
          key: ':::reviews[n].text:::',
        },
        {
          name: "List price",
          key: ':::listPrice:::',
        },
        {
          name: "Sale price",
          key: ':::salePrice:::',
        },
        {
          name: "Discount percentage",
          key: ':::discountPercentage:::',
        },
        {
          name: "Product currency",
          key: ':::currency:::',
        },
        {
          name: "Product manufacturer",
          key: ':::manufacturer:::',
        }
      ];
    },
  },
};
</script>
<style lang="scss">

</style>
