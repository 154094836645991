<template>
  <section class="pm-p-4">
    <Icon class="pm-h-8 pm-w-8 pm-text-gray-500" :name="icon" />
    <h4 class="pm-text-xs pm-font-medium">{{ title }}</h4>
    <p class="pm-text-xxs pm-text-gray-500">{{ desc }}</p>

    <a
      class="pm-text-xxs pm-font-medium hover:pm-underline pm-text-primary-500"
      v-if="url"
      :href="url"
      target="_blank"
    >
      {{ linkLabel }}
    </a>
    <router-link
      class="pm-text-xxs pm-font-medium hover:pm-underline pm-text-primary-500"
      v-if="route"
      :to="route"
    >
      {{ linkLabel }}
    </router-link>
    <a
      href="#"
      v-if="action"
      @click.prevent="action()"
      class="pm-text-xxs pm-font-medium hover:pm-underline pm-text-primary-500"
    >
      {{ linkLabel }}
    </a>
  </section>
</template>

<script>
export default {
  props: {
    title: String,
    desc: String,
    icon: String,
    route: Object,
    url: String,
    linkLabel: String,
    action: Function,
  },
};
</script>
