import theme from "@/config/tailwind-theme";
import { sortBy } from "lodash-es";

/**============================================
 *               TARGET
 *=============================================**/
export const target = [
  { label: "New Tab", value: "_blank" },
  { label: "Parent Tab", value: "_parent" },
  { label: "Top Tab", value: "_top" },
];

/**============================================
 *               OPACITY
 *=============================================**/
export const opacity = Object.keys(theme.opacity)
  .map((key) => {
    return {
      label: key,
      value: parseInt(key),
    };
  })
  .reverse();

/**============================================
 *               POSITION
 *=============================================**/
export const position = [
  {
    value: "left-top",
    label: "Left-Top",
    icon: "MdiArrowTopLeft",
  },
  {
    value: "top",
    label: "Top",
    icon: "MdiArrowUp",
  },
  {
    value: "right-top",
    label: "Right-Top",
    icon: "MdiArrowTopRight",
  },
  {
    value: "left",
    label: "Left",
    icon: "MdiArrowLeft",
  },
  {
    value: "center",
    label: "Center",
    icon: "MdiCircleMedium",
  },
  {
    value: "right",
    label: "Right",
    icon: "MdiArrowRight",
  },
  {
    value: "left-bottom",
    label: "Left-Bottom",
    icon: "MdiArrowBottomLeft",
  },
  {
    value: "bottom",
    label: "Bottom",
    icon: "MdiArrowDown",
  },
  {
    value: "right-bottom",
    label: "Right-Bottom",
    icon: "MdiArrowBottomRight",
  },
];

/**============================================
 *               SPACING
 *=============================================**/

/**
 * Tailwind has a utility for 1px margin
 * while other scalling is incremental in rem unit.
 * So skipping 1px scale until there is a special way to do
 * this in inspector.
 */

export const spacing = sortBy(
  Object.keys(theme.spacing)
    .filter((key) => key != "px")
    .map((key) => {
      return {
        label: `${key} (${key * 4}px)`,
        value: parseFloat(key),
      };
    }),
  (item) => item.value
);


/**============================================
 *               Units
 *=============================================**/


export const units = [
  { label: "cm", value: "cm" },
  { label: "mm", value: "mm" },
  { label: "in", value: "in" },
  { label: "px", value: "px" },
  { label: "pt", value: "pt" },
  { label: "pc", value: "pc" },
  { label: "ex", value: "ex" },
  { label: "em", value: "em" },
  { label: "ch", value: "ch" },
  { label: "rem", value: "rem" },
  { label: "vw", value: "vw" },
  { label: "vh", value: "vh" },
  { label: "vmin", value: "vmin" },
  { label: "vmax", value: "vmax" },
  { label: "%", value: "%" }
]


/**============================================
 *        theme and custom tabs props
 *=============================================**/

export const themeTabs = [
  {
      label: "Theme",
      value: "theme",
  },
  {
      label: "Custom",
      value: "custom",
  },
]


/**============================================
 *               tooltip delay
 *=============================================**/
export const tooltipDalay = {show: 500, hide: 0}