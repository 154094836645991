<template>
  <Slideover id="media-manager" :title="title" :size="300">
    <Tab v-if="embeddable" v-model="tab" :tabs="$options.tabs" horizontal full>
      <template #internal>
        <Internal :value="valueArray" @input="$emit('select', $event)" />
      </template>
      <template #embed>
        <MediaEmbed
          :source="$options.embedSources.includes(source) ? source : null"
          :value="value"
          @input="$emit('select', $event)"
        />
      </template>
    </Tab>

    <Internal v-else @input="$emit('select', $event)" />
  </Slideover>
</template>

<script>
import MediaEmbed from "./Embed";
import Internal from "./Internal";

export default {
  props: {
    value: [String, Array],
    source: String,
    embeddable: Boolean,
    title: {
      type: String,
      default: "Select Media",
    },
  },
  components: {
    MediaEmbed,
    Internal,
  },
  tabs: [
    {
      label: "Gallery",
      id: "internal",
      icon: "MdiImageMultiple",
    },
    {
      label: "Embed",
      id: "embed",
      icon: "MdiCodeTags",
    },
  ],
  embedSources: ["embed", "svg", "external"],
  data() {
    return {
      tab: this.$options.embedSources.includes(this.source)
        ? "embed"
        : "internal",
    };
  },
  computed: {
    valueArray() {
      return Array.isArray(this.value) ? this.value : [this.value];
    },
  },
};
</script>

<style lang="scss">
.tab__content--svg {
  @apply pm-h-full;
}
</style>
