<template>
  <IAccordion title="Grid Item" open>
    <div class="field-group g-3">
      <ISlider label="Span" :options="$options.span" prop="span" />
    </div>
  </IAccordion>
</template>

<script>
const span = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => {
  return {
    label: String(item),
    value: item,
  };
});

export default {
  span,
};
</script>
