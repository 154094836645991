<template>
  <div class="navigator">
    <TheHeader />

    <!-- <Draggable v-model="myArray" group="people" @start="drag=true" @end="drag=false"> -->
    <div class="navigator__modules">
      <NAccordion
        v-for="(module, index) in modules"
        :index="index + 1"
        :label="module.label"
        :desc="module.desc"
        :key="module.uuid"
        :uuid="module.uuid"
        :is-settings-active="settingsUuid == module.uuid"
        @settings="settingsUuid = module.uuid"
      >
        <Layers
          :children="layers2[module.uuid]"
          :module-uuid="module.uuid"
          class="pm-overflow-auto"
        />
      </NAccordion>

      <div
        class="pm-flex pm-h-10 pm-shrink-0 pm-cursor-pointer pm-items-center pm-justify-center pm-border-b hover:pm-bg-gray-50"
        @click="appendModule()"
      >
        <Icon class="pm-h-5 pm-w-5 pm-text-gray-400" name="MdiPlus" />
        <p class="pm-ml-2 pm-text-xs pm-font-medium pm-text-gray-500">
          Add New Module
        </p>
      </div>
    </div>
    <!-- </Draggable> -->

    <Slideover
      id="module-settings"
      position="left"
      :title="`Module Settings`"
      :size="200"
      @close="settingsUuid = null"
    >
      <ModuleSettings :uuid="settingsUuid" />
    </Slideover>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { startCase } from "lodash-es";
import listToTree from "list-to-tree-lite";
import segmentConfig from "@/config/segments";
import TheHeader from "./Header";
import ModuleSettings from "../module-settings/Index";

export default {
  props: {
    data: Array,
  },

  components: {
    TheHeader,
    ModuleSettings,
  },

  watch: {
    settingsUuid(newValue) {
      if (newValue != null) {
        this.$slideover.open("module-settings");
      } else {
        this.$slideover.close("module-settings");
      }
    },
  },

  data() {
    return {
      settingsUuid: null,
    };
  },

  computed: {
    ...mapState({
      uuid: (state) => state.editor.activeSegmentModuleUuid,
      layers2: (state) => state.modules.layers,
    }),

    ...mapGetters({
      allModules: "modules/modules",
      visibleModules: "modules/visibleModules",
      segments: "modules/segments",
    }),

    modules() {
      return this.visibleModules.map((module) => {
        return {
          label: startCase(module.name),
          uuid: module.uuid,
          desc: module.tags.join(", "),
          layers: this.layers(module.uuid),
        };
      });
    },
  },

  methods: {
    ...mapMutations({
      setActiveSegment: "editor/setActiveSegment",
    }),

    layers(uuid) {
      const segments = this.segments[uuid];
      if (!segments || !Array.isArray(segments)) {
        return [];
      }

      const layers = segments.map((segment) => {
        const name = segment.name.split(">");
        const _parent = name[name.length - 2];
        const _id = name[name.length - 1];

        const layerData = {
          label: startCase(_id),
          _id,
          _parent,
          icon: segmentConfig[segment.tag]?.icon,
          isHidden: segment.props?.isHidden,
          name: segment.name,
          isGroup: Array.isArray(segment.propsByIndex),
          tag: segment.tag,
        };

        if (segment.tag === "pm-grid") {
          layerData.childrenCount = segment.props.count;
        }

        return layerData;
      });

      const layerTree = listToTree(layers, {
        idKey: "_id",
        parentKey: "_parent",
      });
      return layerTree;
    },

    appendModule() {
      this.$modal.open("module-type-selector", this.allModules.length);
    },
  },
};
</script>

<style lang="scss">
.navigator {
  grid-row: 1 / 3;
  grid-column: 1 / 2;
  @apply pm-flex pm-flex-col;
  @apply pm-border-r pm-border-gray-200;

  .layer {
    .layers {
      @apply pm-ml-6 pm-border-l pm-border-gray-200;
    }
  }
}

.navigator__modules {
  @apply pm-flex pm-flex-col pm-overflow-auto;
}
</style>
