<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import Page from "@/components/editor/Page";
import Vuex from "vuex";

import editor from "@/store/modules/editor";
import modules from "@/store/modules/modules";
import template from "@/store/modules/template";

/**
 * This iframe rendering idea is inspired by:
 * stackoverflow.com/a/43100695/3165956
 */
export default {
  render(createElement) {
    return createElement("iframe", {
      ref: "iframe",
      attrs: {
        id: "editor-iframe",
        name: "editor-iframe",
        class: `editor-iframe`,
      },
      on: {
        load: this.renderChildren,
      },
    });
  },

  inject: ["PAGE_DATA"],

  computed: {
    ...mapGetters({
      variables: "template/variables",
      type: "template/type",
    }),
  },

  methods: {
    renderChildren() {
      const body = this.$el.contentDocument.body;
      const el = document.createElement("DIV");
      el.innerHTML = "Loading...";
      const pageData = this.PAGE_DATA;
      body.appendChild(el);

      const app = new Vue({
        store: new Vuex.Store({
          modules: {
            editor,
            modules,
            template,
          },
        }),
        components: {
          Page,
        },
        provide: {
          PAGE_DATA: pageData,
          VARIABLES: () => this.variables,
          TEMPLATE_TYPE: () => this.type,
        },
        render: (createElement) => {
          return createElement("Page");
        },
      });
      app.$mount(el);
      this.$emit("rendered");
    },
  },
};
</script>

<style lang="scss">
.editor-iframe {
  @apply pm-h-full pm-w-full;
}

.editor-iframe--modules {
  @apply pm-h-screen pm-w-screen;
}
</style>
