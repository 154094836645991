<template>
    <IAccordion title="Border" prop-group="border">
        <div class="pm-space-y-3">
            <IuiBox
                class="pm-w-full pm-border"
                :x-locked="xLocked"
                :y-locked="yLocked"
                :all-locked="allLocked"
            >
                <template v-slot:top>
                    <IBorderInput
                        :options="$options.widths"
                        :value-input="(value) => valueInput(value, 'y')"
                        :props-output="
                            (props) => propsOutput(props, 'borderT', 'y')
                        "
                        prop="borderT"
                        name="top-space"
                        :heading="topBorderHeading"
                        class="pm-bg-gray-100"
                        :class="{
                            '!pm-bg-primary-50': isActive(
                                'top',
                                'bottom',
                                yLocked
                            ),
                        }"
                        @show="active = 'top'"
                        @hide="active = active === 'top' ? null : active"
                    />
                </template>
                <template v-slot:right>
                    <IBorderInput
                        :options="$options.widths"
                        :value-input="(value) => valueInput(value, 'x')"
                        :props-output="
                            (props) => propsOutput(props, 'borderR', 'x')
                        "
                        prop="borderR"
                        name="right-space"
                        :heading="rightBorderHeading"
                        :class="{
                            '!pm-bg-primary-50': isActive(
                                'right',
                                'left',
                                xLocked
                            ),
                        }"
                        @show="active = 'right'"
                        @hide="active = active === 'right' ? null : active"
                    />
                </template>
                <template v-slot:bottom>
                    <IBorderInput
                        :options="$options.widths"
                        :value-input="(value) => valueInput(value, 'y')"
                        :props-output="
                            (props) => propsOutput(props, 'borderB', 'y')
                        "
                        prop="borderB"
                        name="bottom-space"
                        :heading="bottomBorderHeading"
                        class="pm-bg-gray-100"
                        :class="{
                            '!pm-bg-primary-50': isActive(
                                'bottom',
                                'top',
                                yLocked
                            ),
                        }"
                        @show="active = 'bottom'"
                        @hide="active = active === 'bottom' ? null : active"
                    />
                </template>
                <template v-slot:left>
                    <IBorderInput
                        :options="$options.widths"
                        :value-input="(value) => valueInput(value, 'x')"
                        :props-output="
                            (props) => propsOutput(props, 'borderL', 'x')
                        "
                        prop="borderL"
                        name="left-space"
                        :heading="leftBorderHeading"
                        :class="{
                            '!pm-bg-primary-50': isActive(
                                'left',
                                'right',
                                xLocked
                            ),
                        }"
                        @show="active = 'left'"
                        @hide="active = active === 'left' ? null : active"
                    />
                </template>
                <template>
                    <div
                        class="pm-flex pm-relative pm-justify-center pm-items-center pm-h-full pm-aspect-1"
                    >
                        <SpaceLock
                            v-tooltip="{content:'Lock Horizontal Border', delay:$options.d}"
                            prop="borderX"
                            :value-input="
                                (value) => (value == null ? false : 'DEFAULT')
                            "
                            orientation="x"
                            :value-output="
                                (value) => (value == false ? null : 'DEFAULT')
                            "
                            :side-effect="(props) => lockSideEffect(props)"
                        />
                        <SpaceLock
                            v-tooltip="{content:'Lock Vertical Border', delay:$options.d}"
                            prop="borderY"
                            :value-input="
                                (value) => (value == null ? false : 'DEFAULT')
                            "
                            orientation="y"
                            :value-output="
                                (value) => (value == false ? null : 'DEFAULT')
                            "
                            :side-effect="(props) => lockSideEffect(props)"
                        />
                        <SpaceLock
                            v-tooltip="{content:'Lock Border', delay:$options.d}"
                            prop="border"
                            :value-input="
                                (value) => (value == null ? false : 'DEFAULT')
                            "
                            orientation="all"
                            :value-output="
                                (value) => (value == false ? null : 'DEFAULT')
                            "
                            :side-effect="(props) => lockSideEffect(props)"
                        />
                    </div>
                </template>
            </IuiBox>
            <IColor prop="borderColor" />
            <IButtonIconGroup
                label="Style"
                prop="borderStyle"
                :options="$options.borderStyles"
            />
        </div>
    </IAccordion>
</template>

<script>
import { mapGetters } from "vuex";
import { tooltipDalay } from "@/config/prop-values";
import SpaceLock from "../inputs/SpaceLock";
import { IuiBox } from "inspector-ui";
/**
 * multiplier is 1
 * 0: 0px
 * default: 1px
 * 2: 2px
 * 4: 4px
 * 8: 8px
 */
const widths = [0, "DEFAULT", 2, 4, 8].map((item) => {
    return {
        label: (item == "DEFAULT" ? 1 : item) + 'px',
        value: item,
    };
});

const borderStyles = [
    {
        label: "Solid",
        value: "solid",
        icon: "SolidBorder",
    },
    {
        label: "Dotted",
        value: "dotted",
        icon: "DottedBorder",
    },
    {
        label: "Dashed",
        value: "dashed",
        icon: "DashedBorder",
    },
    {
        label: "Double",
        value: "double",
        icon: "DoubleBorder",
    },
];

export default {
    d: tooltipDalay,
    widths,
    borderStyles,
    components: {
        SpaceLock,
        IuiBox,
    },
    data() {
        return {
            active: null,
        };
    },

    computed: {
        ...mapGetters({
            propValueFinder: "editor/activeSegmentPropValue",
        }),
        allLocked() {
            return this.propValueFinder("border") != null;
        },
        xLocked() {
            return this.propValueFinder("borderX") != null;
        },
        yLocked() {
            return this.propValueFinder("borderY") != null;
        },
        topBorderHeading() {
            if (this.yLocked) return "Vertical Border";
            if (this.allLocked) return "Border";
            return "Top Border";
        },
        rightBorderHeading() {
            if (this.yLocked) return "Horizontal Border";
            if (this.allLocked) return "Border";
            return "Right Border";
        },
        bottomBorderHeading() {
            if (this.yLocked) return "Vertical Border";
            if (this.allLocked) return "Border";
            return "Bottom Border";
        },
        leftBorderHeading() {
            if (this.yLocked) return "Horizontal Border";
            if (this.allLocked) return "Border";
            return "Left Border";
        },
    },

    methods: {
        lockSideEffect(props) {
            if (props.border == "DEFAULT") {
                props.borderL = null;
                props.borderR = null;
                props.borderT = null;
                props.borderB = null;
                props.borderX = null;
                props.borderY = null;
            }
            if (props.borderX == 'DEFAULT') {
                props.borderL = null;
                props.borderR = null;
                props.border = null;
            }
            if (props.borderY == 'DEFAULT') {
                props.borderT = null;
                props.borderB = null;
                props.border = null;
            }
            return { props };
        },

        valueInput(value, dir) {
            if (this.allLocked) {
                return this.propValueFinder("border");
            } else if (dir == "x" && this.xLocked) {
                return this.propValueFinder("borderX");
            } else if (dir == "y" && this.yLocked) {
                return this.propValueFinder("borderY");
            } else {
                return value;
            }
        },

        propsOutput(props, prop, dir) {
            if (this.allLocked) {
                return {
                    ...props,
                    borderL: null,
                    borderR: null,
                    borderT: null,
                    borderB: null,
                    borderY: null,
                    borderX: null,
                    border: props[prop],
                };
            } else if (dir == "x" && this.xLocked) {
                return {
                    ...props,
                    borderL: null,
                    borderR: null,
                    border: null,
                    borderX: props[prop],
                };
            } else if (dir == "y" && this.yLocked) {
                return {
                    ...props,
                    borderT: null,
                    borderB: null,
                    border: null,
                    borderY: props[prop],
                };
            } else {
                return props;
            }
        },
        isActive(side, opposite, locked) {
            return (
                this.active === side ||
                (this.active === opposite && locked) ||
                (this.active && this.allLocked)
            );
        },
    },
};
</script>
