<template>
  <div
    class="tab"
    :class="{ 'tab--horizontal': horizontal, 'tab--full': full }"
  >
    <div class="tab__list">
      <TabButton
        v-for="tab in tabs"
        :key="tab.id"
        @click.native="$emit('input', tab.id)"
        :label="tab.label"
        :icon="tab.icon"
        :tooltip="tab.tooltip"
        :active="tab.id == value"
        :horizontal="horizontal"
      />
    </div>
    <div class="tab__container">
      <template v-for="tab in tabs">
        <div
          v-if="tab.id == value"
          class="tab__content"
          :class="`tab__content--${tab.id}`"
          :key="`tab-${tab.id}`"
        >
          <slot :name="tab.id"></slot>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import TabButton from "./TabButton";

export default {
  components: {
    TabButton,
  },
  props: {
    tabs: Array,
    value: String,
    horizontal: Boolean,
    full: Boolean,
  },
};
</script>

<style lang="scss">
.tab {
  @apply pm-flex pm-flex-nowrap pm-items-stretch;
}
.tab__list {
  @apply pm-flex-shrink-0 pm-flex-grow-0;
}
.tab__container {
  @apply pm-flex-grow;
}

.tab--horizontal {
  @apply pm-block;
  .tab__list {
    @apply pm-flex pm-flex-nowrap;
  }
}

.tab--full {
  @apply pm-h-full pm-flex pm-flex-col;
  .tab__container {
    @apply pm-flex-grow pm-overflow-auto;
  }
}
</style>
