<template>
  <div class="badge" :class="classList">
    <Icon class="badge__icon" v-if="icon" :name="icon"></Icon>
    <span class="badge__label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    icon: String,
    color: String,
  },
  computed: {
    classList() {
      const classList = [`badge--${this.color}`];
      return classList;
    },
  },
};
</script>

<style lang="scss">
.badge {
  background-color: var(--bg);
  color: var(--text);
  outline: none !important;
  @apply pm-h-6 pm-text-xxs pm-pr-2 pm-pl-1 pm-rounded-full pm-tracking-wider;
  @apply pm-inline-flex pm-items-center pm-justify-center pm-font-medium pm-leading-none pm-relative pm-select-none pm-uppercase;
  .icon {
    @apply pm-w-4 pm-h-4;
  }
}

.badge__icon {
  @apply pm-mr-1;
}

.badge--primary {
  --bg: theme("colors.primary.100");
  --text: theme("colors.primary.500");
}

.badge--success {
  --bg: theme("colors.success.100");
  --text: theme("colors.success.500");
}

.badge--warning {
  --bg: theme("colors.warning.100");
  --text: theme("colors.warning.500");
}

.badge--danger {
  --bg: theme("colors.danger.100");
  --text: theme("colors.danger.500");
}

.badge--gray {
  --bg: theme("colors.gray.100");
  --text: theme("colors.gray.500");
}
</style>
