const state = {
  js: null,
  colors: null,
  status: null,
  variables: null,
  type: null,
  hash: null,
  css: null,
  userCss: null,
  fonts: null,
  commonJs: null,
};

const getters = {
  css(state) {
    return state.css;
  },
  userCss(state) {
    return state.userCss;
  },
  fonts(state) {
    return state.fonts;
  },
  hash(state) {
    return state.hash;
  },
  type(state) {
    return state.type;
  },
  colors(state) {
    return state.colors;
  },
  status(state) {
    return state.status;
  },
  variables(state) {
    return state.variables;
  },
  commonJs(state) {
    return state.commonJs;
  },
  js(state) {
    return state.js;
  },
};

const mutations = {
  set(state, data) {
    const { css, js, campaignCss, fonts, type, hash, colors, variables, commonJs } =
      data;
    state.js = js;
    state.css = css;
    state.fonts = fonts;
    state.type = type;
    state.hash = hash;
    state.colors = colors;
    state.userCss = campaignCss;
    state.variables = variables;
    state.commonJs = commonJs;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
