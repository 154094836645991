<template>
  <div class="media-input">
    <MediaManager :value="value" :source="activeSegmentProps.source" @select="input($event)" embeddable />
    <IuiField label="Value" :disabled="isDisabled" :responsive="hasResponsive" >
      <IuiMedia :isLocked="isLocked" :value="value" @open="open" @input="input" />
    </IuiField>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import inspectorInput from "@/mixins/inspector-input";
import MediaManager from "@/components/media-manager/Index";
import { IuiMedia, IuiField } from "inspector-ui"

export default {
  inheritAttrs: false,
  inject: ["PAGE_DATA"],
  mixins: [inspectorInput],
  components: {
    MediaManager,
    IuiMedia,
    IuiField
  },
  computed: {
    ...mapGetters({
      activeSegmentProps: "editor/activeSegmentProps",
    }),
  },
  methods: {
    input(data) {
      this.updateMultiple({
        [this.prop]: data.value,
        source: data.source,
      });
    },
    open() {
      this.$slideover.open("media-manager");
    },
  },
};
</script>
