<template>
  <input
    class="pm-h-7 pm-w-full pm-text-xs pm-font-medium !pm-outline-none pm-shadow-none"
    :class="theme && `textbox--${theme}`"
    :value="value"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    value: [String, Date, Number],
    theme: {
      type: String,
      default: "default",
    },
  },
};
</script>

<style lang="scss">

.textbox--default {
  @apply pm-rounded-sm pm-border-0 pm-p-0;
  &:hover,
  &:focus {
    @apply pm-bg-gray-100;
    box-shadow: -4px 0 0 0 theme("colors.gray.100"),
      4px 0 0 0 theme("colors.gray.100");
  }
}

.textbox--underline {
  @apply pm-border-b pm-border-solid pm-border-gray-200;
  &:hover {
    @apply pm-border-gray-400;
  }
  &:focus {
    @apply pm-border-primary-500;
  }
}
</style>
