<template>
  <div class="header">
    <div><Status /></div>
    <div class="pm-text-center"><Breakpoints /></div>
    <div class="pm-text-right"><Actions /></div>
  </div>
</template>

<script>
import Breakpoints from "./Breakpoints";
import Status from "./Status";
import Actions from "./Actions";

export default {
  components: {
    Breakpoints,
    Status,
    Actions,
  },
};
</script>

<style lang="scss">
.header {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  @apply pm-border-b pm-border-gray-200 pm-grid pm-grid-cols-3;
}
</style>
