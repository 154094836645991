import { merge } from "lodash-es";
import { generatePropVariantsByTag } from "@pagemakerhq/segments/src-utils/index";
import { breakpoints } from "@pagemakerhq/segments/src-config/index";

const state = {
  module: {},
  breakpoint: "base",
  breakpointWidth: "375",
  isUnsaved: false,
  inspectorState: "default",
  loading: false,

  /**
   *  Previously when user clicks outside the editor, we are calling the `setActiveSegment`
   *  mutation without any data. So it is making the active segment null which is creating
   *  the issue for pending actions like `updateSegment` mutation.
   *  To solve this we have added this state and using it in highlighter
   *  and inspector component when active segment is not set.
   *  */
  isSegmentActive: false,

  activeSegmentIndex: null,
  activeSegmentName: null,
  activeSegmentModuleUuid: null,
  interruptReload: true,
};

const getters = {
  interruptReload(state) {
    return state.interruptReload;
  },

  loading(state) {
    return state.loading;
  },

  isUnsaved(state) {
    return state.isUnsaved;
  },

  isDefault(state) {
    return state.inspectorState == "default";
  },

  isHover(state) {
    return state.inspectorState == "hover";
  },

  breakpoint(state) {
    return state.breakpoint || "base";
  },

  breakpointWidth(state) {
    return state.breakpointWidth != "100%"
      ? state.breakpointWidth + "px"
      : "100%";
  },

  modifiers(state, getters) {
    return {
      breakpoint: getters.breakpoint,
      hover: getters.isHover,
    };
  },

  tags(state) {
    return state.tags;
  },

  activeSegmentData(state, getters, rootState, rootGetters) {
    if (!(state.activeSegmentModuleUuid && state.activeSegmentName)) return {};

    const segments =
      rootGetters["modules/segments"][state.activeSegmentModuleUuid];
    if (!segments) return {};

    const segment = segments.find(
      (segment) => segment.name == state.activeSegmentName
    );
    return segment || {};
  },

  activeSegmentProps(state, getters) {
    const { props, propsByIndex } = getters.activeSegmentData;
    if (state.activeSegmentIndex != null) {
      return merge({}, props, propsByIndex?.[state.activeSegmentIndex]);
    }
    return props;
  },

  activeSegmentTag(state, getters) {
    return getters.activeSegmentData.tag;
  },

  activeSegmentIndex(state) {
    return state.activeSegmentIndex;
  },

  activeSegmentName(state) {
    return state.activeSegmentName;
  },

  activeSegmentModuleUuid(state) {
    return state.activeSegmentModuleUuid;
  },

  activeSegmentPropValue:
    (state, getters, rootState, rootGetters) => (prop) => {
      const tag = rootGetters["editor/activeSegmentTag"];
      const modifiers = rootGetters["editor/modifiers"];
      const segmentProps = rootGetters["editor/activeSegmentProps"];

      const { hover, breakpoint, dark } = modifiers;
      const breakpointsClone = [...breakpoints];

      const breakpointIndex = breakpointsClone.indexOf(breakpoint);
      const breakpointsToRemove = breakpointsClone.splice(breakpointIndex + 1);

      const propsForInheritance = generatePropVariantsByTag(tag, prop)
        .filter((variantProp) => {
          return !breakpointsToRemove.some((breakpoint) =>
            variantProp.includes(breakpoint)
          );
        })
        .filter((variantProp) => {
          if (hover && dark) {
            return (
              variantProp.includes("hover") && variantProp.includes("dark")
            );
          } else if (hover) {
            return (
              variantProp.includes("hover") && !variantProp.includes("dark")
            );
          } else if (dark) {
            return (
              variantProp.includes("dark") && !variantProp.includes("hover")
            );
          }
          return (
            !variantProp.includes("dark") && !variantProp.includes("hover")
          );
        })
        .reverse();

      const applicableProp = propsForInheritance.find(
        (item) => segmentProps[item] !== undefined
      );

      return segmentProps[applicableProp];
    },

  isSegmentActive(state) {
    return state.isSegmentActive;
  },
};

const mutations = {
  setInterruptReload(state, value) {
    state.interruptReload = value;
  },

  setInspectorState(state, value) {
    state.inspectorState = value;
  },

  setLoading(state, value) {
    state.loading = value;
  },

  setGroupEditing(state, value) {
    state.activeSegmentIndex = null;
  },

  setBreakpoint(state, data) {
    state.breakpoint = data.value;
    state.breakpointWidth = data.width;
  },

  setUnsaved(state, value) {
    state.isUnsaved = value;
  },

  setActiveSegment(state, data) {
    if (data) {
      const { segmentIndex, moduleUuid, segmentName } = data;
      state.isSegmentActive = true;
      state.activeSegmentName = segmentName;
      state.activeSegmentIndex = segmentIndex;
      state.activeSegmentModuleUuid = moduleUuid;
    } else {
      state.isSegmentActive = false;
      state.activeSegmentName = null;
      state.activeSegmentIndex = null;
      state.activeSegmentModuleUuid = null;
    }
  },

  setIsSegmentActive(state, value) {
    state.isSegmentActive = value;
  },
};

const actions = {};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
