<template>
  <section class="s-accordion">
    <header @click="isOpen = !isOpen">
      <Icon
        class="s-accordion__arrow"
        :name="isOpen ? 'MdiMenuDown' : 'MdiMenuRight'"
      />
      <p class="s-accordion__title">{{ label }}</p>
      <Icon
        v-if="help"
        class="s-accordion__help"
        name="MdiHelp"
        v-tooltip="help"
      />
    </header>
    <div class="s-accordion__content" v-if="isOpen">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    label: String,
    help: String,
    open: Boolean,
  },
  data() {
    return {
      isOpen: this.open,
    };
  },
};
</script>

<style lang="scss">
.s-accordion {
  @apply pm-border-b pm-border-gray-200;
  header {
    @apply pm-h-7 pm-px-1 pm-bg-gray-100 pm-flex pm-items-center pm-flex-nowrap pm-cursor-pointer;
    &:hover {
      .s-accordion__title {
        @apply pm-text-gray-500;
      }
    }
  }
}
.s-accordion__arrow {
  @apply pm-flex-grow-0 pm-flex-shrink-0 pm-text-gray-400;
}
.s-accordion__title {
  @apply pm-px-1 pm-text-xxs pm-text-gray-400 pm-uppercase pm-font-medium;
  letter-spacing: 1px;
}
.s-accordion__content {
  @apply pm-border-gray-200 pm-border-t;
}
.s-accordion__help {
  @apply pm-text-gray-400 pm-ml-auto pm-w-3 pm-h-3 pm-mr-1;
}
</style>
