const breakpoints = [
  {
    width: 375,
    value: "base",
    label: "Mobile",
    icon: "MdiCellphone",
  },
  {
    width: 640,
    value: "sm",
    label: "Tablet",
    icon: "MdiTabletAndroid",
  },
  {
    width: 768,
    value: "md",
    label: "Large Tablet",
    icon: "MdiTablet",
  },
  {
    width: 1024,
    value: "lg",
    label: "Laptop",
    icon: "MdiLaptop",
  },
  {
    width: 1280,
    value: "xl",
    label: "Desktop",
    icon: "MdiMonitor",
  },
  {
    width: "100%",
    value: "full",
    label: "Full Width",
    icon: "MdiArrowExpandHorizontal",
  },
];

export default breakpoints;

export const width = (breakpoint) => {
  const data = breakpoints.find((item) => item.value == breakpoint);
  if (data.value == "full") {
    return "100%";
  } else {
    return data.width;
  }
};
