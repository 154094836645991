<template>
  <IAccordion title="Shopify Product" open>
    <ISelect
      label="Product"
      prop="productId"
      :request="productList"
      :side-effect="sideEffect"
    />
  </IAccordion>
</template>

<script>
import list from "@/gql/shopifyProduct/list.gql";
import get from "@/gql/shopifyProduct/get.gql";

export default {
  inject: ["PAGE_DATA"],

  data() {
    return {
      products: null,
    };
  },

  methods: {
    productList() {
      return this.$apollo
        .query(list, {
          workspaceId: this.PAGE_DATA.workspaceId,
          campaignId: this.PAGE_DATA.campaignId,
        })
        .then((data) => {
          this.products = data?.data?.shopifyProducts;

          return this.products.map((product) => {
            return {
              label: product.title,
              value: product.id,
            };
          });
        });
    },

    async sideEffect({ productId }) {
      if (!productId) return;

      const { shopifyProduct } = await this.$apollo
        .query(get, {
          id: productId,
          workspaceId: this.PAGE_DATA.workspaceId,
          campaignId: this.PAGE_DATA.campaignId,
        })
        .then(({ data }) => data);

      const { title, bodyHtml, image, images, variants, attributes } = shopifyProduct;

      const price = variants[0]?.price;
      const compareAtPrice = variants[0]?.compareAtPrice;

      return {
        props: {
          title,
          image,
          price,
          images,
          bodyHtml,
          variants,
          attributes,
          compareAtPrice,
          hasVariants: variants.length > 1 ? true : false,
        },
        config: {
          refreshLayers: true,
        },
      };
    },
  },
};
</script>
