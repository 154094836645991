export default {
  neutral: ["transparent", "black", "white"],
  brand: ["primary", "secondary"],
  palette: [
    "rose",
    "pink",
    "fuchsia",
    "purple",
    "violet",
    "indigo",
    "blue",
    "light-blue",
    "cyan",
    "teal",
    "emerald",
    "green",
    "lime",
    "yellow",
    "amber",
    "orange",
    "red",
    "warm-gray",
    "true-gray",
    "gray",
    "cool-gray",
    "blue-gray",
  ],
};
