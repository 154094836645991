<template>
  <IAccordion title="Number Input" open>
    <ITextbox label="Minimum" prop="min" type="number" />
    <ITextbox label="Maximum" prop="max" type="number" />
  </IAccordion>
</template>

<script>
export default {
  inject: ["PAGE_DATA"],

  data() {
    return {};
  },
};
</script>
