<template>

  <span class="pm-flex pm-items-center pm-rounded-sm pm-w-full pm-py-1 hover:pm-bg-gray-100 color-preview"
    @click="$emit('click')">
    <slot name="color"></slot>
    <p class="pm-text-xs pm-font-medium">
      {{ value[0] == "#"?value:($options.startCase(value) || "Select") }}
    </p>
    <ButtonIcon class="pm-ml-auto" v-if="value" icon="MdiClose" @click.native="$emit('update')" />
  </span>

</template>

<script>
import { startCase } from "lodash-es";
import Field from "./Field.vue";
import ButtonIcon from "./ButtonIcon.vue";

export default {
  startCase,
  components: {
    Field,
    ButtonIcon,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
};
</script>
