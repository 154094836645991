<template>
    <div class="radius-box pm-w-32 pm-h-32 pm-relative" :class="{ 'top-lock': topLocked, 'right-lock': rightLocked, 'bottom-lock': bottomLocked, 'left-lock': leftLocked, 'all-lock': allLocked}" >
        <div class="pm-flex pm-flex-col pm-w-full pm-h-full pm-border pm-rounded-2xl pm-overflow-hidden pm-divide-y">
            <div class="pm-flex  pm-grow pm-divide-x">
                <div class="pm-w-1/2 pm-relative top-left">
                    <slot name="top-left"></slot>
                </div>
                <div class="pm-w-1/2 pm-relative top-right">
                    <slot name="top-right"></slot>
                </div>
            </div>
            <div class="pm-flex pm-grow pm-divide-x">
                <div class="pm-w-1/2 pm-relative bottom-left">
                    <slot name="bottom-left"></slot>
                </div>
                <div class="pm-w-1/2 pm-relative bottom-right">
                    <slot name="bottom-right"></slot>
                </div>
            </div>
        </div>
        <div
            class="pm-absolute pm-w-2/5 pm-h-2/5 pm-border pm-top-1/2 pm-left-1/2 -pm-translate-x-1/2 -pm-translate-y-1/2 pm-rounded-xl pm-bg-white pm-flex pm-justify-center pm-items-center">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        topLocked: {
            type: Boolean,
            default: false
        },
        rightLocked: {
            type: Boolean,
            default: false
        },
        bottomLocked: {
            type: Boolean,
            default: false
        },
        leftLocked: {
            type: Boolean,
            default: false
        },
        allLocked: {
            type: Boolean,
            default: false
        }
    },
    methods: {

    },
};
</script>
