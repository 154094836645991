<template>
  <div class="breakpoints">
    <TheButton
      v-for="breakpoint in breakpoints"
      :key="breakpoint.value"
      :active="breakpoint.value == active"
      :icon="breakpoint.icon"
      @click.native="setActiveBreakpoint(breakpoint.value)"
    />
      <div class="pm-flex pm-items-center pm-pr-2">
        <label for="width" class="pm-flex-col pm-pl-2 pm-text-xs pm-font-medium pm-leading-none">
          Width
        </label>
        <div class="pm-flex pm-border pm-border-solid pm-border-gray-300 pm-rounded pm-ml-1 pm-p-1">
          <input
            type="number"
            name="width"
            v-model="width"
            :disabled="active == 'full'"
            class="breakpoint-width-input pm-w-10 pm-h-5 pm-flex-col pm-text-xs pm-outline-none"
          >
          <div v-if="width != '100%'" class="pm-flex-col pm-cursor-not-allowed pm-border-l pm-border-l-solid pm-border-l-gray-300">
            <p class="pm-text-xs pm-pl-1">PX</p>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import TheButton from "./Button";
import { debounce } from "lodash-es";
import { mapMutations, mapGetters, mapState } from "vuex";
import breakpoints, { width as getBreakpointWidth } from "@/config/breakpoints";

export default {
  components: {
    TheButton,
  },

  data() {
    return {
      breakpoints,
      width: null,
    };
  },

  mounted() {
    this.width = getBreakpointWidth(this.active);
  },

  watch: {
    width: debounce(function (newValue) {
      if (newValue) {
        let breakpoint;
        let baseBreakpointWidth = getBreakpointWidth("base");
        let smBreakpointWidth = getBreakpointWidth("sm");
        let mdBreakpointWidth = getBreakpointWidth("md");
        let lgBreakpointWidth = getBreakpointWidth("lg");
        let xlBreakpointWidth = getBreakpointWidth("xl");

        if (newValue <= baseBreakpointWidth) {
          breakpoint = "base";
        } else if (newValue > baseBreakpointWidth && newValue <= smBreakpointWidth) {
          breakpoint = "sm";
        } else if (newValue > smBreakpointWidth && newValue <= mdBreakpointWidth) {
          breakpoint = "md";
        } else if (newValue > mdBreakpointWidth && newValue <= lgBreakpointWidth) {
          breakpoint = "lg";
        } else if (newValue > lgBreakpointWidth && newValue <= xlBreakpointWidth) {
          breakpoint = "xl";
        } else {
          breakpoint = "full";
          newValue = "100%";
        }

        this.setBreakpoint({
          value: breakpoint,
          width: newValue,
        });

      }
    }, 1000),
  },

  computed: {
    ...mapState("editor", ["unsaved"]),
    ...mapGetters({
      active: "editor/breakpoint",
    }),
  },

  methods: {
    ...mapMutations({
      setBreakpoint: "editor/setBreakpoint",
    }),
    setActiveBreakpoint(breakpoint) {
      this.width = getBreakpointWidth(breakpoint);
      this.setBreakpoint({
        value: breakpoint,
        width: this.width,
      });
    },
  },
};
</script>

<style lang="scss">
.breakpoints {
  @apply pm-inline-flex pm-flex-grow-0 pm-divide-x pm-divide-gray-200 pm-border-l pm-border-r pm-border-gray-200;
}
.breakpoint-width-input::-webkit-inner-spin-button,
.breakpoint-width-input::-webkit-outer-spin-button {
    @apply pm-appearance-none;
}
</style>
