<template>
  <div class="modal-shell" :class="`modal-shell--${size}`">
    <header class="modal-shell__header">
      <p v-if="title" class="pm-pl-4 pm-flex pm-items-center pm-font-medium">
        {{ title }}
      </p>
      <div class="pm-flex-grow">
        <slot name="header"> </slot>
      </div>
      <button class="modal-shell__close" @click="close()">
        <Icon name="MdiClose" class="pm-block pm-w-5 pm-h-5" />
      </button>
    </header>

    <!-- Loading -->
    <div
      class="pm-flex pm-justify-center pm-items-center pm-h-full pm-py-24"
      v-if="loading"
    >
      <Icon
        name="MdiLoading"
        class="pm-animate pm-animate-spin pm-w-8 pm-h-8 pm-text-gray-300"
      />
    </div>

    <!-- Content -->
    <template v-else>
      <div class="modal-shell__body">
        <slot />
      </div>
      <footer v-if="footer" class="modal-shell__footer">
        <slot name="footer">
          <div>
            <slot name="footer-left"> </slot>
          </div>
          <div class="pm-flex pm-space-x-2 pm-justify-end pm-p-3">
            <slot name="footer-actions">
              <PButton label="Cancel" theme="gray" />
              <PButton label="Okay" />
            </slot>
          </div>
        </slot>
      </footer>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    close: Function,
    footer: Boolean,
    size: {
      type: String,
      default: "md",
    },
    loading: Boolean,
  },
};
</script>

<style lang="scss">
.modal-shell {
  @apply pm-flex pm-flex-col pm-rounded-md pm-overflow-hidden pm-bg-white pm-ml-auto pm-mr-auto;
  margin-top: 40px;
  margin-bottom: 40px;
  max-height: calc(100vh - 80px);
}
.modal-shell__header {
  @apply pm-h-12 pm-border-b pm-border-gray-200 pm-flex pm-items-stretch pm-justify-between;
}
.modal-shell__close {
  outline: none !important;
  @apply pm-outline-none pm-w-12 pm-h-12 pm-flex pm-justify-center pm-items-center pm-text-gray-400 hover:pm-text-gray-500;
}
.modal-shell__body {
  @apply pm-flex-grow pm-overflow-auto;
}

.modal-shell--sm {
  width: 320px;
  max-width: 100%;
}

.modal-shell--md {
  width: 480px;
  max-width: 100%;
}

.modal-shell--lg {
  width: 640px;
  max-width: 100%;
}

.modal-shell--fs {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 100%;
  max-width: calc(100vw - 20px);
  max-height: calc(100vh - 20px);
}

.modal-shell__footer {
  @apply pm-flex pm-justify-between pm-items-center pm-border-t pm-border-gray-200;
}
</style>
