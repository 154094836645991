<template>
  <IAccordion title="Menu" open>
    <div class="field-group g-3">
      <IButtonIconGroup
        label="Show Menu From"
        desc="A menu will be visible by default from the selected and larger devices."
        :options="$options.expand"
        prop="expandFrom"
      />

      <IButtonIconGroup
        label="Slide Menu From"
        :options="$options.position"
        prop="itemsPosition"
      />
    </div>
  </IAccordion>
</template>

<script>
import breakpoints from "@/config/breakpoints";

export default {
  expand: breakpoints
    .filter((breakpoint) => breakpoint.value != "full")
    .map((breakpoint) => {
      return {
        label: breakpoint.label,
        value: breakpoint.value,
        icon: breakpoint.icon,
      };
    }),
  position: [
    {
      label: "Top",
      value: "top",
      icon: "MdiArrowUp",
    },
    {
      label: "Right",
      value: "right",
      icon: "MdiArrowRight",
    },
    {
      label: "Bottom",
      value: "bottom",
      icon: "MdiArrowDown",
    },
    {
      label: "Left",
      value: "left",
      icon: "MdiArrowLeft",
    },
  ],
};
</script>
