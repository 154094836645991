<template>
  <IuiField :label="label" :disabled="isDisabled" :responsive="hasResponsive" >
    <IuiSlider

      :icon="icon"
      :tooltip="tooltip"
      :options="options"
      :min="min"
      :max="max"
      :value="value || null"
      :canReset="canReset"
      :display="display"
      @update="update(prop, $event)"
      @reset="reset"
    />
  </IuiField>
  
</template>

<script>
import inspectorInput from "@/mixins/inspector-input";
import { IuiSlider, IuiField } from "inspector-ui"

export default {
  mixins: [inspectorInput],
  components: {
    IuiSlider,
    IuiField
  },

  inheritAttrs: false,
  props: {
    icon: String,
    options: [Array, Object],
    min: Number,
    max: Number,
    tooltip: String,
    canReset: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    display() {
      if (this.options) {
        let item = this.options.find((item) => item.value == this.value);
        return item?.label;
      } else {
        return this.value;
      }
    },
  },

  methods: {
    reset() {
      if (!this.canReset) return;
      this.update(this.prop, null);
    },
  },
};
</script>