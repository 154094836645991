<template>
  <button
    class="slideover-button"
    :class="theme && `slideover-button--${theme}`"
  >
    <span>{{ label }}</span>
    <Icon class="slideover-button__icon" v-if="icon" :name="icon" />
  </button>
</template>

<script>
export default {
  props: {
    label: String,
    icon: String,
    theme: String,
  },
};
</script>

<style lang="scss">
.slideover-button {
  @apply pm-flex pm-flex-nowrap pm-items-center pm-justify-between pm-text-xs pm-h-9 hover:pm-bg-gray-50 pm-w-full pm-px-3 pm-font-bold pm-text-gray-500;

  &:disabled {
    @apply pm-cursor-not-allowed pm-opacity-70;
  }
}
.slideover-button__icon {
  @apply pm-w-4 pm-h-4 pm-text-gray-400;
}

.slideover-button--danger {
  @apply pm-text-danger-500;

  .slideover-button__icon {
    @apply pm-text-danger-400;
  }
}

.slideover-button--warning {
  @apply pm-text-warning-500;

  .slideover-button__icon {
    @apply pm-text-warning-400;
  }
}
</style>
