<template>
  <IuiField :label="label" :desc="desc" :disabled="isDisabled" :responsive="hasResponsive">
    <IuiButtonIconGroup 
      :options="options"
      :icons="icons"
      :value="value"
      @click="input" />
  </IuiField>
</template>

<script>
import inspectorInput from "@/mixins/inspector-input";
import { IuiButtonIconGroup, IuiField } from "inspector-ui";

export default {
  components: {
    IuiField,
    IuiButtonIconGroup,
  },
  mixins: [inspectorInput],
  props: {
    options: Array,
    icons: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    input(option) {
      const value = this.value == option.value ? null : option.value;
      this.update(this.prop, value);
    },
  },
};
</script>

