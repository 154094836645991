<template>
  <PrismEditor
    class="code-editor"
    :class="{ 'code-editor--full-height': fullHeight }"
    :value="value || help"
    @input="$emit('input', $event)"
    :highlight="highlighter"
    line-numbers
  />
</template>

<script>
import { PrismEditor } from "vue-prism-editor";
import { highlight, languages } from "prismjs/components/prism-core";
// import "prismjs";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-markup";
import "prismjs/components/prism-css";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-tomorrow.css";
import "vue-prism-editor/dist/prismeditor.min.css";

export default {
  props: {
    value: String,
    language: String,
    help: String,
    fullHeight: Boolean,
  },
  components: {
    PrismEditor,
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages[this.language]); // languages.<insert language> to return html with markup
    },
  },
};
</script>

<style lang="scss">
.code-editor {
  @apply pm-text-xs pm-p-2 pm-rounded-sm;
  background-color: #111;
  color: #ddd;
  overflow: auto;
  textarea {
    outline: none !important;
  }

  .prism-editor__line-numbers {
    @apply pm-text-xs;
  }
}

.code-editor--full-height {
  // .prism-editor__container {
  //   height: 100%;
  // }
}
</style>
