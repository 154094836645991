<template>
  <button
    class="color"
    :class="`color--${(selected && selected.color) || 'default'}`"
    :style="{ backgroundColor: colorCode }"
  >
    <span class="color__fill">
      <span class="color__content">
        <slot :value="value" />
      </span>
    </span>
  </button>
</template>

<script>
import mixin from "./color";

export default {
  mixins: [mixin],
};
</script>

<style lang="scss">
.color {
  cursor: pointer;
  border: none;
  position: relative;
  outline: none !important;
  &:hover {
    z-index: 2;
    @apply pm-ring-2 pm-ring-white;
  }
}

.color__fill {
  display: block;
  padding-bottom: 100%;
}
.color__content {
  @apply pm-flex pm-items-center pm-justify-center pm-inset-0 pm-absolute;
}

.color--white,
.color--transparent,
.color--black,
.color--current {
  overflow: hidden;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.color--transparent {
  background-image: linear-gradient(45deg, #dadada 25%, transparent 25%),
    linear-gradient(-45deg, #dadada 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #dadada 75%),
    linear-gradient(-45deg, transparent 75%, #dadada 75%);
  background-size: 8px 8px;
  background-position: 0 0, 0 4px, 4px -4px, -4px 0px;
}

.color--white {
  background-color: #fff;
}
.color--black {
  background-color: #000;
}

.color--default {
  @apply pm-border-2 pm-border-dotted pm-border-gray-200;
}
</style>
