<template>
  <button
    class="tab-button"
    :class="{
      'tab-button--active': active,
      'tab-button--horizontal': horizontal,
    }"
    v-tooltip="{
      content: tooltip,
      delay: {
        show: 1000,
      },
    }"
  >
    <Icon v-if="icon" :name="icon"></Icon>
    <span v-if="label">{{ label }}</span>
  </button>
</template>

<script>
export default {
  props: {
    active: Boolean,
    label: String,
    icon: String,
    horizontal: Boolean,
    tooltip: String,
  },
};
</script>

<style lang="scss">
.tab-button {
  outline: none !important;
  @apply pm-flex pm-w-full pm-text-xs pm-py-2 pm-px-4 pm-items-center pm-relative  pm-text-gray-500;
  @apply hover:pm-bg-gray-100;

  .icon {
    @apply pm-w-4 pm-h-4 pm-text-gray-500;
    + span {
      @apply pm-ml-2;
    }
  }

  &:after {
    content: "";
    @apply pm-bg-gray-200 pm-absolute pm-left-0 pm-top-0 pm-bottom-0 pm-w-px pm-z-10;
  }
}

.tab-button--active {
  @apply pm-text-primary-500;

  .icon {
    @apply pm-text-primary-500;
  }

  &:after {
    @apply pm-w-1 pm-bg-primary-500;
  }
}

.tab-button--horizontal {
  @apply pm-justify-center;
  &:after {
    @apply pm-h-px pm-w-full pm-top-auto pm-right-0;
  }
}

.tab-button--horizontal.tab-button--active {
  &:after {
    @apply pm-h-0.5;
  }
}
</style>
