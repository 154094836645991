<template>
  <Color
    v-bind="$attrs"
    v-tooltip="{ content: label, delay: { show: 1000 } }"
    v-slot="{ value }"
  >
    <Icon
      v-if="active"
      class="pm-w-4 pm-h-4 pm-text-white"
      name="MdiCheckBold"
    />
    <Icon
      v-if="!value"
      class="pm-w-4 pm-h-4 pm-text-gray-500"
      name="MdiSelectColor"
    />
  </Color>
</template>

<script>
import Color from "./Color.vue";

export default {
  components: {
    Color,
  },
  props: {
    label: String,
    active: Boolean,
  },
};
</script>
