<template>
  <div
    class="module-display"
    :class="{ 'module-display--global-used': isUsedAsGlobal }"
  >
    <div
      class="module-display__image"
      @click="isUsedAsGlobal ? null : select()"
    >
      <img v-if="previewImage ?? theme?.image" :src="previewImage ?? theme?.image" alt="" loading="lazy" />
      <p v-else>Module Preview Coming Soon</p>

      <div class="module-display__global-used" v-if="isUsedAsGlobal">
        This module is already added to this page. Global modules can't be added
        more than once in a page.
      </div>
    </div>
    <div
      class="module-display__info"
      :class="{ 'pm-flex pm-justify-between': this.category == 'user-saved' }"
    >
      <p class="module-display__name">{{ alias || name }}</p>
      <PButton
        v-if="this.category == 'user-saved'"
        size="md"
        theme="danger"
        icon="MdiDelete"
        @click.native="del()"
      />
    </div>
  </div>
</template>

<script>
import bus from "../../bus";
import del from "@/gql/module/del.gql";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    id: Number,
    alias: String,
    name: String,
    themes: Array,
    previewImage: String,
    insertIndex: Number,
    category: String,
  },
  inject: ["PAGE_DATA"],
  computed: {
    theme() {
      return this.themes[0];
    },

    ...mapGetters({
      visibleModules: "modules/visibleModules",
    }),

    isUsedAsGlobal() {
      return this.visibleModules
        .filter((module) => module.category == "user-global")
        .map((module) => {
          /**
           * When the module is added freshly and not saved yet,
           * the actual module is inside originalModuleId
           */
          if (module.action == "add") {
            return module.originalModuleId;
          } else {
            return module.id;
          }
        })
        .includes(this.id);
    },
  },
  methods: {
    ...mapActions({
      insertModule: "modules/insert",
    }),

    select() {
      this.insertModule({
        id: this.id,
        order: this.insertIndex,
      });
      bus.$emit("modal-close", "module-list");
    },

    del() {
      bus.$emit("confirm", {
        title: "Delete Saved Module?",
        message: "This action can not be undone.",
        okLabel: "Yes, Delete",
        okTheme: "danger",
        ok: () => {
          this.$apollo
            .mutate(del, {
              id: this.id,
              workspaceId: this.PAGE_DATA.workspaceId,
            })
            .then(() => {
              this.$emit("deleteSuccess");
            })
            .catch((err) => {
              this.$notify({
                message:
                  "There was an error! Please refresh the page & try again.",
                type: "danger",
                duration: 0,
              });
              console.error(err);
            });
        },
      });
    },
  },
};
</script>

<style lang="scss">
.module-display {
  @apply pm-relative pm-bg-white;
}
.module-display__image {
  @apply pm-relative pm-cursor-pointer pm-overflow-hidden pm-rounded-md;
  aspect-ratio: 16/9;
  &:after {
    @apply pm-absolute pm-inset-0 pm-rounded-md;
    content: "";
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  &:hover {
    &:after {
      @apply pm-ring-4 pm-ring-inset pm-ring-primary-100;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  p {
    @apply pm-absolute pm-inset-0 pm-flex pm-items-center pm-justify-center pm-bg-gray-200 pm-text-xs pm-font-medium pm-text-gray-400;
  }
}
.module-display__info {
  @apply pm-mt-2;
}

.module-display__name {
  @apply pm-text-lg pm-font-bold pm-capitalize;
}

.module-display--global-used {
  .module-display__image {
    @apply pm-cursor-not-allowed;
  }
}

.module-display__global-used {
  @apply pm-absolute pm-left-2 pm-bottom-2 pm-right-2 pm-flex pm-cursor-not-allowed pm-items-center pm-justify-center pm-rounded-sm pm-bg-black pm-p-3 pm-text-xxs pm-text-white pm-opacity-80;
}
</style>
