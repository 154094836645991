<template>
  <select
    class="select"
    :class="[
      { 'select--loading': loading },
      { 'select--disabled': disabled },
      `select--${theme}`,
    ]"
    :value="value"
    @input="$emit('input', $event.target.value)"
    :disabled="disabled"
  >
    <option value>{{ placeholder }}</option>
    <option
      v-for="option in localOptions || options"
      :key="option.value"
      :value="option.value"
      :selected="option.value == value"
    >
      {{ option.label }}
    </option>
  </select>
</template>
<script>
export default {
  props: {
    value: [Number, String],
    options: Array,
    request: Function,
    disabled: Boolean,
    placeholder: {
      type: String,
      default: "Select",
    },
    theme: {
      type: String,
      default: "default",
    },
  },

  data() {
    return {
      localOptions: null,
      loading: false,
    };
  },

  async created() {
    if (this.request) {
      this.loading = true;
      this.localOptions = await this.request();
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
.select {
  outline: none !important;
  box-shadow: none;
  background-image: none;
  @apply pm-w-full pm-h-7 pm-text-xs pm-font-medium  pm-appearance-none;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 0px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
}

.select--default {
  @apply pm-border-0 pm-p-0 hover:pm-bg-gray-100 pm-rounded-sm;
  &:hover {
    box-shadow: -4px 0 0 0 theme("colors.gray.100"),
      4px 0 0 0 theme("colors.gray.100");
    background-size: 16px;
  }
}

.select--underline {
  background-size: 16px;
  @apply pm-border-gray-200 pm-border-b pm-border-solid;
  &:hover {
    @apply pm-border-gray-400;
  }
  &:focus {
    @apply pm-border-primary-500;
  }
}

.select--loading {
  @apply pm-bg-gray-200 pm-animate-pulse pm-pointer-events-none;
  font-size: 0;
}

.select--disabled {
  @apply pm-cursor-not-allowed pm-opacity-50;
}
</style>
