<template>
  <div
    id="editor"
    class="editor"
    :class="{
      'editor--loading': isLoading,
      'editor--empty': isEmpty,
    }"
    @click="
      // Always close slideover first to avoid store errors
      $slideover.close();
      setIsSegmentActive(false);
    "
  >
    <LoaderBar v-if="isLoading" class="loading" />
    <Empty v-if="isEmpty" />

    <!-- DO NOT DESTROY THE EDITOR IFRAME ONCE CREATED -->
    <!-- Destroying will create issues with TipTap editor instances -->
    <div
      v-show="!isEmpty"
      class="editor__container"
      :style="{ width: editorWidth }"
    >
      <Actions />
      <Highlighter />
      <TheIframe @rendered="rendered = true" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import bus from "@/bus";
import LoaderBar from "@/components/LoaderBar";
import Empty from "@/components/editor/Empty";
import TheIframe from "@/components/editor/Iframe";
import Highlighter from "./Highlighter";
import Actions from "./Actions";

export default {
  components: {
    LoaderBar,
    Empty,
    TheIframe,
    Highlighter,
    Actions,
  },

  data() {
    return {
      rendered: false,
    };
  },

  mounted() {
    bus.$on("segment-select", (data) => this.setActiveSegment(data));
    bus.$on("segment-content", (content) => {
      this.updateSegment({
        props: { content },
      });
    });
  },

  computed: {
    ...mapGetters({
      isEmpty: "modules/isEmpty",
      activeBreakpoint: "editor/breakpoint",
      loading: "editor/loading",
      modulesLoading: "modules/loading",
      editorWidth: "editor/breakpointWidth",
    }),

    isLoading() {
      return this.loading || this.modulesLoading || !this.rendered;
    },
  },

  methods: {
    ...mapActions({
      updateSegment: "modules/updateSegment",
    }),
    ...mapMutations({
      setActiveSegment: "editor/setActiveSegment",
      setIsSegmentActive: "editor/setIsSegmentActive",
    }),
  },
};
</script>

<style lang="scss">
.editor {
  @apply pm-bg-gray-100;
  grid-row: 2 / 3;
  grid-column: 2 / 3;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
}

.editor__container {
  margin: 0 auto;
  height: 100%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  @apply pm-bg-white;
}

.editor--empty {
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor--loading {
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .loading {
    position: absolute;
    top: 0;
    z-index: 100;
  }
}
</style>
