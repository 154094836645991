<template>
  <span class="icon">
    <component class="pm-w-full pm-h-full" :is="`Icon${name}`" />
  </span>
</template>
<script>
export default {
  props: {
    name: String,
  },
};
</script>

<style lang="scss">
.icon {
  display: inline-block;
}
</style>
