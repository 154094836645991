<template>
  <div class="inspector">
    <TheHeader />

    <!-- No Segment Selected -->
    <Intro v-if="!isSegmentActive" />

    <!-- Breakpoint Not Selected - Full Width Preview -->
    <NoDevice v-else-if="breakpoint == 'full'" />

    <template v-else>
      <Preset />
      <States />
      <GroupEditing />

      <div class="inspector__segments">
        <!-- Segment Specific Editor -->
        <Component v-if="!noEditor.includes(tag)" :is="`${tag}-editor`" />

        <!-- Independet Editors -->
        <Wrapper />
        <Size />
        <Background />
        <BackgroundImage />
        <Gradient />
        <Margin />
        <Padding />
        <Border />
        <Radius />
        <Opacity />
        <BoxShadow />
        <Position />
        <LinkEditor />
        <Attributes />
      </div>
      <Notes />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import bus from "@/bus";

import Notes from "./Notes.vue";
import TheHeader from "./Header";
import States from "./States.vue";
import Intro from "@/components/inspector/Intro";
import NoDevice from "@/components/inspector/NoDevice";
import Preset from "@/components/inspector/preset/Index";
import GroupEditing from "@/components/inspector/GroupEditing";
import PmTextEditor from "@/components/inspector/segment/text/Index";
import PmCodeEditor from "@/components/inspector/segment/Code";
import PmCustomEditor from "@/components/inspector/segment/Custom";
import PmMenuEditor from "@/components/inspector/segment/Menu";
import PmButtonEditor from "@/components/inspector/segment/Button";
import PmGridEditor from "@/components/inspector/segment/Grid";
import PmGridItemEditor from "@/components/inspector/segment/GridItem";
import PmMediaEditor from "@/components/inspector/segment/Media";
import PmMediaGroupEditor from "@/components/inspector/segment/Media";
import PmRowEditor from "@/components/inspector/segment/Row";
import PmCountdownEditor from "@/components/inspector/segment/Countdown";
import PmGalleryEditor from "@/components/inspector/segment/Gallery";
import PmRatingEditor from "@/components/inspector/segment/Rating";
import PmRepeaterEditor from "@/components/inspector/segment/Repeater";
import PmContainerEditor from "@/components/inspector/segment/Container";
import PmFormEditor from "@/components/inspector/segment/form/Index";
import PmIconEditor from "@/components/inspector/segment/Icon";
import PmModalEditor from "@/components/inspector/segment/Modal";
import PmAccordionEditor from "@/components/inspector/segment/Accordion";
import PmCarouselEditor from "@/components/inspector/segment/Carousel";
import PmShopifyProductEditor from "@/components/inspector/segment/ShopifyProduct";
import PmNumberInputEditor from "@/components/inspector/segment/NumberInput";
import PmShopifyProductAttributeEditor from "@/components/inspector/segment/ShopifyProductAttribute";

import Wrapper from "@/components/inspector/segment/Wrapper";
import BackgroundImage from "@/components/inspector/common/BackgroundImage";
import Border from "@/components/inspector/common/Border";
import Gradient from "@/components/inspector/common/Gradient";
import Radius from "@/components/inspector/common/Radius";
import Margin from "@/components/inspector/common/Margin";
import Padding from "@/components/inspector/common/Padding";
import Attributes from "@/components/inspector/common/Attributes";
import Background from "@/components/inspector/common/Background";
import LinkEditor from "@/components/inspector/common/Link";
import BoxShadow from "@/components/inspector/common/Shadow";
import Size from "@/components/inspector/common/Size";
import Opacity from "@/components/inspector/common/Opacity";
import Position from "@/components/inspector/common/Position";

export default {
  components: {
    TheHeader,
    Intro,
    NoDevice,
    GroupEditing,
    Preset,
    States,
    Notes,

    /**
     * Segment Sepcific Editors
     * The component name should match with the segment name
     * i.e. pm-media => pm-media-editor
     */
    PmTextEditor,
    PmCodeEditor,
    PmCustomEditor,
    PmMenuEditor,
    PmButtonEditor,
    PmGridEditor,
    PmGridItemEditor,
    PmMediaEditor,
    PmMediaGroupEditor,
    PmRowEditor,
    PmCountdownEditor,
    PmGalleryEditor,
    PmRatingEditor,
    PmRepeaterEditor,
    PmContainerEditor,
    PmFormEditor,
    PmIconEditor,
    PmModalEditor,
    PmAccordionEditor,
    PmCarouselEditor,
    PmNumberInputEditor,
    PmShopifyProductEditor,
    PmShopifyProductAttributeEditor,
    /**
     * Common Editors
     * Available for all the segments
     */
    Wrapper,
    BackgroundImage,
    Border,
    Gradient,
    Radius,
    Margin,
    Padding,
    Attributes,
    Background,
    LinkEditor,
    BoxShadow,
    Size,
    Opacity,
    Position,
  },

  data() {
    return {
      tab: "inspector",
      //This segments do not have dedicated editing panel
      noEditor: [
        "pm-wrapper",
        "pm-alert",
        "pm-select",
        "pm-button-group",
        "pm-carousel-items",
        "pm-carousel-item",
        "pm-carousel-prev",
        "pm-carousel-next",
        "pm-shopify-product-variant",
      ],
    };
  },

  computed: {
    ...mapGetters({
      breakpoint: "editor/breakpoint",
      tag: "editor/activeSegmentTag",
      isSegmentActive: "editor/isSegmentActive",
    }),
  },
};
</script>

<style lang="scss">
.inspector {
  grid-row: 1 / 3;
  grid-column: 3 / 4;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  @apply pm-border-l pm-border-gray-200  pm-antialiased;
}
.inspector__tabs {
  .tab__list {
    height: var(--pmHeaderSize);
  }
}

.inspector__segments {
  @apply pm-divide-y pm-divide-gray-200;
}
</style>
