<template>
  <IAccordion title="Gradient" prop-group="gradient">
    <div class="pm-space-y-3">
      <IPositionButton
        label="Direction"
        prop="bgGradientTo"
      />

      <IColor
        label="Start"
        prop="bgFrom"
        :side-effect="(props) => colorSideEffect(props)"
      />
      <IColor
        label="Via"
        prop="bgVia"
        :side-effect="(props) => colorSideEffect(props)"
      />
      <IColor
        label="Stop"
        prop="bgTo"
        :side-effect="(props) => colorSideEffect(props)"
      />

      <ISwitch
        label="Apply To Text"
        prop="bgClip"
        :value-output="(value) => (value == true ? 'text' : undefined)"
        :value-input="(value) => (value == 'text' ? true : false)"
      />
    </div>
  </IAccordion>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  bgGradientTo: [
    {
      value: "tl",
      label: "Left-Top",
      icon: "MdiArrowTopLeft",
    },
    {
      value: "t",
      label: "Top",
      icon: "MdiArrowUp",
    },
    {
      value: "tr",
      label: "Right-Top",
      icon: "MdiArrowTopRight",
    },
    {
      value: "l",
      label: "Left",
      icon: "MdiArrowLeft",
    },
    {
      value: "c",
      label: "Center",
      icon: "MdiCircleMedium",
    },
    {
      value: "r",
      label: "Right",
      icon: "MdiArrowRight",
    },
    {
      value: "bl",
      label: "Left-Bottom",
      icon: "MdiArrowBottomLeft",
    },
    {
      value: "b",
      label: "Bottom",
      icon: "MdiArrowDown",
    },
    {
      value: "br",
      label: "Right-Bottom",
      icon: "MdiArrowBottomRight",
    },
  ],

  computed: {
    ...mapGetters({
      propValueFinder: "editor/activeSegmentPropValue",
    }),
  },

  methods: {
    colorSideEffect() {
      if (!this.propValueFinder("bgGradientTo")) {
        return {
          props: {
            bgGradientTo: "r",
          },
        };
      }
    },
  },
};
</script>
