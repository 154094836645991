<template>
    <div
        class="pm-h-32 space pm-relative pm-border-gray-200 group"
        :class="{ 'x-lock': xLocked, 'y-lock': yLocked, 'all-lock': allLocked}"
    >
        <div class="space-section space-top pm-absolute pm-justify-center pm-items-center pm-bg-white pm-h-[25%] pm-w-full pm-top-0">
            <slot name="top"></slot>
        </div>
        <div class="space-section space-right pm-absolute pm-justify-center pm-items-center pm-bg-white pm-w-[calc(25%)] pm-h-full pm-right-0">
            <slot name="right"></slot>
        </div>
        <div class="space-section space-bottom pm-absolute pm-justify-center pm-items-center pm-bg-white pm-h-[25%] pm-w-full pm-bottom-0">
            <slot name="bottom"></slot>
        </div>
        <div class="space-section space-left pm-absolute pm-justify-center pm-items-center pm-bg-white pm-w-[calc(25%)] pm-h-full pm-left-0">
            <slot name="left"></slot>
        </div>
        <div class="content pm-w-full pm-h-full pm-flex pm-justify-center pm-items-center">
            <span class="pm-relative pm-w-1/2 pm-h-1/2 pm-border pm-flex pm-justify-center pm-items-center pm-bg-white pm-border-gray-200">
                <slot></slot>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        xLocked: {
            type: Boolean,
            default: false
        },
        yLocked: {
            type: Boolean,
            default: false
        },
        allLocked: {
            type: Boolean,
            default: false
        }
    },
    methods: {

    },
};
</script>

<style>


/**
* to shape trapzium
*/

.space-section .v-popover,
.space-section .trigger {
    @apply pm-w-full pm-h-full;
}


.space .space-top {
    clip-path: polygon(0px 0, 100% 0, 75% 100%, 25% 100%);
}

.space .space-right {
    clip-path: polygon(100% 0px, 100% 100%, 0 75%, 0 25%, 100% 0px);
}

.space .space-bottom {
    clip-path: polygon(100% 100%, 0px 100%, 25% 0, 75% 0);
}

.space .space-left {
    clip-path: polygon(0 0px, 100% 25%, 100% 75%, 0 100%, 0 0px);
}
</style>