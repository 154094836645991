<template>
  <div v-if="module">
    <TheSection open label="Actions">
      <div class="pm-divide-y pm-border-gray-200">
        <TheButton
          label="Move Up"
          icon="MdiArrowUp"
          :disabled="!isSortable || order == 0"
          @click.native="sort({ currentOrder: order, newOrder: order - 1 })"
        />
        <TheButton
          label="Move Down"
          icon="MdiArrowDown"
          :disabled="!isSortable || order == visibleModulesCount - 1"
          @click.native="sort({ currentOrder: order, newOrder: order + 1 })"
        />
        <TheButton
          label="Reset"
          icon="MdiRestore"
          theme="warning"
          @click.native="reset()"
        />
        <TheButton
          :disabled="!isRemovable"
          label="Remove"
          icon="MdiCloseCircle"
          theme="danger"
          @click.native="remove()"
        />
      </div>
    </TheSection>

    <SaveCopy :module-id="module.id" />
    <SaveGlobal
      :module-id="module.id"
      :name="module.alias"
      :category="module.category"
    />

    <TheSection open label="About">
      <ul class="module-attrs">
        <li>
          <label>Name</label>
          <p class="pm-capitalize">{{ module.name }}</p>
        </li>
        <li>
          <label>Version</label>
          <p>{{ module.version || "Not Available" }}</p>
        </li>
        <li>
          <label>Tags</label>
          <p>{{ module.tags.join(", ") }}</p>
        </li>
      </ul>
    </TheSection>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import bus from "@/bus";
import SaveCopy from "./SaveCopy";
import SaveGlobal from "./SaveGlobal";
import TheSection from "../slideover/Section.vue";
import TheButton from "../slideover/Button.vue";

export default {
  props: {
    uuid: String,
  },
  components: {
    SaveCopy,
    SaveGlobal,
    TheSection,
    TheButton,
  },
  computed: {
    ...mapGetters({
      getByUuid: "modules/getByUuid",
      moduleOrder: "modules/order",
      visibleModulesCount: "modules/visibleModulesCount",
    }),

    order() {
      return this.moduleOrder(this.uuid);
    },

    module() {
      return this.getByUuid(this.uuid);
    },

    isRemovable() {
      return this.module?.name != "promo-modal";
    },

    isSortable() {
      return this.module?.name != "promo-modal";
    },
  },
  methods: {
    ...mapActions({
      sort: "modules/sort",
      removeModule: "modules/remove",
    }),
    ...mapMutations({
      setIsSegmentActive: "editor/setIsSegmentActive",
    }),

    remove() {
      bus.$emit("confirm", {
        title: "Remove Module?",
        message: "This action can not be undone.",
        okLabel: "Yes, Remove",
        okTheme: "danger",
        ok: () => {
          this.removeModule(this.uuid);
          this.$slideover.close();
          this.setIsSegmentActive(false);
        },
      });
    },

    reset() {
      bus.$emit("confirm", {
        title: "Reset Module?",
        message:
          "All the changes you made will be lost and the module will be reset to default design.",
        okLabel: "Yes, Reset",
        okTheme: "warning",
        ok: () => {
          bus.$emit("module-reset", this.uuid);
        },
      });
    },
  },
};
</script>

<style lang="scss">
.module-attrs {
  @apply pm-space-y-3 pm-p-3;
  label {
    @apply pm-block pm-text-xxs pm-leading-none pm-text-gray-500;
  }
  p {
    @apply pm-mt-1  pm-text-xs pm-leading-none;
  }
}
</style>
