<template>
  <div>
    <MediaManager :value="value" :title="activeIndex == null ? 'Select Media' : `Select Media ${activeIndex + 1}`
      " :source="activeSegmentProps.source" @select="input($event)" />

    <IuiField :label="`Media List — ${value?.length || 0}`" :disabled="isDisabled" :responsive="hasResponsive"
      v-if="PAGE_DATA.type != 'shopify_page'">
      <IuiMediaGroup :value="variableValue" @open="open" @remove="remove" />
    </IuiField>
  </div>

</template>

<script>
import inspectorInput from "@/mixins/inspector-input";
import { mapGetters } from "vuex";
import MediaManager from "@/components/media-manager/Index";
import { IuiMediaGroup, IuiField } from "inspector-ui"

export default {
  inheritAttrs: false,
  inject: ["PAGE_DATA"],
  mixins: [inspectorInput],
  components: {
    MediaManager,
    IuiMediaGroup,
    IuiField
  },
  data() {
    return {
      activeIndex: null,
    };
  },
  computed: {
    ...mapGetters({
      activeSegmentProps: "editor/activeSegmentProps",
    }),
    variableValue() {
      return this.value?.map((item) => this.replaceVariableValue(item))
    }
  },
  methods: {
    input(data) {
      const newValue = [...(this.value || [])];
      this.$set(newValue, this.activeIndex, data.value);
      this.update(this.prop, newValue);
    },
    remove(index) {
      const newValue = [...(this.value || [])];
      this.$delete(newValue, index);
      this.update(this.prop, newValue);
    },
    open(index) {
      if (index == null) {
        this.activeIndex = this.value?.length || 0;
      } else {
        this.activeIndex = index;
      }
      this.$slideover.open("media-manager");
    },
  },
};
</script>
