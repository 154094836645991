<template>
  <button
    class="pm-w-4 pm-flex pm-items-center pm-rounded-full pm-bg-white pm-py-1 pm-relative pm-z-10 pm-text-gray-500 pm-outline-none"
    :class="{ 'pm-text-primary-500': value, 'pm-text-gray-300 pm-cursor-not-allowed': disabled }"
    @click="$emit('click')"
  >
    <Icon :name="value ? 'MdiLock' : 'MdiLockOpenVariant'" />
  </button>
</template>

<script>

export default {
  props: {
    value: {
      type: [Boolean, Number, String],
      default: false
    },
    disabled: Boolean,
  },
};
</script>
