<template>
  <div class="switch pm-inline-block pm-align-top" :class="{ 'switch--active': value }" @click="$emit('click', value)">
    <div class="pm-relative pm-flex pm-w-7 pm-h-4 pm-rounded-full pm-border-0 pm-border-gray-100 pm-bg-gray-200 pm-cursor-pointer pm-transition-all pm-duration-300" :class="{'pm-bg-primary-500': value}">
      <div class="pm-absolute pm-w-2 pm-h-2 pm-top-1 pm-rounded-full pm-bg-gray-500 pm-transition-all pm-duration-300" :class="{ 'pm-left-1': !value, 'pm-bg-primary-100 pm-right-1': value }"></div>
    </div>
  </div>
</template>

<script>


export default {
  components: {

  },
  props: {
    value: Boolean,
  },
};
</script>
