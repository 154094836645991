<template>
  <div class="ia" :class="classList">
    <!-- Toggler -->
    <button class="ia__toggle" @click="toggle()" :disabled="locked">
      <slot name="toggle">
        <span class="ia__label">{{ title }}</span>
      </slot>
      <Icon v-if="icon" class="ia__icon" :name="icon" />
    </button>

    <!-- Content -->
    <div class="ia__content" v-if="isOpen">
      <p v-if="desc" class="ia__desc">{{ desc }}</p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import segmentConfig from "@/config/segments";

export default {
  props: {
    title: String,
    desc: String,
    open: Boolean,
    propGroup: String,
  },
  data() {
    return {
      isOpen: this.open,
    };
  },

  computed: {
    ...mapGetters({
      tag: "editor/activeSegmentTag",
      props: "editor/activeSegmentProps",
    }),

    localLockedGroups() {
      return this.props?.lockedGroups || [];
    },

    lockedGroups() {
      return segmentConfig[this.tag]?.lockedGroups || [];
    },

    locked() {
      return (
        this.localLockedGroups.includes(this.propGroup) ||
        this.lockedGroups.includes(this.propGroup)
      );
    },

    classList() {
      return {
        "ia--active": this.isOpen,
        "ia--locked": this.locked,
      };
    },

    icon() {
      if (this.locked) {
        return;
      } else if (this.isOpen) {
        return "MdiChevronUp";
      } else {
        return "MdiChevronDown";
      }
    },
  },

  methods: {
    toggle() {
      if (this.locked) return;
      this.isOpen = !this.isOpen;
    },
    doOpen() {
      if (this.locked) return;
      this.isOpen = true;
    },
  },
};
</script>

<style lang="scss">
.ia__desc {
  font-size: 11px;
  color: --color(grey);
  margin-bottom: --space(2);
}

.ia__toggle {
  @apply pm-py-2 pm-px-3 hover:pm-bg-gray-50;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none !important;
}
.ia__label {
  @apply pm-text-xxs pm-font-medium;
  flex: 1 1 auto;
  text-align: left;
}
.ia__icon {
  @apply pm-text-gray-400;
}

.ia--locked {
  @apply pm-opacity-50;
  .ia__toggle {
    @apply pm-cursor-not-allowed;
  }
}
.ia__content {
  @apply pm-px-3 pm-pb-3 pm-pt-2;
}
.ia--active {
  .ia__label {
    @apply pm-text-primary-500;
  }
}
</style>
