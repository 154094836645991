<template>
  <div
    v-show="uuid"
    class="actions"
    :class="{
      'actions--first': order == 0,
      'actions--last': order == visibleModules.length - 1,
    }"
  >
    <div class="actions__top" :style="topStyle">
      <TheButton
        title="Add Block Before This"
        icon="MdiPlus"
        @click.native="add(order)"
      />
    </div>

    <div class="actions__bottom" :style="bottomStyle">
      <TheButton
        title="Add Module After This"
        icon="MdiPlus"
        @click.native="add(order + 1)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import bus from "@/bus";
import TheButton from "./Button";

export default {
  components: {
    TheButton,
  },

  data() {
    return {
      uuid: null,
      top: 0,
      height: 0,
    };
  },

  mounted() {
    bus.$on("module-hover", (uuid) => {
      this.onModuleHover(uuid);
    });

    bus.$on("editor-scroll", () => {
      if (this.uuid && this.el) {
        this.top = this.el.getBoundingClientRect().top;
      }
    });
  },

  computed: {
    ...mapGetters({
      visibleModules: "modules/visibleModules",
      moduleOrder: "modules/order",
    }),

    el() {
      return document
        .querySelector("#editor-iframe")
        .contentDocument.querySelector(`div[data-uuid="${this.uuid}"]`);
    },

    isRemovable() {
      return this.name != "promo-modal";
    },

    isSortable() {
      return this.name != "promo-modal";
    },

    topStyle() {
      return {
        transform: `translateY(${this.top}px)`,
      };
    },

    bottomStyle() {
      return {
        transform: `translateY(${this.top + this.height}px)`,
      };
    },

    order() {
      return this.moduleOrder(this.uuid);
    },
  },

  methods: {
    ...mapMutations({
      setActiveSegment: "editor/setActiveSegment",
    }),

    add(order) {
      bus.$emit("modal-open", "module-type-selector", order);
      bus.$emit("module-hover");
      this.setActiveSegment();
    },

    /**
     * When mouse leaves the module, we need to check if the mouse is over the action pane, i.e. Add Module
     * If that is the case, we need to keep showing actions.
     * Hence after the mouseleave, we check if the current hovered element is action.
     * If that is the case, do not remove actions.
     */
    onModuleHover(uuid) {
      // querySelectorAll returns the tree of the hovered element
      const targets = document.querySelectorAll(":hover");

      //The last element in array is actual hovered element
      const el = targets[targets.length - 1];

      if (!el) return;

      // If the element is action button, return;
      if (el.dataset.hasOwnProperty("action")) return;

      if (uuid) {
        this.uuid = uuid;
        const { top, height } = this.el.getBoundingClientRect();
        this.top = top;
        this.height = height;
      } else {
        this.uuid = null;
        this.top = 0;
        this.left = 0;
        this.height = 0;
      }
    },
  },
};
</script>

<style lang="scss">
.actions {
  @apply pm-relative pm-z-20;
}
.actions__top,
.actions__bottom {
  @apply pm-absolute;
  background-color: var(--pmHighlight);
  transform: translate(-100px, -100px);
  top: -16px;
  left: 0;
  right: 0;
  margin: auto;
}
.actions__top {
  width: 32px;
}
.actions__bottom {
  width: 32px;
}

.actions--first {
  .actions__top {
    top: 0;
  }
}
.actions--last {
  .actions__bottom {
    top: -32px;
  }
}
</style>
