<template>
  <div class="i-field" :class="{ 'pm-flex pm-justify-between pm-items-center': inline, 'i-field--disabled': disabled }">
    <label v-if="label" class="pm-text-xxs pm-text-gray-500" data-content="⊦"
      :class="{ 'after:pm-content-[attr(data-content)] after:pm-text-danger-500 after:pm-font-bold': !responsive }">
      {{ label }}
    </label>

    <div v-if="disabled" class="pm-text-gray-500 pm-h-7 pm-flex pm-items-center">
      <Icon class="pm-w-4 pm-h-4" name="MdiLock" />
    </div>
    <slot v-else />
    <p class="pm-text-xxs pm-text-danger-500 pm-mt-1" v-if="errors && errors[0]">{{ errors[0] }}</p>
    <p v-if="desc" class="pm-text-xxs pm-text-gray-500 pm-mt-1">{{ desc }}</p>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    desc: String,
    errors: Array,
    inline: Boolean,
    disabled: Boolean,
    responsive: Boolean,
  },
};
</script>
