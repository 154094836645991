import list from "@/gql/integration/list.gql";
import { $apollo } from "@/plugins/apollo";

export default (types, workspaceId) => {
  return $apollo
    .query(list, {
      workspaceId: workspaceId,
      perPage: -1,
    })
    .then((res) => res.data.integrations.data || [])
    .then((res) => {
      if (types) {
        return res.filter((item) => types.includes(item.type));
      } else {
        return res;
      }
    })
    .then((res) => {
      return res.map((item) => {
        return {
          label: `[${item.provider}] ${item.name}`,
          value: item.id,
        };
      });
    });
};
