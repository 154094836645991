<template>
  <textarea
    class="pm-w-full pm-h-24 pm-font-medium pm-text-xs !pm-outline-none pm-shadow-none"
    :class="theme && `textarea--${theme}`"
    :value="value"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    value: String,
    theme: {
      type: String,
      default: "default",
    },
  },
};
</script>

<style lang="scss">

.textarea--default {
  @apply pm-border-0 pm-p-0 pm-rounded-sm;
  &:hover,
  &:focus {
    @apply pm-bg-gray-100;
    box-shadow: -4px 0 0 0 theme("colors.gray.100"),
      4px 0 0 0 theme("colors.gray.100");
  }
}

.textarea--underline {
  @apply pm-border-gray-200 pm-border-b pm-border-solid;
  &:hover {
    @apply pm-border-gray-400;
  }
  &:focus {
    @apply pm-border-primary-500;
  }
}
</style>
