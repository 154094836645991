<template>
  <div class="pm-flex pm-relative pm-divide-x pm-border pm-rounded-sm pm-overflow-hidden" :class="{'pm-w-fit': !fullWidth}">
      <button v-for="option in options" :key="option.value" class="pm-h-5 pm-px-3 pm-font-medium pm-text-xxs pm-flex-grow pm-flex pm-items-center pm-justify-center pm-text-gray-400 hover:pm-text-gray-500 !pm-outline-none"
          :class="{ 'pm-text-primary-500 pm-bg-gray-100 hover:pm-text-primary-500': option.value == value }" @click="$emit('input', option.value)">
          {{ option.label }}
      </button>
  </div>
</template>

<script>

export default {
  props: {
    options: {
          type: Array,
          required: true,
      },
      value: {
          type: String,
          required: true,
      },
      fullWidth: {
          type: Boolean,
          default: false,
      },
  }
};
</script>
