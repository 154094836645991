<template>
  <!-- data-action is used to detect if the module hover out event is going to action button  -->
  <button class="action-button" data-action type="button">
    <Icon class="action-button__icon" :name="icon"></Icon>
  </button>
</template>

<script>
export default {
  props: {
    icon: String,
  },
};
</script>

<style lang="scss">
.action-button {
  color: var(--pmHighlightDarkest);
  outline: none !important;
  @apply pm-inline-flex pm-justify-center pm-items-center pm-select-none pm-cursor-pointer pm-w-8 pm-h-8;
  &:disabled {
    cursor: not-allowed;
    color: var(--pmHighlightDark);
  }
}

.action-button__icon {
  @apply pm-w-4 pm-h-4;
  // Pointer events none prevents the icon being hovered and keeps the button as target
  // This is required when the action buttons are being used to detect the hover out event of module
  pointer-events: none;
}
</style>
