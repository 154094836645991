<template>
  <div class="pm-relative">
    <Layer
      v-for="(layer, index) in children"
      :key="`layer--${depth}--${index}`"
      :layer="layer"
      :is-active="isActive(layer)"
      :module-uuid="moduleUuid"
    >
      <Layers
        class="pm-ml-2 pm-border-l"
        :children="layer.children"
        :depth="depth + 1"
        :module-uuid="moduleUuid"
      />
    </Layer>
  </div>
</template>

<script>
import bus from "@/bus";
import { mapMutations, mapState } from "vuex";
import segmentConfig from "@/config/segments";
import Layer from "./Layer.vue";

export default {
  props: {
    children: {
      type: Array,
    },
    depth: {
      type: Number,
      default: 0,
    },
    moduleUuid: {
      type: String,
    },
  },

  components: {
    Layer,
  },

  computed: {
    ...mapState({
      activeSegmentName: (state) => state.editor.activeSegmentName,
      activeSegmentModuleUuid: (state) => state.editor.activeSegmentModuleUuid,
      activeSegmentIndex: (state) => state.editor.activeSegmentIndex,
    }),
  },

  methods: {
    ...mapMutations({
      setActiveSegment: "editor/setActiveSegment",
    }),

    hover(layer) {
      bus.$emit("segment-hover", {
        moduleUuid: this.moduleUuid,
        segmentName: layer.name,
        segmentIndex: layer.refIndex,
      });
    },

    hoverOut() {
      bus.$emit("segment-hover");
    },

    toggle(index) {
      if (this.open.includes(index)) {
        const elIndex = this.open.indexOf(index);
        this.$delete(this.open, elIndex);
      } else {
        this.open.push(index);
      }
    },

    select(layer) {
      const payload = {
        moduleUuid: this.moduleUuid,
        segmentName: layer.name,
        segmentIndex: layer.refIndex,
      };
      this.setActiveSegment(payload);
      this.$nextTick(() => {
        bus.$emit("segment-scroll", payload);
      });
    },

    isActive(layer) {
      if (
        this.activeSegmentModuleUuid == this.moduleUuid &&
        layer.name == this.activeSegmentName
      ) {
        if (this.activeSegmentIndex == null) {
          return true;
        }
        return layer.refIndex == this.activeSegmentIndex;
      }
      return false;
    },

    icon(layer) {
      return segmentConfig[layer.tag]?.icon;
    },
  },
};
</script>
