import Vue from "vue";
import Router from "vue-router";
import Editor from "@/views/Editor";
Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      name: "editor",
      component: Editor,
    },
  ],
});
export default router;
