import { version } from "@/../package.json";
import gql from "graphql-tag";

import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client/core";

import { createUploadLink } from "apollo-upload-client";

var apollo;
var apolloUpload;

const $apollo = {
  query: (query, variables) => {
    return apollo.query({
      query: gql`
        ${query}
      `,
      variables,
    });
  },
  mutate: (mutation, variables) => {
    return apollo.mutate({
      mutation: gql`
        ${mutation}
      `,
      variables,
    });
  },
};

const init = ({ token, baseUrl }) => {
  token = `Bearer ${token}`;

  const httpLinkConfig = {
    uri: baseUrl,
    headers: {
      Authorization: token,
      "web-version": version,
    },
  };

  const defaultOptions = {
    cache: new InMemoryCache(),
    name: "pagemaker-web",
    version: version,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
      },
      query: {
        fetchPolicy: "no-cache",
      },
    },
  };

  apollo = new ApolloClient({
    link: createHttpLink(httpLinkConfig),
    ...defaultOptions,
  });

  apolloUpload = new ApolloClient({
    link: createUploadLink(httpLinkConfig),
    ...defaultOptions,
  });
};

const httpPublicLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_OPEN,
});

const apolloPublicClient = new ApolloClient({
  link: httpPublicLink,
  cache: new InMemoryCache(),
});

const $apolloUpload = {
  mutate: (mutation, variables) => {
    return apolloUpload.mutate({
      mutation: gql`
        ${mutation}
      `,
      variables,
    });
  },
};

export { init, apollo, $apollo, apolloPublicClient, $apolloUpload };
