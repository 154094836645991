<template>
  <button
  class="radius"
    :class="{ 
      'active': value,
      'pm-text-gray-300 pm-cursor-not-allowed': disabled,
      [orientation]: orientation
    }"
    @click="$emit('click')"
  ></button>
</template>

<script>

export default {
  props: {
    value: {
      type: [Boolean, Number, String],
      default: false
    },
    disabled: Boolean,
    orientation: {
      type: String,
      default: 'all'
    }
  },
};
</script>

<style>

.radius.active:before {
  @apply !pm-bg-primary-300;
}

.radius:before {
  @apply pm-absolute pm-inline-block pm-bg-gray-300;
}

button.top {
  @apply pm-absolute pm-w-full pm-h-2 pm-p-1 pm-top-0
}

button.top:hover::before {
  @apply pm-bg-gray-500
}

.top:before {
  content: "";
  width: calc(100% - 28px);
  @apply pm-absolute pm-top-1/2 pm-left-1/2 pm-h-[2px] -pm-translate-y-1/2 -pm-translate-x-1/2 pm-rounded-full;
}

button.bottom {
  @apply pm-absolute pm-w-full pm-h-2 pm-p-1 pm-bottom-0
}

button.bottom:hover::before {
  @apply pm-bg-gray-500
}

.bottom:before {
  content: "";
  width: calc(100% - 28px);
  @apply pm-absolute pm-top-1/2 pm-left-1/2 pm-h-[2px] -pm-translate-y-1/2 -pm-translate-x-1/2 pm-rounded-full;
}

button.left {
  @apply pm-absolute pm-h-full pm-w-2 pm-p-1 pm-left-0
}

button.left:hover::before {
  @apply pm-bg-gray-500
}

.left:before {
  content: "";
  height: calc(100% - 28px);
  @apply pm-absolute pm-top-1/2 pm-left-1/2 pm-w-[2px] -pm-translate-y-1/2 -pm-translate-x-1/2 pm-rounded-full;
}

button.right {
  @apply pm-absolute pm-h-full pm-w-2 pm-p-1 pm-right-0
}

button.right:hover::before {
  @apply pm-bg-gray-500
}

.right:before {
  content: "";
  height: calc(100% - 28px);
  @apply pm-absolute pm-top-1/2 pm-left-1/2 pm-w-[2px] -pm-translate-y-1/2 -pm-translate-x-1/2 pm-rounded-full;
}

button.radius.all {
  @apply pm-absolute pm-w-3 pm-h-3 pm-top-1/2 pm-left-1/2 -pm-translate-x-1/2 -pm-translate-y-1/2;
}
button.radius.all:hover::before {
  @apply pm-bg-gray-500
}

.radius.all:before {
  content: "";
  @apply pm-absolute pm-w-1 pm-h-1 pm-rounded-full pm-bg-gray-300 -pm-translate-y-1/2 -pm-translate-x-1/2;
}
</style>