import Vue from "vue";
import IAccordion from "@/components/inspector/Accordion";

import ISelect from "@/components/inspector/inputs/Select";
import IButtonIconGroup from "@/components/inspector/inputs/ButtonGroup";
import IPositionButton from "@/components/inspector/inputs/PositionButton";
import { IuiButton, IuiButtonIcon, IuiField } from "inspector-ui"
import IColor from "@/components/inspector/inputs/Color";
import ISlider from "@/components/inspector/inputs/Slider";
import ISpaceInput from "@/components/inspector/inputs/SpaceInput";
import IBorderInput from "@/components/inspector/inputs/BorderInput";
import IRadiusInput from "@/components/inspector/inputs/RadiusInput";
import ITextbox from "@/components/inspector/inputs/Textbox";
import ITextarea from "@/components/inspector/inputs/Textarea";
import ISwitch from "@/components/inspector/inputs/Switch";
import IMedia from "@/components/inspector/inputs/Media";
import INumber from "@/components/inspector/inputs/Number";
import IMediaGroup from "@/components/inspector/inputs/MediaGroup";
import ICode from "@/components/inspector/inputs/Code";

Vue.component("IAccordion", IAccordion);

Vue.component("IField", IuiField);
Vue.component("ISelect", ISelect);
Vue.component("IButtonIcon", IuiButtonIcon);
Vue.component("IButton", IuiButton);
// Vue.component("ButtonGroup", ButtonGroup);
Vue.component("IButtonIconGroup", IButtonIconGroup);
Vue.component("IPositionButton", IPositionButton);
Vue.component("IColor", IColor);
Vue.component("ISlider", ISlider);
Vue.component("ISpaceInput", ISpaceInput);
Vue.component("IBorderInput", IBorderInput);
Vue.component("IRadiusInput", IRadiusInput);

Vue.component("ITextbox", ITextbox);
Vue.component("ITextarea", ITextarea);
Vue.component("ISwitch", ISwitch);
Vue.component("IMedia", IMedia);
Vue.component("INumber", INumber);
Vue.component("IMediaGroup", IMediaGroup);
Vue.component("ICode", ICode);
