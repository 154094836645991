<template>
    <div class="pm-mt-1 pm-grid pm-gap-1 pm-grid-cols-[repeat(3,min-content)]">
      <IuiButtonIcon
        v-for="option in options"
        :key="`button-${option.value}`"
        :icon="option.icon"
        :active="value == option.value"
        :title="option.label"
        @click.native="$emit('click', option)"
      />
    </div>

</template>

<script>

import IuiButtonIcon from "./ButtonIcon.vue";
import IuiButton from "./Button.vue";

const options = [
  {
    value: "left-top",
    label: "Left-Top",
    icon: "MdiArrowTopLeft",
  },
  {
    value: "top",
    label: "Top",
    icon: "MdiArrowUp",
  },
  {
    value: "right-top",
    label: "Right-Top",
    icon: "MdiArrowTopRight",
  },
  {
    value: "left",
    label: "Left",
    icon: "MdiArrowLeft",
  },
  {
    value: "center",
    label: "Center",
    icon: "MdiCircleMedium",
  },
  {
    value: "right",
    label: "Right",
    icon: "MdiArrowRight",
  },
  {
    value: "left-bottom",
    label: "Left-Bottom",
    icon: "MdiArrowBottomLeft",
  },
  {
    value: "bottom",
    label: "Bottom",
    icon: "MdiArrowDown",
  },
  {
    value: "right-bottom",
    label: "Right-Bottom",
    icon: "MdiArrowBottomRight",
  },
];

export default {
  components: {
    IuiButtonIcon,
    IuiButton
  },
  data() {
    return {
      options,
    };
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
  },
};
</script>
