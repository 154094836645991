<template>
  <div
    v-if="!supported"
    class="
      pm-fixed
      pm-inset-0
      pm-bg-black
      pm-bg-opacity-80
      pm-backdrop-filter
      pm-backdrop-blur-sm
      pm-z-50
      pm-flex
      pm-justify-center
      pm-items-center
    "
  >
    <div class="pm-bg-white pm-p-8 pm-rounded-md pm-max-w-sm">
      <h5 class="pm-text-lg pm-font-bold">Unsupported Browser.</h5>
      <div class="pm-text-sm pm-mt-2 pm-space-y-2">
        <p>
          Looks like you're using a browser we don't support yet. To have a
          better experience in Pagemaker Editor we recommend using one of the
          following browsers.
        </p>
        <ul class="pm-font-medium pm-list-disc pm-list-inside pm-pl-4">
          <li>Google Chrome</li>
          <li>Microsoft Edge</li>
          <li>Brave</li>
        </ul>
        <p>We look to add support for more browser in future updates.</p>
      </div>
      <PButton
        class="pm-mt-4"
        size="md"
        label="Exit Editor"
        theme="primary"
        @click.native="exit()"
      />
    </div>
  </div>
</template>

<script>
import { detect } from "detect-browser";
import bus from "@/bus";

export default {
  data() {
    return {
      supported: true,
    };
  },
  created() {
    this.supported = this.isBrowserSupported();
  },
  methods: {
    exit() {
      bus.$emit("exit", true);
    },
    isBrowserSupported() {
      const { name } = detect();
      return ["chrome", "edge-chromium"].includes(name);
    },
  },
};
</script>
