<template>
  <VPopover :ref="prop" placement="top-start" @apply-hide="$emit('hide')" @apply-show="$emit('show')">
    <template #default>
      <div
        class="pm-text-xs pm-text-gray-500 pm-font-bold pm-flex pm-items-center pm-justify-center pm-h-full pm-cursor-pointer"
        :class="{ '!pm-text-gray-400': typeof display === 'undefined' }" v-bind="$attrs"><span>{{ display ?? '–'
          }}</span></div>
    </template>
    
    <template #popover="data">
      <div class="pm-absolute pm-flex pm-flex-col pm-w-52 pm-rounded-md pm-overflow-hidden pm-bg-white pm-shadow-md"
        :class="[name]">
        <div class="pm-text-black pm-border-b pm-px-3 pm-h-8 pm-flex pm-items-center">
          <span class="pm-grow pm-text-xxs">{{ heading }}</span>
          <button v-close-popover class="pm-text-gray-500 pm-w-3 pm-h-3">
            <Icon name="MdiClose" class="pm-block pm-w-3 pm-h-3" />
          </button>
        </div>
        <div class="content pm-p-3">
          <IuiTabs :full-width="false" :tabs="$options.tabs" :value="selectedTabs" @change="onSelectTab">
            <template #theme>
              <div class="pm-grid pm-text-gray-500 pm-mt-2 pm-px-1" :class="{ 'pm-grid-cols-2 pm-gap-3': hasNegative }">
                <IuiField label="Value" :responsive="true">
                  <IuiSelect :options="options" :value="themeValue" placeholder="Select" @input="onInput($event)" />
                </IuiField>
                <IuiField label="Negative" :responsive="true" v-if="hasNegative">
                  <IuiSwitch class="pm-w-full pm-my-2" :value="isNegative" @click="toggleNegative" />
                </IuiField>
              </div>
            </template>
            <template #custom>
              <div class="pm-grid pm-text-gray-500 pm-grid-cols-2 pm-gap-3 pm-mt-2 pm-px-1">
                <IuiField label="Value" :responsive="true">
                  <IuiTextbox class="pm-grow pm-w-full" type="number" placeholder="12" :value="customValue"
                    @input="onCustomInput($event)" />
                </IuiField>
                <IuiField label="Unit" :responsive="true">
                  <IuiSelect class="pm-w-full" :options="$options.u" :value="unit" @input="updateUnit($event)" />
                </IuiField>
              </div>
            </template>
          </IuiTabs>
        </div>
      </div>
    </template>
  </VPopover>


</template>

<script>
import inspectorInput from "@/mixins/inspector-input";
import { IuiField, IuiButton, IuiSwitch, IuiSelect, IuiTextbox, IuiTabs } from "inspector-ui"
import { units, themeTabs } from "@/config/prop-values";
import { isArbitrary, arbitraryVariantValue } from "@/utils";

export default {
  tabs: themeTabs,
  u: units,
  mixins: [inspectorInput],
  components: {
    IuiField,
    IuiButton,
    IuiSwitch,
    IuiSelect,
    IuiTextbox,
    IuiTabs
  },
  mounted() {
    if (this.isArbitrary) {
      const [val, unit] = this.arbitraryVariants
      this.unit = unit
    }
  },

  data() {
    return {
      selectedTabs: 'theme',
      unit: 'px'
    }
  },

  inheritAttrs: false,
  props: {
    options: [Array, Object],
    name: String,
    heading: String,
    hasNegative: Boolean,
  },

  computed: {
    isArbitrary() {
      return isArbitrary(this.value)
    },
    display() {
      if (this.isArbitrary) {
        return this.arbitraryVariants.join('')
      }
      else {
        let item = this.options.find((item) => item.value == Math.abs(this.value));
        if (!item) return
        return (this.isNegative ? "-" : "") + item?.value;
      }
    },
    isNegative() {
      return this.value < 0;
    },
    customValue() {
      if (this.isArbitrary) return this.arbitraryVariants[0]
      else return this.value * 4
    },
    themeValue() {
      if (!this.value) return ""
      return this.isArbitrary ? "" : Math.abs(this.value)
    },
    arbitraryVariants() {
      return arbitraryVariantValue(this.value)
    }
  },

  methods: {
    reset() {
      if (!this.canReset) return;
      this.update(this.prop, null);
    },
    onSelectTab(tab) {
      this.selectedTabs = tab;
    },
    toggleNegative() {
      this.update(this.prop, this.value * -1);
    },
    onInput(value) {
      this.unit = 'px'
      let multipler = this.isNegative ? -1 : 1;
      this.update(this.prop, value * multipler)
    },
    onCustomInput(value) {
      this.update(this.prop, `[${value}${this.unit}]`)
    },
    updateUnit(value) {
      this.unit = value;
      this.update(this.prop, `[${this.customValue}${value}]`)
    }
  },
};
</script>

<style>
.tooltip-inner:has(.top-space) {
  @apply pm-right-[242px] pm-top-[-38px];
}

.tooltip-inner:has(.right-space) {
  @apply pm-right-[370px] pm-top-[-38px];
}

.tooltip-inner:has(.bottom-space) {
  @apply pm-right-[242px] pm-top-[-133px];
}

.tooltip-inner:has(.left-space) {
  @apply pm-right-[242px] pm-top-[-38px];
}


.space-top:hover .v-popover,
.y-lock:has(.space-bottom:hover) .space-top .v-popover {
  @apply pm-bg-gray-200;
}

.space-bottom:hover .v-popover,
.y-lock:has(.space-top:hover) .space-bottom .v-popover {
  @apply pm-bg-gray-200;
}

.space-left:hover .v-popover,
.x-lock:has(.space-right:hover) .space-left .v-popover {
  @apply pm-bg-gray-200;
}

.space-right:hover .v-popover,
.x-lock:has(.space-left:hover) .space-right .v-popover {
  @apply pm-bg-gray-200;
}

.all-lock:has(.space-top:hover, .space-bottom:hover, .space-left:hover, .space-right:hover) .v-popover {
  @apply pm-bg-gray-200;
}
</style>