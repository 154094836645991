<template>
  <VList
    ref="imageList"
    class="pm-border-t pm-border-gray-200"
    endpoint="true"
    :page="1"
    :per-page="48"
    :request-handler="fetchMediaList"
    :params="{ workspaceId: PAGE_DATA.workspaceId }"
  >
    <template #loading>
      <div class="media-grid">
        <div class="media-grid__item" v-for="n in 48" :key="`loading-${n}`">
          <div class="media-grid__media">
            <div class="media-grid__shimmer"></div>
          </div>
          <div class="media-grid__name">
            <div class="pm-h-4 pm-w-full pm-animate-pulse pm-bg-gray-200"></div>
          </div>
        </div>
      </div>
    </template>
    
    <template #empty> <div class="pm-p-1 pm-text-center">No Data Found</div> </template>
    <template #loading-page> <span></span> </template>

    <template #default="{ items }">
      <div class="media-grid" v-if="items">
        <div
          class="media-grid__item"
          v-for="item in [...prepend, ...items]"
          v-show="!deleted.includes(item.id)"
          :key="`item-${item.id}`"
        >
          <!-- UPLOADING -->
          <div
            v-if="item.status == 'uploading'"
            class="pm-absolute pm-inset-0 pm-z-20 pm-flex pm-items-center pm-justify-center pm-bg-black pm-bg-opacity-70 pm-text-white"
          >
            <Icon name="MdiLoading" class="pm-h-5 pm-w-5 pm-animate-spin" />
          </div>

          <!-- ERROR -->
          <div
            v-if="item.status == 'error'"
            class="pm-absolute pm-inset-0 pm-z-20 pm-flex pm-items-center pm-justify-center pm-bg-gray-100 pm-bg-opacity-80 pm-text-white"
          >
            <Icon name="MdiAlert" class="pm-h-5 pm-w-5 pm-text-gray-500" />
          </div>

          <!-- ACTIVE MEDIA -->
          <div class="media-grid__media" @click="$emit('select', item.url)">
            <img :src="item.url" alt="" />
          </div>
          <div class="media-grid__name">
            <p v-tooltip="tooltip(item)">{{ item.name }}</p>
          </div>
          <Icon
            class="media-grid__delete"
            name="MdiDelete"
            @click.native="del(item.id)"
          />
        </div>
      </div>

      <div class="pm-p-3">
        <LoadMore />
      </div>
    </template>
  </VList>
</template>

<script>
import bus from "@/bus";
import LoadMore from "@/components/LoadMore";
import list from "@/gql/media/list.gql";
import del from "@/gql/media/delete.gql";

export default {
  props: {
    value: Array,
    prepend: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    LoadMore,
  },

  inject: ["PAGE_DATA"],

  data() {
    return {
      deleted: [],
    };
  },
  watch: {
    prepend(val) {
      if(val.length === 1 && val[0].status != "uploading" && !this.$refs.imageList?.response?.items?.length) {
        this.$refs.imageList.refresh();
        this.$emit("updatePrepend", []);
      }
    },
  },

  methods: {
    tooltip(media) {
      return {
        content: `
        <table class="media-info-table">
          <tr>
            <td>Name</td>
            <td>${media.name}</td>
          </tr>

          <tr>
            <td>Size</td>
            <td>${media.size}</td>
          </tr>
          <tr>
            <td>Type</td>
            <td>${media.type}</td>
          </tr>
          <tr v-if="">
            <td>Dimension</td>
            <td>${
              media.type.includes("svg")
                ? "N/A"
                : media.width + "x" + media.height
            }</td>
          </tr>
        </table>
      `,
        delay: {
          show: 1000,
        },
      };
    },

    del(id) {
      bus.$emit("confirm", {
        title: "Delete Media?",
        message:
          "This media will be deleted permanently. Make sure this is not used in any of your landing pages.",
        okLabel: "Yes, Delete",
        okTheme: "danger",
        ok: () => {
          this.$apollo.mutate(del, {
            workspaceId: this.PAGE_DATA.workspaceId,
            id,
          });
          this.deleted.push(id);
        },
      });
    },

    fetchMediaList(payload) {
      const { search, pagination, sort, filters, params } = payload;

      return this.$apollo
        .query(list, {
          workspaceId: params.workspaceId,
          search,
          page: pagination.page,
          perPage: pagination.perPage,
          filters,
          sort:
            sort.by === undefined || sort.order === undefined
              ? undefined
              : sort,
        })
        .then((res) => res.data.medias)
        .then((res) => {
          return {
            items: res.data.map((item) => {
              return {
                id: item.id,
                name: item.name,
                type: item.mimetype,
                width: item.width,
                height: item.height,
                url: item.url,
                size: item.size,
              };
            }),
            count: res.pagination.total,
          };
        });
    },
  },
};
</script>

<style lang="scss">
.media-grid {
  @apply pm-grid pm-grid-cols-3 pm-gap-px pm-border-b pm-border-gray-200 pm-bg-gray-200;
}
.media-grid__item {
  @apply pm-relative pm-bg-white pm-p-2;

  &:hover {
    @apply pm-ring-2 pm-ring-inset pm-ring-primary-100;

    .media-grid__media {
      --bg: theme("colors.gray.200");
      background-color: white;
      background-image: linear-gradient(45deg, var(--bg) 25%, transparent 25%),
        linear-gradient(-45deg, var(--bg) 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, var(--bg) 75%),
        linear-gradient(-45deg, transparent 75%, var(--bg) 75%);
      background-size: 10px 10px;
      background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
    }

    .media-grid__delete {
      display: block;
    }
  }
}

.media-grid__media {
  @apply pm-aspect-w-1 pm-aspect-h-1 pm-cursor-pointer;
  img {
    @apply pm-h-full pm-w-full pm-object-contain;
  }
}

.media-grid__shimmer {
  @apply pm-h-full pm-animate-pulse pm-bg-gray-200;
}

.media-grid__name {
  @apply pm-mt-2 pm-cursor-help pm-overflow-hidden pm-text-xxs pm-text-gray-500;
  p {
    @apply pm-truncate;
  }
}

.media-info-table {
  @apply pm-border-collapse pm-border pm-border-black pm-p-2 pm-text-xxs;
  tr {
    td:nth-child(1) {
      @apply pm-border-r pm-border-gray-700 pm-px-1 pm-py-0.5;
    }
    td:nth-child(2) {
      @apply pm-border-gray-700 pm-px-1 pm-py-0.5;
    }
    &:not(:last-child) {
      td {
        @apply pm-border-b;
      }
    }
  }
}

.media-grid__delete {
  @apply pm-absolute pm-top-2 pm-right-2 pm-hidden pm-w-4 pm-w-4 pm-cursor-pointer pm-rounded-full pm-bg-danger-500 pm-p-0.5 pm-text-white;
}
</style>
