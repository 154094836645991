import Vue from "vue";
// Imported the tailwind css first to give the priority to component level css
import "@/styles/main/index.scss"; 
import "@/global";
import "@/plugins/";
import "@/global/icons";
import bus from "@/bus";
import App from "@/App.vue";
import store from "@/store/";
import "@/global/interfaces";
import "@/global/prototypes";
import "@/global/directives";
import router from "@/router";

import "@/global/inspector-inputs";
import { $apollo } from "@/plugins/apollo";
import get from "@/gql/shopifyProduct/get.gql";

import Segments from "@pagemakerhq/segments/src/install";
Vue.use(Segments, {
  bus,
  mode: "editor",
  apiResolver(type, options) {
    if (type == "get-shopify-product") {
      return $apollo.query(get, options).then(({ data }) => {
        return data?.shopifyProduct;
      });
    }
  },
});

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");