<template>
  <IAccordion title="Text" open>
    <div class="pm-space-y-3">
      <IColor label="Color" prop="color" />

      <div class="pm-flex pm-space-x-1">
        <IButtonIconGroup
          label="Style"
          prop="fontStyle"
          :options="$options.fontStyle"
        />

        <IButtonIconGroup
          label="Decoration"
          prop="decoration"
          :options="$options.decoration"
        />
      </div>

      <TextAlign />

      <div class="pm-grid pm-grid-cols-2 pm-gap-3">
        <ISelect label="Weight" prop="weight" :options="$options.fontWeight" />
        <ISelect
          label="Font Family"
          prop="family"
          :options="$options.fontFamily"
        />
      </div>

      <div class="pm-grid pm-grid-cols-2 pm-gap-3">
        <ISelect label="Size" prop="size" :options="$options.fontSize" />
        <ISelect
          label="Line Height"
          prop="leading"
          :options="$options.leading"
        />
      </div>

      <IButtonIconGroup
        label="Transform"
        prop="transform"
        :options="$options.transform"
      />

      <TextContent v-if="contentSwitching" />
      <!-- <ITextarea v-else label="Content" prop="content" theme="underline" /> -->
    </div>
  </IAccordion>
</template>

<script>
import { mapGetters } from "vuex";
import { startCase } from "lodash-es";
import theme from "@/config/tailwind-theme";
import TextAlign from "@/components/inspector/common/TextAlign";
import TextContent from "./Content";

const fontSize = Object.keys(theme.fontSize).map((key) => {
  return {
    label: key,
    value: key,
  };
});

const fontFamily = ["display", "body"].map((item) => {
  return {
    label: startCase(item),
    value: item,
  };
});

const fontWeight = [
  {
    label: "Bold",
    value: "bold",
  },
  {
    label: "Normal",
    value: "normal",
  },
  {
    label: "Thin",
    value: "thin",
  },
];

const leading = Object.keys(theme.lineHeight).map((key) => {
  return {
    label: startCase(key),
    value: key,
  };
});

const decoration = [
  {
    label: "Underline",
    value: "underline",
    icon: "MdiFormatUnderline",
  },
  {
    label: "line-through",
    value: "line-through",
    icon: "MdiFormatStrikethrough",
  },
];

const fontStyle = [
  {
    label: "Italic",
    value: "italic",
    icon: "MdiFormatItalic",
  },
];

const transform = [
  {
    label: "Uppercase",
    value: "uppercase",
    icon: "RadixIconsLetterCaseUppercase",
  },
  {
    label: "Lowercase",
    value: "lowercase",
    icon: "RadixIconsLetterCaseLowercase",
  },
  {
    label: "Capitalize",
    value: "capitalize",
    icon: "RadixIconsLetterCaseCapitalize",
  },
];

export default {
  fontFamily,
  fontSize,
  fontWeight,
  leading,
  decoration,
  fontStyle,
  transform,

  components: {
    TextAlign,
    TextContent,
  },

  computed: {
    ...mapGetters({
      type: "template/type",
    }),
    contentSwitching() {
      return ["product_page", "promo_page"].includes(this.type);
    },
  },
};
</script>
