<template>
  <IntroSection
    icon="PhWarningCircleDuotone"
    title="Select Device."
    desc="Editing is not available in Full Width Mode. Please select a device from
        top bar to start editing."
  />
</template>

<script>
import IntroSection from "./IntroSection.vue";

export default {
  components: {
    IntroSection,
  },
};
</script>
