<template>
    <VPopover
        class="pm-h-full pm-grow"
        :ref="prop"
        placement="top-start"
        @apply-hide="$emit('hide')"
        @apply-show="$emit('show')"
    >
        <template #default>
            <div
                class="pm-flex pm-text-xs pm-text-gray-500 pm-h-full pm-font-bold pm-cursor-pointer pm-p-4 "
                :class="{
                    '!pm-text-gray-400': typeof display === 'undefined',
                    'pm-justify-start pm-items-start': name === 'top-left',
                    'pm-justify-end pm-items-start': name === 'top-right',
                    'pm-justify-start pm-items-end': name === 'bottom-left',
                    'pm-justify-end pm-items-end': name === 'bottom-right',
                    [name]: name,
                }"
                v-bind="$attrs"
            >
                <span>{{ display ?? "–" }}</span>
            </div>
        </template>

        <template #popover="data">
            <div
                class="pm-absolute pm-flex pm-flex-col pm-w-52 pm-rounded-md pm-overflow-hidden pm-bg-white pm-shadow-md"
                :class="[name]"
            >
                <div
                    class="pm-text-black pm-border-b pm-px-3 pm-h-8 pm-flex pm-items-center"
                >
                    <span class="pm-grow pm-text-xxs">{{ heading }}</span>
                    <button
                        v-close-popover
                        class="pm-text-gray-500 pm-w-3 pm-h-3"
                    >
                        <Icon name="MdiClose" class="pm-block pm-w-3 pm-h-3" />
                    </button>
                </div>
                <div class="content pm-p-3">
                    <IuiTabs
                        :full-width="false"
                        :tabs="$options.tabs"
                        :value="selectedTabs"
                        @change="onSelectTab"
                    >
                        <template #theme>
                            <div class="pm-grid pm-text-gray-500 pm-mt-2 pm-px-1">
                                <IuiField label="Value" :responsive="true">
                                    <IuiSelect
                                        :options="$options.rounded"
                                        :value="themeValue"
                                        placeholder="Select"
                                        @input="onInput($event)"
                                    />
                                </IuiField>
                            </div>
                        </template>
                        <template #custom>
                            <div
                                class="pm-grid pm-text-gray-500 pm-grid-cols-2 pm-gap-3 pm-mt-2 pm-px-1"
                            >
                                <IuiField label="Value" :responsive="true">
                                    <IuiTextbox
                                        class="pm-grow pm-w-full"
                                        type="number"
                                        placeholder="12"
                                        :value="customValue"
                                        @input="onCustomInput($event)"
                                    />
                                </IuiField>
                                <IuiField label="Unit" :responsive="true">
                                    <IuiSelect
                                        class="pm-w-full"
                                        :options="$options.u"
                                        :value="unit"
                                        @input="updateUnit($event)"
                                    />
                                </IuiField>
                            </div>
                        </template>
                    </IuiTabs>
                </div>
            </div>
        </template>
    </VPopover>
</template>

<script>
import inspectorInput from "@/mixins/inspector-input";
import {
    IuiField,
    IuiButton,
    IuiSwitch,
    IuiSelect,
    IuiTextbox,
    IuiTabs,
} from "inspector-ui";
import { units, themeTabs } from "@/config/prop-values";
import { isArbitrary, arbitraryVariantValue } from "@/utils";

const rounded = [
    { value: "none", label: "0 (0px)", shortLabel: "0" },
    { value: "sm", label: "1 (2px)", shortLabel: "1" },
    { value: "DEFAULT", label: "2 (4px)", shortLabel: "2" },
    { value: "md", label: "3 (6px)", shortLabel: "3" },
    { value: "lg", label: "4 (8px)", shortLabel: "4" },
    { value: "xl", label: "5 (12px)", shortLabel: "5" },
    { value: "2xl", label: "6 (16px)", shortLabel: "6" },
    { value: "3xl", label: "7 (24px)", shortLabel: "7" },
    { value: "4xl", label: "8 (32px)", shortLabel: "8" },
    { value: "5xl", label: "9 (48px)", shortLabel: "9" },
    { value: "6xl", label: "10 (64px)", shortLabel: "10" },
    { value: "full", label: "♾ (9999px)", shortLabel: "♾" },
];

export default {
    rounded,
    tabs: themeTabs,
    u: units,
    mixins: [inspectorInput],
    components: {
        IuiField,
        IuiButton,
        IuiSwitch,
        IuiSelect,
        IuiTextbox,
        IuiTabs,
    },
    mounted() {
        if (this.isArbitrary) {
            const [val, unit] = this.arbitraryVariants;
            this.unit = unit;
        }
    },

    data() {
        return {
            selectedTabs: "theme",
            unit: "px",
        };
    },

    inheritAttrs: false,
    props: {
        options: [Array, Object],
        name: String,
        heading: String,
    },

    computed: {
        isArbitrary() {
            return isArbitrary(this.value);
        },
        isDefault() {
            return this.value == "DEFAULT";
        },
        display() {
            if (this.isArbitrary) {
                return this.arbitraryVariants.join("");
            } else {
                let item = this.$options.rounded.find(
                    (item) => item.value == this.value
                );
                if (!item) return;
                return item?.shortLabel;
            }
        },
        customValue() {
            if (this.isArbitrary) return this.arbitraryVariants[0];
            else return this.value;
        },
        themeValue() {
            if (!this.value) return "";
            return this.isArbitrary ? "" : this.value;
        },
        arbitraryVariants() {
            return arbitraryVariantValue(this.value);
        },
    },

    methods: {
        onSelectTab(tab) {
            this.selectedTabs = tab;
        },
        onInput(value) {
            this.unit = "px";
            this.update(this.prop, value);
        },
        onCustomInput(value) {
            this.update(this.prop, `[${value}${this.unit}]`);
        },
        updateUnit(value) {
            this.unit = value;
            this.update(this.prop, `[${this.customValue}${value}]`);
        },
    },
};
</script>

<style>
.tooltip-inner:has(.top-left) {
    @apply pm-right-[242px] pm-top-[-36px];
}

.tooltip-inner:has(.top-right) {
    @apply pm-right-[306px] pm-top-[-36px];
}

.tooltip-inner:has(.bottom-left) {
    @apply pm-right-[242px] pm-top-[-100px];
}

.tooltip-inner:has(.bottom-right) {
    @apply pm-right-[306px] pm-top-[-100px];
}

.top-left:hover .v-popover,
.top-lock:has(.top-right:hover) .top-left .v-popover,
.left-lock:has(.bottom-left:hover) .top-left .v-popover {
    @apply pm-bg-gray-200;
}

.top-right:hover .v-popover,
.top-lock:has(.top-left:hover) .top-right .v-popover,
.right-lock:has(.bottom-right:hover) .top-right .v-popover {
    @apply pm-bg-gray-200;
}

.bottom-left:hover .v-popover,
.left-lock:has(.top-left:hover) .bottom-left .v-popover,
.bottom-lock:has(.bottom-right:hover) .bottom-left .v-popover {
    @apply pm-bg-gray-200;
}

.bottom-right:hover .v-popover,
.bottom-lock:has(.bottom-left:hover) .bottom-right .v-popover,
.right-lock:has(.top-right:hover) .bottom-right .v-popover {
    @apply pm-bg-gray-200;
}

.all-lock:has(
        .top-left:hover,
        .top-right:hover,
        .bottom-left:hover,
        .bottom-right:hover
    )
    .v-popover {
    @apply pm-bg-gray-200;
}
</style>
