<template>
  <button
  class="orientation"
    :class="{ 'active': value, 'pm-text-gray-300 pm-cursor-not-allowed': disabled, [orientation]: orientation }"
    @click="$emit('click')"
  >
  
  </button>
</template>

<script>

export default {
  props: {
    value: {
      type: [Boolean, Number, String],
      default: false
    },
    disabled: Boolean,
    orientation: {
      type: String,
      default: 'all'
    }
  },
};
</script>

<style>

.orientation.active:before,
.orientation.active:after {
  @apply pm-bg-primary-300;
}

.orientation:before,
.orientation:after {
  @apply pm-absolute pm-inline-block pm-bg-gray-300;
}

button.y {
  @apply pm-absolute pm-w-2 pm-h-full pm-px-1
}
button.y:hover::after {
  @apply pm-bg-gray-500
}
button.y:hover::before {
  @apply pm-bg-gray-500
}

button.x {
  @apply pm-absolute pm-w-full pm-h-2 pm-px-1
}
button.x:hover::after {
  @apply pm-bg-gray-500
}
button.x:hover::before {
  @apply pm-bg-gray-500
}

button.all {
  @apply pm-absolute pm-w-4 pm-h-4 pm-p-1
}
button.all:hover::before {
  @apply pm-bg-gray-500
}

.y:after {
  content: "";
  height: calc(50% - 16px);
  @apply pm-w-[2px] -pm-translate-x-1/2 pm-bottom-2 pm-rounded-full;
}
.y:before {
  content: "";
  height: calc(50% - 16px);
  @apply pm-w-[2px] -pm-translate-x-1/2 pm-top-2 pm-rounded-full;
}

.x:after {
  content: "";
  width: calc(50% - 16px);
  @apply pm-h-[2px] -pm-translate-y-1/2 pm-right-2 pm-rounded-full;
}
.x:before {
  content: "";
  width: calc(50% - 16px);
  @apply pm-h-[2px] -pm-translate-y-1/2 pm-left-2 pm-rounded-full;
}

.all:before {
  content: "";
  @apply pm-absolute pm-w-1 pm-h-1 pm-rounded-full pm-bg-gray-300 -pm-translate-y-1/2 -pm-translate-x-1/2;
}
</style>