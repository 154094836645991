<template>
  <IuiField inline :label="label" :disabled="isDisabled" :responsive="hasResponsive">
    <IuiNumber :value="value" @plus="plus()" @minus="minus()" :disabled="isDisabled" />
  </IuiField>
</template>

<script>
import inspectorInput from "@/mixins/inspector-input";
import { IuiNumber, IuiField } from "inspector-ui"

export default {
  mixins: [inspectorInput],
  components: {
    IuiNumber,
    IuiField
  },

  props: {
    max: {
      type: Number,
      default: 99,
    },
    min: {
      type: Number,
      default: 1,
    },
  },

  methods: {
    plus() {
      if (this.value < this.max) {
        this.update(this.prop, this.value + 1);
      }
    },
    minus() {
      if (this.value > this.min) {
        this.update(this.prop, this.value - 1);
      }
    },
  },
};
</script>
