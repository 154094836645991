<template>
  <div
    class="pm-px-3 pm-py-2 pm-border-b pm-flex pm-flex-nowrap pm-items-center"
  >
    <label class="pm-flex-grow pm-text-xxs pm-text-gray-500">State</label>
    <IuiButtonGroup
      :options="$options.states"
      :value="active"
      @input="setInspectorState"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { IuiButtonGroup } from "inspector-ui";

export default {
  states: [
    {
      label: "Default",
      value: "default",
    },
    {
      label: "Hover",
      value: "hover",
    },
  ],
  components: {
    IuiButtonGroup,
  },
  computed: {
    ...mapGetters({
      isHover: "editor/isHover",
    }),
    active() {
      return this.isHover ? "hover" : "default";
    },
  },
  methods: {
    ...mapMutations({
      setInspectorState: "editor/setInspectorState",
    }),
  },
};
</script>

<style lang="scss">
.states {
  display: flex;
  position: relative;
  @apply pm-divide-x pm-border pm-rounded-sm pm-overflow-hidden;
}
.state {
  outline: none !important;
  @apply pm-h-5 pm-px-3 pm-font-medium pm-text-xxs pm-flex-grow pm-flex pm-items-center pm-justify-center pm-text-gray-400 hover:pm-text-gray-500;

  &--active {
    @apply pm-text-primary-500 pm-bg-gray-100 hover:pm-text-primary-500;
  }

  &--loading {
    @apply pm-cursor-wait pm-text-primary-500;
    .icon {
      @apply pm-animate-spin;
    }
  }
}
</style>
