<template>
  <IAccordion title="Shadow">
    <ISlider :options="$options.shadow" prop="shadow" />
  </IAccordion>
</template>

<script>
const shadow = [
  { value: "none", label: "0" },
  { value: "sm", label: "1" },
  { value: "DEFAULT", label: "2" },
  { value: "md", label: "3" },
  { value: "lg", label: "4" },
  { value: "xl", label: "5" },
  { value: "2xl", label: "6" },
];

export default {
  shadow,
};
</script>
