<template>
  <Modal id="shortcut-modal" #default="{ close }">
    <ModalShell title="Shortcuts Keys" :close="close">
      <div class="pm-p-4">
        <div class="pm-rounded-md pm-overflow-hidden pm-border-gray-100 pm-border-2">
          <table class="pm-w-full">
            <tr class="odd:pm-bg-gray-100" v-for="(key, index) in keys" :key="index">
              <td class="pm-p-4">
                <div class="shortcut-key pm-flex pm-items-center pm-flex-wrap">
                  <span
                    class="pm-bg-white pm-block pm-px-2 pm-py-1 pm-border  pm-rounded pm-text-xs pm-border-b-2 pm-border-gray-200 pm-text-gray-500 pm-space-x-4"
                    v-for="(short, ind) in key.shortcut" :key="ind">
                    {{ short }}
                  </span>
                </div>
              </td>
              <td class="pm-p-4 pm-text-right pm-text-gray-500 pm-text-sm">
                {{ key.name }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </ModalShell>
  </Modal>
</template>

<script>
import OS from "platform-detect/os.mjs";
export default {
  computed: {
    cmdOrCtrl() {
      return OS.macos ? "Command" : "Ctrl";
    },

    keys() {
      return [
        {
          name: "Save Page",
          shortcut: [this.cmdOrCtrl, "s"],
        },
        {
          name: "Preview Page",
          shortcut: [this.cmdOrCtrl, "p"],
        },
        {
          name: "Publish Page",
          shortcut: ["Shift", this.cmdOrCtrl, "p"],
        },
        {
          name: "Exit Editor",
          shortcut: [this.cmdOrCtrl, "Escape"],
        },
        {
          name: "Toggle Side Panes",
          shortcut: [this.cmdOrCtrl, "\\"],
        },
        {
          name: "Undo Changes",
          shortcut: [this.cmdOrCtrl, "z"],
        },
        {
          name: "Redo Changes",
          shortcut: [this.cmdOrCtrl, "y"],
        },
      ];
    },
  },
};
</script>
<style lang="scss">
.shortcut-table {
  @apply pm-w-full;

  tr:nth-child(odd) {
    @apply pm-bg-gray-100;
  }
}

.shortcut-key {
  span {
    &+span {
      @apply pm-relative pm-ml-5;

      &::before {
        content: "+";
        left: -15px;
        @apply pm-absolute;
      }
    }
  }
}
</style>
