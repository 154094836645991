<template>
  <IuiLock
    :value="value"
    :disabled="isDisabled"
    @click="isDisabled ? null : update(prop, !value)"
  />
</template>

<script>
import inspectorInput from "@/mixins/inspector-input";
import {IuiLock} from "inspector-ui"

export default {
  components: {
    IuiLock
  },
  mixins: [inspectorInput],
};
</script>

