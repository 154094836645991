<template>
  <ISlider label="Gap" prop="gap" :options="$options.gap" />
</template>

<script>
import { spacing } from "@/config/prop-values";

export default {
  gap: spacing,
};
</script>
