<template>
  <IButtonIconGroup
    label="Text Align"
    :options="$options.textAlign"
    prop="textAlign"
  />
</template>

<script>
const textAlign = [
  {
    label: "Left",
    value: "left",
    icon: "MdiFormatAlignLeft",
  },
  {
    label: "Center",
    value: "center",
    icon: "MdiFormatAlignCenter",
  },
  {
    label: "Right",
    value: "right",
    icon: "MdiFormatAlignRight",
  },
  {
    label: "Justify",
    value: "justify",
    icon: "MdiFormatAlignJustify",
  },
];

export default {
  textAlign,
};
</script>
