<template>
  <button
    class="pm-flex pm-h-7 pm-w-7 pm-items-center pm-justify-center pm-rounded-sm pm-text-gray-400 hover:pm-text-gray-500 !pm-outline-none"
    :class="{ 'pm-bg-gray-200 pm-text-black': active }"
    :title="title"
  >
    <Icon class="pm-h-4 pm-w-4" :name="icon" />
  </button>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
    active: Boolean,
  },
};
</script>
