<template>
  <div v-if="value" class="pm-flex pm-flex-nowrap">
    <div @click="$emit('open')" class="pm-flex-grow pm-cursor-pointer">
      <img class="pm-w-full" :src="value" alt="" />
    </div>
    <div class="pm-flex pm-flex-shrink-0 pm-flex-nowrap pm-pl-2">
      <ButtonIcon icon="MdiPencil" @click.native="$emit('open')" class="disabled:pm-cursor-not-allowed" />
      <ButtonIcon v-if="value" icon="MdiClose" :active="!isLocked" class="disabled:pm-cursor-not-allowed"
        @click.native="$emit('input', { value: null, source: null })" />
    </div>
  </div>
  <button v-else @click="$emit('open')" class="pm-flex pm-h-24 pm-w-full pm-items-center pm-justify-center pm-border disabled:pm-cursor-not-allowed">
    <Icon name="MdiImage" class="pm-h-6 pm-w-6 pm-text-gray-300" />
  </button>
</template>

<script>
import Field from "./Field.vue";
import ButtonIcon from "./ButtonIcon.vue";

export default {
  components: {
    Field,
    ButtonIcon,
  },
  props: {
    value: String,
    isLocked: Boolean,
  }
};
</script>
