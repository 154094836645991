<template>
    <IAccordion title="Radius" prop-group="rounded">
        <IuiRadiusBox
            :topLocked="topLocked"
            :leftLocked="leftLocked"
            :bottomLocked="bottomLocked"
            :rightLocked="rightLocked"
            :allLocked="allLocked"
        >
            <template #top-left>
                <IRadiusInput
                    :value-input="(value) => valueInput(value, 'roundedTl')"
                    :props-output="(props) => propsOutput(props, 'roundedTl')"
                    prop="roundedTl"
                    :heading="topLeftRoundedHeading"
                    name="top-left"
                    class="pm-grid"
                    :class="{
                        '!pm-bg-primary-50': isActive('top-left'),
                    }"
                    @show="active = 'top-left'"
                    @hide="active = active === 'top-left' ? null : active"
                />
            </template>
            <template #top-right>
                <IRadiusInput
                    :value-input="(value) => valueInput(value, 'roundedTr')"
                    :props-output="(props) => propsOutput(props, 'roundedTr')"
                    prop="roundedTr"
                    :heading="topRightRoundedHeading"
                    name="top-right"
                    class="pm-grid"
                    :class="{
                        '!pm-bg-primary-50': isActive('top-right'),
                    }"
                    @show="active = 'top-right'"
                    @hide="active = active === 'top-right' ? null : active"
                />
            </template>
            <template #bottom-left>
                <IRadiusInput
                    :value-input="(value) => valueInput(value, 'roundedBl')"
                    :props-output="(props) => propsOutput(props, 'roundedBl')"
                    prop="roundedBl"
                    :heading="bottomLeftRoundedHeading"
                    name="bottom-left"
                    class="pm-grid"
                    :class="{
                        '!pm-bg-primary-50': isActive('bottom-left'),
                    }"
                    @show="active = 'bottom-left'"
                    @hide="active = active === 'bottom-left' ? null : active"
                />
            </template>
            <template #bottom-right>
                <IRadiusInput
                    :value-input="(value) => valueInput(value, 'roundedBr')"
                    :props-output="(props) => propsOutput(props, 'roundedBr')"
                    prop="roundedBr"
                    :heading="bottomRightRoundedHeading"
                    name="bottom-right"
                    class="pm-grid"
                    :class="{
                        '!pm-bg-primary-50': isActive('bottom-right'),
                    }"
                    @show="active = 'bottom-right'"
                    @hide="active = active === 'bottom-right' ? null : active"
                />
            </template>
            <template>
                <div class="pm-relative pm-h-10 pm-w-10 pm-aspect-1">
                    <RadiusLock
                        v-tooltip="{content:'Lock Top Radius', delay:$options.d}"
                        prop="roundedT"
                        :value-input="(value) => {return (value == null ? false : 'DEFAULT')}"
                        orientation="top"
                        :value-output="(value) => (value == false ? null : 'DEFAULT')"
                        :side-effect="(props) => lockSideEffect(props, 'roundedT')"
                    />
                    <RadiusLock
                        v-tooltip="{content:'Lock Right Radius', delay:$options.d}"
                        prop="roundedR"
                        :value-input="(value) => {return (value == null ? false : 'DEFAULT')}"
                        orientation="right"
                        :value-output="(value) => (value == false ? null : 'DEFAULT')"
                        :side-effect="(props) => lockSideEffect(props, 'roundedR')"
                    />
                    <RadiusLock
                        v-tooltip="{content:'Lock Bottom Radius', delay:$options.d}"
                        prop="roundedB"
                        :value-input="(value) => {return (value == null ? false : 'DEFAULT')}"
                        orientation="bottom"
                        :value-output="(value) => (value == false ? null : 'DEFAULT')"
                        :side-effect="(props) => lockSideEffect(props, 'roundedB')"
                    />
                    <RadiusLock
                        v-tooltip="{content:'Lock Left Radius', delay:$options.d}"
                        prop="roundedL"
                        :value-input="(value) => {return (value == null ? false : 'DEFAULT')}"
                        orientation="left"
                        :value-output="(value) => (value == false ? null : 'DEFAULT')"
                        :side-effect="(props) => lockSideEffect(props, 'roundedL')"
                    />
                    <RadiusLock
                        v-tooltip="{content:'Lock All Radius', delay:$options.d}"
                        prop="rounded"
                        orientation="all"
                        :value-input="(value) => {return (value == null ? false : 'DEFAULT')}"
                        :value-output="(value) => (value == false ? null : 'DEFAULT')"
                        :side-effect="(props) => lockSideEffect(props, 'rounded')"
                    />
                </div>
            </template>
        </IuiRadiusBox>
    </IAccordion>
</template>
<script>
import { mapGetters } from "vuex";
import Lock from "../inputs/Lock";
import SliderGroup from "../SliderGroup";
import { IuiRadiusBox } from "inspector-ui";
import RadiusLock from "../inputs/RadiusLock";
import { tooltipDalay } from "@/config/prop-values";

export default {
    d: tooltipDalay,
    components: {
        Lock,
        SliderGroup,
        IuiRadiusBox,
        RadiusLock,
    },
    data() {
        return {
            active: null,
        };
    },
    computed: {
        ...mapGetters({
            propValueFinder: "editor/activeSegmentPropValue",
        }),

        allLocked() {
            return this.propValueFinder("rounded") != null;
        },
        topLocked() {
            return this.propValueFinder("roundedT") != null;
        },
        leftLocked() {
            return this.propValueFinder("roundedL") != null;
        },
        bottomLocked() {
            return this.propValueFinder("roundedB") != null;
        },
        rightLocked() {
            return this.propValueFinder("roundedR") != null;
        },
        topLeftRoundedHeading() {
            if (this.topLocked) return "Top Radius";
            if (this.leftLocked) return "Left Radius";
            if (this.allLocked) return "Radius";
            return "Top Left Radius";
        },
        topRightRoundedHeading() {
            if (this.topLocked) return "Top Radius";
            if (this.rightLocked) return "Right Radius";
            if (this.allLocked) return "Radius";
            return "Top Right Radius";
        },
        bottomLeftRoundedHeading() {
            if (this.bottomLocked) return "Bottom Radius";
            if (this.leftLocked) return "Left Radius";
            if (this.allLocked) return "Radius";
            return "Bottom Left Radius";
        },
        bottomRightRoundedHeading() {
            if (this.bottomLocked) return "Bottom Radius";
            if (this.rightLocked) return "Right Radius";
            if (this.allLocked) return "Radius";
            return "Bottom Right Radius";
        },
    },

    methods: {
        lockSideEffect(props, prop) {

            if (props.rounded == "DEFAULT") {
                props.roundedTl = null;
                props.roundedTr = null;
                props.roundedBl = null;
                props.roundedBr = null;
                props.roundedT = null;
                props.roundedR = null;
                props.roundedB = null;
                props.roundedL = null;
            }
            if (props.roundedT == "DEFAULT") {
                props.roundedTl = null;
                props.roundedTr = null;
                props.roundedL = null;
                props.roundedR = null;
                props.rounded = null;
            }
            if (props.roundedR == "DEFAULT") {
                props.roundedTr = null;
                props.roundedBr = null;
                props.roundedT = null;
                props.roundedB = null;
                props.rounded = null;
            }
            if (props.roundedB == "DEFAULT") {
                props.roundedBl = null;
                props.roundedBr = null;
                props.roundedL = null;
                props.roundedR = null;
                props.rounded = null;
            }
            if (props.roundedL == "DEFAULT") {
                props.roundedTl = null;
                props.roundedBl = null;
                props.roundedT = null;
                props.roundedB = null;
                props.rounded = null;
            }
            return { props };
        },

        isActive(side) {
            if (side === this.active) return true;
            if (this.allLocked && this.active) return true;
            if (this.active === "top-left") {
                if (this.topLocked && side === "top-right") return true;
                if (this.leftLocked && side === "bottom-left") return true;
                if (
                    this.topLocked &&
                    this.rightLocked &&
                    side === "bottom-right"
                )
                    return true;
                if (
                    this.bottomLocked &&
                    this.leftLocked &&
                    side === "bottom-right"
                )
                    return true;
            }
            if (this.active === "top-right") {
                if (this.topLocked && side === "top-left") return true;
                if (this.rightLocked && side === "bottom-right") return true;
                if (this.topLocked && this.leftLocked && side === "bottom-left")
                    return true;
                if (
                    this.bottomLocked &&
                    this.rightLocked &&
                    side === "bottom-left"
                )
                    return true;
            }
            if (this.active === "bottom-right") {
                if (this.bottomLocked && side === "bottom-left") return true;
                if (this.rightLocked && side === "top-right") return true;
                if (this.topLocked && this.rightLocked && side === "top-left")
                    return true;
                if (this.bottomLocked && this.leftLocked && side === "top-left")
                    return true;
            }
            if (this.active === "bottom-left") {
                if (this.bottomLocked && side === "bottom-right") return true;
                if (this.leftLocked && side === "top-left") return true;
                if (this.topLocked && this.leftLocked && side === "top-right")
                    return true;
                if (
                    this.bottomLocked &&
                    this.rightLocked &&
                    side === "top-right"
                )
                    return true;
            }
        },

        valueInput(value, prop) {
            if (this.allLocked) {
                return this.propValueFinder("rounded");
            } else if (prop === "roundedTl") {
                if(this.topLocked) return this.propValueFinder("roundedT");
                if(this.leftLocked) return this.propValueFinder("roundedL");
            } else if (prop === "roundedTr") {
                if(this.topLocked) return this.propValueFinder("roundedT");
                if(this.rightLocked) return this.propValueFinder("roundedR");
            } else if (prop === "roundedBl") {
                if(this.bottomLocked) return this.propValueFinder("roundedB");
                if(this.leftLocked) return this.propValueFinder("roundedL");
            } else if (prop === "roundedBr") {
                if(this.bottomLocked) return this.propValueFinder("roundedB");
                if(this.rightLocked) return this.propValueFinder("roundedR");
            }
            return value;
        },

        propsOutput(props, prop) {

            if (this.allLocked) {
                return {
                    ...props,
                    roundedTl: null,
                    roundedTr: null,
                    roundedBl: null,
                    roundedBr: null,
                    roundedT: null,
                    roundedR: null,
                    roundedB: null,
                    roundedL: null,
                    rounded: props[prop],
                };
            } else if (prop === "roundedTl") {
                if(this.topLocked) {
                    return {
                        ...props,
                        roundedTl: null,
                        roundedTr: null,
                        roundedT: props[prop],
                    };
                }
                if(this.leftLocked) {
                    return {
                        ...props,
                        roundedTl: null,
                        roundedBl: null,
                        roundedL: props[prop],
                    };
                }
            } else if (prop === "roundedTr") {
                if(this.topLocked) {
                    return {
                        ...props,
                        roundedTl: null,
                        roundedTr: null,
                        roundedT: props[prop],
                    };
                }
                if(this.rightLocked) {
                    return {
                        ...props,
                        roundedTr: null,
                        roundedBr: null,
                        roundedR: props[prop],
                    };
                }
            } else if (prop === "roundedBl") {
                if(this.bottomLocked) {
                    return {
                        ...props,
                        roundedBl: null,
                        roundedBr: null,
                        roundedB: props[prop],
                    };
                }
                if(this.leftLocked) {
                    return {
                        ...props,
                        roundedTl: null,
                        roundedBl: null,
                        roundedL: props[prop],
                    };
                }
            } else if (prop === "roundedBr") {
                if(this.bottomLocked) {
                    return {
                        ...props,
                        roundedBl: null,
                        roundedBr: null,
                        roundedB: props[prop],
                    };
                }
                if(this.rightLocked) {
                    return {
                        ...props,
                        roundedTr: null,
                        roundedBr: null,
                        roundedR: props[prop],
                    };
                }
            }
            return props;
        },
    },
};
</script>
