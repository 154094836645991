<template>
  <IAccordion title="Grid" open>
    <div class="pm-space-y-3">
      <INumber label="Items" :max="96" :min="1" prop="count" :side-effect="sideEffect"/>
      <ISlider label="Columns" :options="$options.cols" prop="cols" />
      <Gap />
      <AlignItems />
    </div>
  </IAccordion>
</template>

<script>
import bus from "@/bus";
import { mapGetters } from "vuex";
import Gap from "@/components/inspector/common/Gap";
import AlignItems from "@/components/inspector/common/AlignItems";

const cols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => {
  return {
    label: String(item),
    value: item,
  };
});

export default {
  cols,
  components: {
    Gap,
    AlignItems,
  },

  computed: {
    ...mapGetters({
      activeSegmentModuleUuid: "editor/activeSegmentModuleUuid",
    }),
  },

  methods:{
    sideEffect() {
      bus.$emit("refresh-active-module-layers", this.activeSegmentModuleUuid);
    },
  }
};
</script>
