<template>
  <IAccordion title="Padding" prop-group="padding">
    <IuiBox
      class="pm-w-full pm-border"
      :x-locked="xLocked"
      :y-locked="yLocked"
      :all-locked="allLocked"
    >
      <template v-slot:top>
        <ISpaceInput :options="$options.p" :value-input="(value) => valueInput(value, 'y')"
          :props-output="(props) => propsOutput(props, 'pt', 'y')" prop="pt" name="top-space"
          :heading="topPaddingHeading"
          class="pm-bg-gray-100"
          :class="{ '!pm-bg-primary-50': isActive('top', 'bottom', yLocked) }"
          @show="active = 'top'"
          @hide="active = active === 'top'?null:active"
        />
      </template>
      <template v-slot:right>
        <ISpaceInput :options="$options.p" :value-input="(value) => valueInput(value, 'x')"
          :props-output="(props) => propsOutput(props, 'pr', 'x')" prop="pr" name="right-space"
          :heading="rightPaddingHeading"
          :class="{ '!pm-bg-primary-50': isActive('right', 'left', xLocked) }"
          @show="active = 'right'"
          @hide="active = active === 'right'?null:active"
        />
      </template>
      <template v-slot:bottom>
        <ISpaceInput :options="$options.p" :value-input="(value) => valueInput(value, 'y')"
          :props-output="(props) => propsOutput(props, 'pb', 'y')" prop="pb" name="bottom-space"
          :heading="bottomPaddingHeading"
          class="pm-bg-gray-100"
          :class="{ '!pm-bg-primary-50': isActive('bottom', 'top', yLocked) }"
          @show="active = 'bottom'"
          @hide="active = active === 'bottom'?null:active"
        />
      </template>
      <template v-slot:left>
        <ISpaceInput :options="$options.p" :value-input="(value) => valueInput(value, 'x')"
          :props-output="(props) => propsOutput(props, 'pl', 'x')" prop="pl" name="left-space"
          :heading="leftPaddingHeading"
          :class="{ '!pm-bg-primary-50': isActive('left', 'right', xLocked) }"
          @show="active = 'left'"
          @hide="active = active === 'left'?null:active"
        />
      </template>
      <template>
        <div class="pm-flex pm-relative pm-justify-center pm-items-center pm-h-full pm-aspect-1">
          <SpaceLock
            v-tooltip="{content: 'Lock Horizontal Padding', delay:$options.d}"
            prop="px"
            :value-input="(value) => (value == null ? false : 1)"
            orientation="x"
            :value-output="(value) => (value == false ? null : 1)"
            :side-effect="(props) => lockSideEffect(props)"
          />
          <SpaceLock
            v-tooltip="{content: 'Lock Vertical Padding', delay:$options.d}"
            prop="py"
            :value-input="(value) => (value == null ? false : 1)"
            orientation="y"
            :value-output="(value) => (value == false ? null : 1)"
            :side-effect="(props) => lockSideEffect(props)"
          />
          <SpaceLock
            v-tooltip="{content: 'Lock Padding', delay:$options.d}"
            prop="p"
            :value-input="(value) => (value == null ? false : 1)"
            orientation="all"
            :value-output="(value) => (value == false ? null : 1)"
            :side-effect="(props) => lockSideEffect(props)"
          />
        </div>
      </template>
    </IuiBox>
  </IAccordion>
</template>

<script>
import { mapGetters } from "vuex";
import { spacing, tooltipDalay } from "@/config/prop-values";
import SpaceLock from "../inputs/SpaceLock";
import { IuiBox } from "inspector-ui";

export default {
  p: spacing,
  d: tooltipDalay,

  components: {
    SpaceLock,
    IuiBox
  },
  data() {
    return {
      active: null,
    };
  },

  computed: {
    ...mapGetters({
      propValueFinder: "editor/activeSegmentPropValue",
    }),

    allLocked() {
      return this.propValueFinder("p") != null;
    },

    xLocked() {
      return this.propValueFinder("px") != null;
    },

    yLocked() {
      return this.propValueFinder("py") != null;
    },
    topPaddingHeading() {
      if (this.yLocked) return "Vertical Padding"
      if (this.allLocked) return "Padding"
      return "Top Padding"
    },
    rightPaddingHeading() {
      if (this.xLocked) return "Horizontal Padding"
      if (this.allLocked) return "Padding"
      return "Right Padding"
    },
    bottomPaddingHeading() {
      if (this.yLocked) return "Vertical Padding"
      if (this.allLocked) return "Padding"
      return "Bottom Padding"
    },
    leftPaddingHeading() {
      if (this.xLocked) return "Horizontal Padding"
      if (this.allLocked) return "Padding"
      return "Left Padding"
    },
  },

  methods: {
    lockSideEffect(props) {
      if (props.p == 1) {
        props.pl = null;
        props.pr = null;
        props.pt = null;
        props.pb = null;
        props.px = null;
        props.py = null;
      }
      if (props.px == 1) {
        props.pl = null;
        props.pr = null;
        props.p = null;
      }
      if (props.py == 1) {
        props.p = null;
        props.pt = null;
        props.pb = null;
      }
      return { props };
    },

    valueInput(value, dir) {
      if (this.allLocked) {
        return this.propValueFinder("p");
      } else if (dir == "x" && this.xLocked) {
        return this.propValueFinder("px");
      } else if (dir == "y" && this.yLocked) {
        return this.propValueFinder("py");
      } else {
        return value;
      }
    },

    propsOutput(props, prop, dir) {
      if (this.allLocked) {
        return {
          ...props,
          pl: null,
          pr: null,
          pt: null,
          pb: null,
          py: null,
          px: null,
          p: props[prop],
        };
      } else if (dir == "x" && this.xLocked) {
        return {
          ...props,
          pl: null,
          pr: null,
          p: null,
          px: props[prop],
        };
      } else if (dir == "y" && this.yLocked) {
        return {
          ...props,
          pt: null,
          pb: null,
          p: null,
          py: props[prop],
        };
      } else {
        return props;
      }
    },
    isActive(side, oppositeSide, lock) {
      return this.active === side || (this.active === oppositeSide && lock) || (this.active && this.allLocked)
    }
  },
};
</script>
