<template>

  <div class="">
    <!-- ITEMS -->
    <div v-for="(item, index) in [...(value || []), null]" :key="`media-${index}`"
      class="pm-group pm-relative hover:pm-bg-primary-50 pm-border pm--mt-px" :class="{
      'pm-border-primary-200 pm-z-10 pm-ring-2 pm-ring-primary-200 pm-ring-inset':
        activeIndex == index,
      'pm-border-gray-200': activeIndex !== index,
    }">
      <div class="pm-p-0.5">
        <div v-if="item" class="pm-h-16 pm-overflow-hidden">
          <img class="pm-cursor-pointer pm-w-full pm-h-full pm-object-cover pm-object-center" :src="item"
            @click="$emit('open', index)" />
        </div>
        <button v-else
          class="pm-h-16 pm-w-full pm-flex pm-items-center pm-text-center pm-text-gray-500 pm-justify-center"
          @click="$emit('open', index)">
          <Icon name="MdiPlus" />
          <span class="pm-text-xs">Add New Item</span>
        </button>
      </div>

      <button v-if="item"
        class="pm-hidden group-hover:pm-flex pm-h-6 pm-w-6 pm-rounded-full pm-items-center pm-justify-center pm-bg-danger-500 pm-text-white pm-border-2 pm-border-white pm-absolute pm-right-1 pm-top-1 pm-left-auto"
        @click="$emit('remove', index)">
        <Icon name="MdiClose" />
      </button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: Array,
    activeIndex: Number,
  }
};
</script>
