<template>
  <IuiField :label="label" :desc="desc" :disabled="isDisabled" :responsive="hasResponsive" >
    <IuiPosition
      :value="value"
      @click="input" />
  </IuiField>
</template>

<script>
import inspectorInput from "@/mixins/inspector-input";
import { IuiPosition, IuiField } from "inspector-ui";

const bgGradientValue = {
  "left-top": "tl",
  "top": "t",
  "right-top": "tr",
  "left": "l",
  "center": "c",
  "right": "r",
  "left-bottom": "bl",
  "bottom": "b",
  "right-bottom": "br",
}

export default {
  components: {
    IuiField,
    IuiPosition,
  },
  mixins: [inspectorInput],
  methods: {
    input(option) {
      let optionValue = option.value;
      if(this.prop === 'bgGradientTo') {
        optionValue = bgGradientValue[optionValue]
      }
      
      this.update(this.prop, optionValue);
    },
  },
};
</script>
