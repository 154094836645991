<template>
  <IAccordion title="Countdown" open>
    <div class="pm-space-y-3">
      <ITextbox
        label="Expiry"
        prop="expiry"
        type="datetime-local"
        :value-input="(value) => getValue(value)"
        :value-output="(value, expiry) => setValue(value, expiry)"
      />
    </div>
  </IAccordion>
</template>

<script>
export default {
  computed: {
    eod() {
      //Make sure this matches with the countdown plugin js
      const defaultDate = new Date();
      defaultDate.setHours(23, 59, 59, 999);
      return defaultDate;
    },
  },
  methods: {
    getValue(value) {
      if(value === ":::endDate:::") return ""
      const date = new Date(value || this.eod);
      const isoDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      ).toISOString();
      return isoDate.split(".")[0];
    },

    setValue(value) {
      return new Date(value).getTime();
    },
  },
};
</script>
