<template>
  <header class="inspector-header">
    <p class="">Inspector</p>
  </header>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss">
.inspector-header {
  @apply pm-flex-shrink-0 pm-flex pm-items-center pm-border-gray-200 pm-border-b pm-px-4 pm-text-xs pm-font-medium;
  height: var(--pmHeaderSize);
}
</style>
