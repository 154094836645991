<template>
  <IAccordion title="Background" prop-group="background">
    <div class="pm-space-y-3 pm-gap-3">
      <IColor prop="bg" />
      <ISlider label="Opacity" :options="$options.opacity" prop="bgOpacity" />
    </div>
  </IAccordion>
</template>

<script>
import { opacity } from "@/config/prop-values";

export default {
  opacity,
};
</script>
