<template>
  <pm-page
    :get="get"
    :window="window"
    :document="document"
    :web-font-context="webFontContext"
  >
    <template #default="{ loading }">
      <Modules v-if="!loading" />
    </template>
  </pm-page>
</template>

<script>
import { mapGetters } from "vuex";
import Modules from "@/components/editor/Modules";
import bus from "@/bus";

/**
 * https://vitejs.dev/guide/features.html#disabling-css-injection-into-the-page
 * Vite injects CSS into document when import.
 * Need to disable automatic injection of CSS here as it needs to be inside iframe.
 */
import iFrameCss from "@/styles/iframe/index.scss?inline";
import segmentsGlobal from "@pagemakerhq/segments/src/styles/global.scss";
import segments from"@pagemakerhq/segments/src/styles/segments.scss";

export default {
  components: {
    Modules,
  },

  computed: {
    ...mapGetters({
      js: "template/js",
      css: "template/css",
      fonts: "template/fonts",
      userCss: "template/userCss",
      variables: "template/variables",
      commonJs: "template/commonJs",
      colors: "template/colors",
      fonts: "template/fonts",
    }),

    document() {
      return document.querySelector("#editor-iframe").contentDocument;
    },

    window() {
      return document.querySelector("#editor-iframe").contentWindow;
    },

    webFontContext() {
      return frames["editor-iframe"];
    },
  },

  mounted() {
    this.scrollToModule();
    this.injectCustomGlobalCss();
    this.injectIframeCss();

    // Remove highlighters
    this.document.addEventListener("mouseleave", function () {
      bus.$emit("module-hover");
    });

    /**
     * Add Scroll Listener to move highlighters and actions accordingly
     */
    this.window.addEventListener("scroll", () => {
      bus.$emit("editor-scroll", {
        x: this.window.scrollX,
        y: this.window.scrollY,
      });
    });

    // if (process.env.NODE_ENV == "development") {
    //   this.loadJs("http://localhost:8098");
    // }
  },

  methods: {
    /**
     * Inject the custom user generated global CSS
     */
    injectCustomGlobalCss() {
      if (!this.userCss || this.userCss.length <= 0) return;
      this.userCss.forEach((css) => {
        this.document.head.insertAdjacentHTML(
          "beforeend",
          `<style>${css.content}</style>`
        );
      });
    },

    /**
     * Inject iframe specific css for editor
     */
    injectIframeCss() {
      this.document.head.insertAdjacentHTML(
        "beforeend",
        `<style id="pagemaker-iframe-css">${iFrameCss}</style>
        <style id="pagemaker-segment-global">${segmentsGlobal}</style>
        <style id="pagemaker-segment-css">${segments}</style>
        `
      );
    },

    scrollToModule() {
      bus.$on("segment-scroll", (data) => {
        const { moduleUuid, segmentName, segmentIndex } = data;
        let query = `div[data-uuid="${moduleUuid}"] [data-ref="${segmentName}"]`;

        if (segmentIndex != null) {
          query += `[data-ref-index="${segmentIndex}"]`;
        }

        const el = this.document.querySelector(query);
        if (el) {
          el.scrollIntoView({
            behavior: "smooth",
          });
        }
      });
    },

    get() {
      return new Promise((resolve) => {
        resolve({
          js: this.js,
          css: this.css,
          fonts: this.fonts,
          commonJs: this.commonJs,
          colors: this.colors,
          fonts: this.fonts,
          // js: [
          //   {
          //     src: "http://plugins.pagemaker.local:7774/all.js",
          //   },
          // ],
        });
      });
    },
  },
};
</script>
