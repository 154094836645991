<template>
  <div v-if="error">Oops!</div>
  <Pagemaker v-else-if="pageData" :page-data="pageData" />
  <div v-else>Loading...</div>
</template>

<script>
import bus from "@/bus";
import { init as initAxios } from "@/plugins/axios";
import { init as initApollo } from "@/plugins/apollo";
import { mapGetters } from "vuex";
import * as Sentry from "@sentry/browser";
import Pagemaker from "@/components/Pagemaker";

export default {
  data() {
    return {
      pageData: null,
      error: false,
    };
  },

  components: {
    Pagemaker,
  },

  computed: {
    ...mapGetters({
      interruptReload: "editor/interruptReload",
    }),
  },

  mounted() {
    this.error = false;
    /**
     * Send message to parent frame that the iframe is loaded
     * This will hide the loader.
     */
    this.sendMessage("ready");

    const urlSearchParams = new URLSearchParams(window.location.search);
    //passed Parameters
    const params = Object.fromEntries(urlSearchParams.entries());

    if (Object.keys(params).length <= 0) {
      //Loaded Independently
      this.error = true;
    } else {
      // Loaded from Parent Frame
      const { pageId, templateId, campaignId, workspaceId, email } = params;
      this.pageData = {
        ...params,
        id: parseInt(pageId),
        templateId: parseInt(templateId),
        campaignId: parseInt(campaignId),
        workspaceId: parseInt(workspaceId),
      };
      this.init();
      Sentry.setUser({ email });
    }
  },

  methods: {
    sendMessage(action, payload) {
      window.parent.postMessage({ action, payload }, "*");
    },

    init() {
      initAxios({
        baseUrl: import.meta.env.VITE_API,
        token: this.pageData.token,
        params: {
          workspace_id: this.pageData.workspaceId,
        },
      });

      initApollo({
        baseUrl: import.meta.env.VITE_GRAPHQL,
        token: this.pageData.token,
      });

      window.onbeforeunload = function () {
        if (this.interruptReload) {
          return confirm(
            "Any unsaved changes will be lost. Are you sure to close/reload?"
          );
        }
      };

      bus.$on("exit", (doNotConfirm) => {
        if (doNotConfirm) {
          this.sendMessage("exit");
        } else {
          this.$confirm({
            title: "Exit Editor?",
            message:
              "Make sure you have saved all the changes. If there are any unsaved changes, it will be lost.",
            okLabel: "Yes, Exit",
            okTheme: "warning",
            ok: () => {
              this.sendMessage("exit");
            },
          });
        }
      });
      bus.$on("template", () => this.sendMessage("template"));
      bus.$on("parent-reload", () => this.sendMessage("reload"));
    },
  },
};
</script>
