<template>
  <div class="highlighter">
    <div
      v-for="(item, index) in hoverList"
      v-show="!scrolling"
      :key="`hover-${index}`"
      class="highlighter__hover"
      :style="item.style"
      :data-label="item.label"
      :class="{ 'highlighter__hover--bottom': item.bottomLabel }"
    ></div>
    <div
      v-for="(item, index) in activeList"
      :key="`active-${index}`"
      class="highlighter__active"
      :style="item.style"
      :data-label="item.label"
      :class="{ 'highlighter__active--bottom': item.bottomLabel }"
    ></div>
  </div>
</template>

<script>
import { startCase } from "lodash-es";
import { mapGetters } from "vuex";
import bus from "@/bus";

export default {
  data() {
    return {
      hoverSegmentName: null,
      hoverSegmentModuleUuid: null,
      hoverSegmentIndex: null,
      scrollY: 0,
      timestamp: null,
      scrolling: false,
      timer: null,
    };
  },
  mounted() {
    bus.$on("segment-hover", (data) => {
      if (data) {
        const { segmentIndex, segmentName, moduleUuid } = data;
        this.hoverSegmentName = segmentName;
        this.hoverSegmentIndex = segmentIndex;
        this.hoverSegmentModuleUuid = moduleUuid;
      } else {
        this.hoverSegmentName = null;
        this.hoverSegmentIndex = null;
        this.hoverSegmentModuleUuid = null;
      }
    });

    bus.$on("editor-scroll", ({ y }) => {
      this.scrollY = y;
    });

    bus.$on("segment-highlight-refresh", () => {
      this.$nextTick(() => {
        this.refresh();
      });

      
    });

    window.frames["editor-iframe"]?.addEventListener("resize", () => {
      this.refresh();
    });

    window.addEventListener(
      "resize",
      () => {
        this.refresh();
      },
      true
    );

    window.frames["editor-iframe"]?.addEventListener(
      "scroll",
      () => {
        this.scrolling = true;
        if (this.timer !== null) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.scrolling = false;
          this.refresh();
        }, 200);
      },
      false
    );
  },

  computed: {
    ...mapGetters({
      breakpoint: "editor/breakpoint",
      isSegmentActive: "editor/isSegmentActive",
      activeSegmentName: "editor/activeSegmentName",
      activeSegmentIndex: "editor/activeSegmentIndex",
      activeSegmentProps: "editor/activeSegmentProps",
      activeSegmentModuleUuid: "editor/activeSegmentModuleUuid",
    }),

    activeList() {
      //Referencing this here Triggers reactivity
      this.breakpoint;
      this.scrollY;
      this.timestamp;

      if (
        !this.activeSegmentModuleUuid ||
        !this.activeSegmentName ||
        !window.frames["editor-iframe"] ||
        !this.isSegmentActive
      )
        return [];

      let query = `div[data-uuid="${this.activeSegmentModuleUuid}"] [data-ref="${this.activeSegmentName}"]`;

      /**
       * Select with index, i.e. only single element.
       * groupEdit prop defines that group editing is explicitly disabled.
       */
      if (this.activeSegmentIndex != null) {
        query += `[data-ref-index="${this.activeSegmentIndex}"]`;
      }

      let elements =
        window.frames["editor-iframe"]?.document.querySelectorAll(query);

      return Array.from(elements).map((el) => {
        const { width, height, top, left } = el.getBoundingClientRect();

        const hasLabel =
          this.activeSegmentIndex == null ||
          this.activeSegmentIndex == el.dataset.refIndex;

        return {
          style: {
            transform: `translate(${left}px,${top}px)`,
            width: width + "px",
            height: height + "px",
          },
          bottomLabel: top < 20,
          label: hasLabel ? startCase(this.activeSegmentName) : null,
        };
      });
    },

    hoverList() {
      //Referencing this here Triggers reactivity
      this.breakpoint;
      this.scrollY;
      this.timestamp;

      if (
        !this.hoverSegmentModuleUuid ||
        !this.hoverSegmentName ||
        !window.frames["editor-iframe"]
      )
        return [];

      let query = `div[data-uuid="${this.hoverSegmentModuleUuid}"] [data-ref="${this.hoverSegmentName}"]`;

      if (this.hoverSegmentIndex != null) {
        query += `[data-ref-index="${this.hoverSegmentIndex}"]`;
      }

      const elements =
        window.frames["editor-iframe"].document.querySelectorAll(query);

      return Array.from(elements).map((el) => {
        const { width, height, top, left } = el.getBoundingClientRect();

        const hasLabel =
          this.hoverSegmentIndex == null ||
          (this.hoverSegmentIndex &&
            this.hoverSegmentIndex == el.dataset.refIndex);

        return {
          style: {
            transform: `translate(${left}px,${top}px)`,
            width: width + "px",
            height: height + "px",
          },
          bottomLabel: top < 20,
          label: hasLabel ? startCase(this.hoverSegmentName) : null,
        };
      });
    },
  },

  methods: {
    refresh() {
      this.timestamp = new Date().getTime().toString();
    },

    container() {
      const parent = document.querySelector(`#editor`);
      const parentPos = parent.getBoundingClientRect();
      return {
        x: parent.scrollLeft - parentPos.left,
        y: parent.scrollTop - parentPos.top,
      };
    },
  },
};
</script>

<style lang="scss">
.highlighter {
  @apply pm-relative pm-z-10;
}
.highlighter__hover,
.highlighter__active {
  @apply pm-absolute pm-inset-0;
  box-shadow: inset 0 0 0 var(--pmHighlightSize) var(--pmHighlight);
  transform: translate(-999px, -999px);
  will-change: transform;
  pointer-events: none;
  &[data-label] {
    &:after {
      @apply pm-absolute pm-inline-block;
      content: attr(data-label);
      background-color: var(--pmHighlight);
      color: var(--pmHighlightDarkest);
      font-size: 11px;
      padding: 0px 6px;
      left: 0;
      top: -16px;
      height: 16px;
      white-space: nowrap;
      line-height: 16px;
    }
  }
  &--bottom {
    &[data-label] {
      &:after {
        top: auto;
        bottom: -16px;
      }
    }
  }
}
</style>
