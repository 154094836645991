<template>
  <div>
    <IAccordion title="Form" open>
      <div class="pm-space-y-3">
        <ITextbox label="Name" prop="name" />

        <ISelect
          prop="state"
          label="Form Status"
          :options="$options.formStates"
          desc="Change status to edit success and error messages."
        />

        <Gap />

        <!-- onSuccess -->
        <ISelect
          prop="onSuccess"
          label="On Success"
          :options="$options.successOption"
          placeholder="Show Success Message"
        />

        <!-- SuccessRedirect -->
        <ITextbox
          prop="successRedirectUrl"
          label="Success Redirect URL"
          v-if="propValueFinder('onSuccess') === 'redirect_to_url'"
        />

        <!-- onSuccess -->
        <ISelect
          label="Redirect URL target"
          placeholder="Self (Recommended)"
          :options="$options.target"
          prop="successRedirectUrlTarget"
          v-if="propValueFinder('onSuccess') === 'redirect_to_url'"
        />

        <ISelect
          placeholder="None"
          label="Integration"
          prop="integrationId"
          :request="integrationOptions"
          desc="When a user submits a form, you can trigger an integration which
          you've configured in integrations section."
        />

        <IField label="Fields" class="i-field--non-responsive">
          <div class="i-form-fields">
            <button
              class="i-form-field"
              :class="{
                'i-form-field--active':
                  fieldBeingEdited && fieldBeingEdited.name == field.name,
              }"
              v-for="(field, index) in propValueFinder('fields')"
              :key="`field-${field.name}`"
              @click="openFieldEditor(field, index)"
            >
              {{ field.name }}
            </button>
          </div>
        </IField>

        <PButton
          icon="MdiPlus"
          @click.native="
            fieldBeingEdited = null;
            $slideover.open('field-manager');
          "
          label="Add New Field"
          theme="gray"
          size="sm"
        />
      </div>
    </IAccordion>

    <FieldManager
      prop="fields"
      :field="fieldBeingEdited"
      @input="input($event)"
      @close="closeFieldEditor"
      @delete="removeField($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { target } from "@/config/prop-values";
import integrations from "@/api/integrations";
import Gap from "@/components/inspector/common/Gap";
import FieldManager from "@/components/inspector/segment/form/Field";

export default {
  target,
  successOption: [
    {
      label: "Redirect to URL",
      value: "redirect_to_url",
    },
  ],
  formStates: [
    {
      label: "Initial",
      value: "initial",
    },
    {
      label: "Success",
      value: "success",
    },
    {
      label: "Error",
      value: "error",
    },
  ],
  components: {
    Gap,
    FieldManager,
  },

  inject: ["PAGE_DATA"],

  data() {
    return {
      fieldBeingEdited: null,
    };
  },

  computed: {
    ...mapGetters({
      propValueFinder: "editor/activeSegmentPropValue",
    }),
  },

  methods: {
    closeFieldEditor() {
      this.fieldBeingEdited = null;
    },

    openFieldEditor(field, index) {
      this.fieldBeingEdited = { ...field, index };
      this.$slideover.open("field-manager");
    },

    integrationOptions() {
      return integrations(["email", "webhook"], this.PAGE_DATA.workspaceId);
    },
  },
};
</script>

<style lang="scss">
.i-form-fields {
  @apply pm-flex pm-flex-col;
}

.i-form-field {
  @apply pm--mx-3 pm-flex pm-h-7 pm-items-center pm-px-3 pm-text-xxs pm-font-medium;
  @apply hover:pm-bg-gray-100;

  &--active {
    @apply pm-bg-primary-50 pm-text-primary-700 hover:pm-bg-primary-50;
  }
}
</style>
