<template>
  <button
    class="pm-h-7 pm-flex pm-justify-center pm-items-center pm-rounded-sm pm-text-gray-400 hover:pm-text-gray-500 !pn-outline-none"
    :class="{ 'pm-bg-gray-200 pm-text-black': active }"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  props: {
    label: String,
    active: Boolean,
  },
};
</script>