<template>
  <IuiField :label="label" :desc="desc" :disabled="isDisabled" :responsive="hasResponsive">
    <IuiSelect
      :request="request"
      :options="options"
      :value="value"
      :placeholder="placeholder"
      :disabled="isLocked"
      @input="update(prop, $event)"
    />
  </IuiField>
  
</template>

<script>
import inspectorInput from "@/mixins/inspector-input";
import { IuiSelect, IuiField } from "inspector-ui"

export default {
  mixins: [inspectorInput],
  components: {
    IuiSelect,
    IuiField
  },

  props: {
    options: Array,
    request: Function,
    placeholder: String,
  },
};
</script>
