import { mapGetters } from "vuex";

export default {
  props: {
    value: String,
  },
  computed: {
    ...mapGetters({
      colors: "template/colors",
    }),

    colorCode() {
      if(this.selected?.color?.[0] === "#") {
        return this.selected?.color
      }
      if (this.colors && this.selected?.color && this.selected?.shade) {
        return this.colors[this.selected.color]?.[this.selected.shade];
      }
      return null;
    },

    selected() {
      if (!this.value) return;
      //Colors like `deep-orange-500` needs to be taken care here.
      const color = this.value.split("-");
      let colorName;
      let shade;

      switch (color.length) {
        case 3:
          colorName = color[0] + "-" + color[1];
          shade = color[2];
          break;

        case 2:
          if (this.colors[this.value]) {
            colorName = color[0] + "-" + color[1];
            shade = "500";
          } else {
            colorName = color[0];
            shade = color[1];
          }
          break;

        case 1:
          colorName = color[0];
          shade = "500";
          break;
      }
      return {
        color: colorName,
        shade,
      };
    },
  },
};
