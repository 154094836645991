<template>
  <IAccordion title="Positioning">
    <div class="pm-space-y-3">
      <ISelect label="Position" prop="position" :options="$options.position" />

      <div>
        <ISlider
          :options="$options.spacing"
          prop="top"
          icon="MdiArrowUp"
          tooltip="Top"
        />
        <ISlider
          :options="$options.spacing"
          prop="bottom"
          icon="MdiArrowDown"
          tooltip="Bottom"
        />
        <ISlider
          :options="$options.spacing"
          prop="left"
          icon="MdiArrowLeft"
          tooltip="Left"
        />
        <ISlider
          :options="$options.spacing"
          prop="right"
          icon="MdiArrowRight"
          tooltip="Right"
        />
      </div>

      <ISelect label="Z-Index" prop="zIndex" :options="$options.zIndex" />
    </div>
  </IAccordion>
</template>

<script>
import { startCase } from "lodash-es";
import theme from "@/config/tailwind-theme";
import { spacing } from "@/config/prop-values";

const position = ["static", "fixed", "absolute", "relative", "sticky"].map(
  (item) => {
    return {
      label: startCase(item),
      value: item,
    };
  }
);

const zIndex = Object.keys(theme.zIndex).map((key) => {
  return {
    label: startCase(key),
    value: key,
  };
});

export default {
  position,
  zIndex,
  spacing,
};
</script>
