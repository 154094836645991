<template>
  <IuiField
    :label="label"
    :desc="desc"
    :responsive="hasResponsive"
    :disabled="isDisabled"
  >
    <IuiTextbox
      :type="type"
      placeholder="Not Set"
      :value="value"
      @input="update(prop, $event)"
    />
  </IuiField>
</template>

<script>
import inspectorInput from "@/mixins/inspector-input";
import { IuiTextbox, IuiField } from "inspector-ui"

export default {
  components: {
    IuiTextbox,
    IuiField,
  },
  props: {
    type: {
      default: "text",
    },
  },
  mixins: [inspectorInput],
};
</script>

<style lang="scss" scoped>
input[type="datetime-local"] {
  font-size: 11px;
  &::-webkit-calendar-picker-indicator {
    margin-left: 0px;
  }
}
</style>
