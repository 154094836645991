<template>
  <div
    class="toggle"
    :class="{ 'toggle--active': value }"
    @click="$emit('input', !value)"
  >
    <div class="toggle__wrapper">
      <div class="toggle__indicator"></div>
    </div>

    <label v-if="showLabel" class="toggle__label">{{
      value ? onLabel : offLabel
    }}</label>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    onLabel: { type: String, default: "Active" },
    offLabel: { type: String, default: "Inactive" },
    showLabel: Boolean,
  },
};
</script>

<style lang="scss">
.toggle {
  --toggle--size: 16px;
  --toggle--padding: 4px;
  --toggle--border-width: 0px;
  --toggle--radius: 9999px;
  --toggle--color: theme("colors.primary.500");
  --toggle--bg: theme("colors.gray.200");
  display: inline-flex;
  align-items: center;
  vertical-align: top;
}
.toggle__label {
  @apply pm-ml-2 pm-text-xs pm-text-warning-700;
}
.toggle__wrapper {
  display: flex;
  width: calc(var(--toggle--size) * 1.6);
  height: var(--toggle--size);
  border-radius: var(--toggle--radius);
  border: var(--toggle--border-width) solid theme("colors.gray.100");
  background: var(--toggle--bg);
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease 0s;
}
.toggle__input {
  position: absolute;
  width: 0px;
  height: 0px;
  opacity: 0.01;
}
.toggle__indicator {
  width: calc(
    var(--toggle--size) - var(--toggle--padding) * 2 -
      var(--toggle--border-width) * 2
  );
  height: calc(
    var(--toggle--size) - var(--toggle--padding) * 2 -
      var(--toggle--border-width) * 2
  );
  top: var(--toggle--padding);
  left: var(--toggle--padding);
  border-radius: calc(var(--toggle--radius) / 2);
  background-color: theme("colors.gray.500");
  position: absolute;
  transition: all 0.3s ease 0s;
}

.toggle--active {
  .toggle__wrapper {
    background-color: var(--toggle--color);
  }
  .toggle__indicator {
    background-color: theme("colors.primary.100");
    left: calc(100% - var(--toggle--size) + var(--toggle--padding));
  }
  .toggle__label {
    @apply pm-text-success-700;
  }
}
</style>
