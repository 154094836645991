<template>
  <TransitionGroup tag="div" class="notifications">
    <div
      v-for="(notification, index) in notifications"
      class="notification"
      :class="`notification--${notification.type}`"
      :key="`notification-${index}`"
    >
      <p>{{ notification.message }}</p>
      <Icon
        class="notification__close"
        name="MdiClose"
        @click.native="close(notification.id)"
      />
    </div>
  </TransitionGroup>
</template>

<script>
import bus from "../bus";
import { uid } from "../utils";

export default {
  data() {
    return {
      notifications: [],
    };
  },
  created() {
    bus.$on("notify", (payload) => {
      this.push(
        Object.assign(
          {
            message: "This is notification!",
            type: "default",
            duration: 3000,
          },
          payload
        )
      );
    });
  },

  methods: {
    push(data) {
      if (data.duration) {
        data.id = uid();
        setTimeout(() => {
          this.close(data.id);
        }, data.duration);
      }
      this.notifications.push(data);
    },

    close(id) {
      const removeIndex = this.notifications.find((notification, index) => {
        if (notification.id == id) return index;
      });
      this.notifications.splice(removeIndex, 1);
    },
  },
};
</script>

<style lang="scss">
.notifications {
  @apply pm-fixed pm-top-4 pm-left-0 pm-right-0 pm-m-auto pm-w-64 pm-space-y-1;
}

.notification {
  @apply pm-p-3 pm-bg-black pm-text-white pm-rounded-md pm-text-sm pm-flex pm-font-medium;

  &.v-enter {
    opacity: 0;
  }
  &.v-enter-to {
    opacity: 1;
  }
  &.v-leave-to {
    opacity: 0;
  }
  &.v-enter-active {
    transition: all 0.3s ease 0s;
  }
  &.v-leave-active {
    transition: all 0.3s ease 0s;
  }
}

.notification__close {
  @apply pm-ml-auto pm-opacity-50 pm-cursor-pointer hover:pm-opacity-100 pm-w-5 pm-h-5 pm-flex-shrink-0;
}

.notification--default {
  @apply pm-bg-black;
}

.notification--danger {
  @apply pm-bg-danger-500;
}

.notification--success {
  @apply pm-bg-success-500;
}
</style>
