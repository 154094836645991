<template>
  <IButtonIconGroup
    label="Align Items"
    :options="optionsByDirection"
    prop="items"
  />
</template>

<script>
import { mapGetters } from "vuex";

const items = [
  {
    label: "Start",
    value: "start",
    icon: {
      x: "MdiAlignVerticalTop",
      y: "MdiAlignHorizontalLeft",
    },
  },
  {
    label: "Center",
    value: "center",
    icon: {
      x: "MdiAlignVerticalCenter",
      y: "MdiAlignHorizontalCenter",
    },
  },
  {
    label: "End",
    value: "end",
    icon: {
      x: "MdiAlignVerticalBottom",
      y: "MdiAlignHorizontalRight",
    },
  },
  {
    label: "Stretch",
    value: "stretch",
    icon: {
      x: "MdiViewColumn",
      y: "MdiViewSequential",
    },
  },
];

export default {
  computed: {
    ...mapGetters({
      props: "editor/activeSegmentProps",
      propValue: "editor/activeSegmentPropValue",
    }),

    optionsByDirection() {
      return items.map((option) => {
        const { label, value, icon } = option;
        return {
          label,
          value,
          icon: icon[this.direction],
        };
      });
    },

    direction() {
      if (["grid", "inline-grid"].includes(this.propValue("display"))) {
        return "x";
      } else {
        return ["col", "col-reverse"].includes(this.propValue("direction"))
          ? "y"
          : "x";
      }
    },
  },
};
</script>
