<template>
  <Slideover id="presets" title="Select Preset" :size="300">
    <VList
      endpoint="true"
      :per-page="48"
      :request-handler="fetchPresetsList"
      :params="{ workspaceId: PAGE_DATA.workspaceId, tag }"
    >
      <template #loading>
        <div class="presets">
          <div class="preset" v-for="n in 20" :key="`loading-${n}`">
            <div
              class="pm-h-16 pm-w-full pm-animate-pulse pm-rounded-md pm-bg-gray-200"
            ></div>
          </div>
        </div>
      </template>

      <template #default="{ items }">
        <div class="presets">
          <div
            class="preset"
            v-for="item in items"
            :key="item.id"
            @click="
              updateSegment({ props: item.props, config: { isPreset: true } })
            "
          >
            <img
              :src="item.preview"
              :style="{ '--height': item.height / 2 + 'px' }"
              :height="item.height / 2 + 'px'"
            />
          </div>
        </div>
      </template>
    </VList>
  </Slideover>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import getSegment from "@/gql/segment/get.gql";

export default {
  inject: ["PAGE_DATA"],

  computed: {
    ...mapGetters({
      tag: "editor/activeSegmentTag",
    }),
  },

  methods: {
    ...mapActions({
      updateSegment: "modules/updateSegment",
    }),

    fetchPresetsList(payload) {
      const { params } = payload;

      return this.$apollo
        .query(getSegment, {
          name: params.tag,
        })
        .then((res) => {
          return res.data.segment.presets.map((item) => {
            return {
              id: item.presetId,
              props: item.data,
              preview: item.previewImage,
              width: item.previewImageWidth,
              height: item.previewImageHeight,
              tags: item.tags.map((tag) => {
                return {
                  name: tag.name,
                  id: tag.id,
                };
              }),
            };
          });
        })
        .then((res) => {
          return {
            items: res,
            count: 1000,
          };
        });
    },
  },
};
</script>

<style lang="scss">
.presets {
  @apply pm-divide-y pm-divide-gray-200;
}

.preset {
  @apply pm-relative pm-cursor-pointer pm-p-3 hover:pm-bg-gray-100;
  img {
    height: var(--height);
  }
}
</style>
