<template>
  <div class="radio-button">
    <div class="radio-button__group">
      <PButton
        v-for="option in options"
        :label="option.label"
        :for="`radio--${option.value}`"
        :key="option.value"
        theme="gray"
        size="sm"
        :class="{ 'radio-button__active': option.value == value }"
      />
    </div>
    <input
      class="radio-button__input"
      v-for="option in options"
      :key="option.value"
      type="radio"
      :id="`radio--${option.value}`"
      :name="name"
      :value="option.value"
      :checked="option.value == value"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  props: {
    options: Array,
    name: {
      type: String,
      required: true,
    },
    value: String,
  },
};
</script>

<style lang="scss">
.radio-button__group {
  .button {
    cursor: pointer;
    @apply pm-text-gray-400;
    + .button {
      border-left: none;
    }
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not(:first-child, :last-child) {
      border-radius: 0;
    }
    &.radio-button__active {
      @apply pm-bg-gray-200 pm-text-gray-500;
    }
  }
}

.radio-button__input {
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
}
</style>
