<template>
  <IAccordion title="Shopify Product Input" open>
    <ISelect
      label="Type"
      prop="inputType"
      placeholder="Select Input Type"
      :options="$options.type"
    />
  </IAccordion>
</template>
<script>
import { startCase } from "lodash-es";

const type = ["select", "radio"].map((item) => {
  return {
    label: startCase(item),
    value: item,
  };
});

export default {
  type,
  inject: ["PAGE_DATA"],
  data() {
    return {};
  },
};
</script>
