<template>
  <div
    class="media-drop"
    :class="{ 'media-drop--active': active }"
    @dragenter.prevent="active = true"
    @dragover.prevent="active = true"
  >
    <!-- Upload Overlay -->
    <div
      v-show="active"
      @drop.prevent="drop"
      @dragenter.prevent
      @dragover.prevent
      @dragleave.prevent="active = false"
      class="media-drop__overlay"
    >
      <div class="pm-mt-8 pm-flex pm-flex-col pm-items-center pm-text-center">
        <Icon
          name="MdiCloudUpload"
          class="pm-h-12 pm-w-12 pm-animate-bounce pm-text-white"
        />
        <p class="pm-text-center pm-font-bold pm-leading-none pm-text-white">
          Upload Files
        </p>
      </div>
    </div>

    <Upload @select="upload($event)" />
    <Gallery
      :value="value"
      :prepend="uploads"
      @updatePrepend="uploads = $event"
      @select="$emit('input', { value: $event, source: 'internal' })"
    />
  </div>
</template>

<script>
import Gallery from "./Gallery";
import Upload from "./Upload";
import upload from "@/gql/media/upsert.gql";

const acceptFileTypes = [
  "image/jpeg",
  "image/webp",
  "image/jpg",
  "image/png",
  "image/svg",
  "image/svg+xml",
  "image/gif",
];

export default {
  props: {
    value: Array,
  },
  components: {
    Gallery,
    Upload,
  },
  data() {
    return {
      active: false,
      uploads: [],
    };
  },
  inject: ["PAGE_DATA"],
  methods: {
    drop(e) {
      this.active = false;
      this.upload(e.dataTransfer.files);
    },
    upload(items) {
      for(let index = 0; index < items.length; index++) {
        let item = items[index];
        if (!acceptFileTypes.includes(item.type)) return;
        const { name, size, type } = item;
        const url = URL.createObjectURL(item);
        this.uploads.unshift({
          id: new Date() + "--" + index, //This is temp id for using as key
          name,
          size,
          type,
          url,
          status: "uploading",
        });

        this.$apolloUpload
          .mutate(upload, {
            workspaceId: this.PAGE_DATA.workspaceId,
            input: {
              privacy: "public",
              type: "file",
              files: [item],
            },
          })
          .then((res) => res.data.upsertMedia[0])
          .then((res) => {
            return {
              id: res.id,
              name: res.name,
              type: res.mimetype,
              width: res.width,
              height: res.height,
              url: res.url,
              size: res.size,
            };
          })
          .then((res) => {
            this.$set(this.uploads, index, res);
          })
          .catch((err) => {
            this.$sentry.catch(err);
            this.uploads[index].status = "error";
          });
      }
    },
  },
};
</script>

<style lang="scss">
.media-drop {
  @apply pm-relative;
}
.media-drop__overlay {
  @apply pm-absolute pm-inset-0 pm-z-20 pm-flex pm-justify-center pm-bg-black pm-bg-opacity-80;
}
</style>
