<template>
  <div
    class="
      pm-inline-flex pm-divide-x pm-divide-gray-200 pm-flex-grow-0 pm-h-full
    "
  >
    <TheButton
      v-tooltip="tooltip(panes ? 'Hide Panes' : 'Show Panes')"
      :icon="panes ? 'MdiArrowSplitVertical' : 'MdiArrowCollapseHorizontal'"
      @click.native="togglePanes"
    />
    <TheButton
      v-tooltip="'Undo'"
      :disabled="!canUndo"
      icon="MdiUndo"
      @click.native="applyHistory('undo')"
    />
    <TheButton
      v-tooltip="'Redo'"
      :disabled="!canRedo"
      icon="MdiRedo"
      @click.native="applyHistory('redo')"
    />
    <div v-if="!isUnsaved"></div>
    <div v-if="isUnsaved" class="status status--unsaved">
      <Icon class="status__icon" name="MdiCircle" />
      <p class="status__label">Unsaved</p>
    </div>
  </div>
</template>

<script>
import bus from "@/bus";
import tinykeys from "tinykeys";
import { mapGetters, mapMutations } from "vuex";
import TheButton from "./Button";

export default {
  components: {
    TheButton,
  },
  data() {
    return {
      panes: true,
    };
  },
  computed: {
    ...mapGetters({
      isUnsaved: "editor/isUnsaved",
      canUndo: "modules/canUndo",
      canRedo: "modules/canRedo",
    }),
  },
  mounted() {
    tinykeys(window, {
      "$mod+\\": () => {
        this.togglePanes();
      },
      "$mod+z": (e) => {
        e.preventDefault();
        if (this.canUndo) {
          this.applyHistory("undo");
        }
      },
      "$mod+y": (e) => {
        e.preventDefault();
        if (this.canRedo) {
          this.applyHistory("redo");
        }
      },
    });
  },
  methods: {
    ...mapMutations({
      applyHistory: "modules/applyHistory",
    }),
    togglePanes() {
      this.panes = !this.panes;
      bus.$emit("panes-toggle");
    },
    tooltip(content) {
      return {
        content,
        delay: {
          show: 1000,
        },
      };
    },
  },
};
</script>

<style lang="scss">
.status {
  @apply pm-flex pm-items-center  pm-h-full pm-px-3 pm-text-gray-500;
}
.status__icon {
  @apply pm-w-4 pm-h-4;
}
.status__label {
  @apply pm-text-xs pm-ml-1;
}

.status--unsaved {
  @apply pm-text-warning-500;
}
</style>
