<template>
  <div class="pm-border-b pm-border-gray-200">
    <!-- TOGGLE -->
    <div class="pm-group pm-flex pm-h-10 pm-items-stretch hover:pm-bg-gray-50">
      <!-- INDEX -->
      <i
        class="pm-flex pm-w-8 pm-items-center pm-justify-center pm-font-mono pm-text-xl pm-not-italic pm-leading-none"
        :class="{
          'pm-text-primary-100': isActive,
          'pm-text-gray-200 group-hover:pm-text-gray-300': !isActive,
        }"
      >
        {{ index }}
      </i>

      <!-- NAME / TAGS -->
      <button
        class="pm-flex pm-grow pm-flex-col pm-items-start pm-justify-center"
        @click="toggle()"
      >
        <label
          class="pm-cursor-pointer pm-text-xs pm-font-medium pm-leading-none"
          :class="{
            'pm-text-primary-500': isActive,
            'pm-text-gray-900 ': !isActive,
          }"
        >
          {{ label }}
        </label>
        <p
          v-if="desc"
          class="pm-mt-1 pm-text-xxs pm-font-medium pm-leading-none pm-text-gray-500 pm-text-left"
        >
          {{ desc }}
        </p>
      </button>

      <!-- SETTINGS -->
      <button
        class="pm-text pm-flex pm-w-8 pm-items-center pm-justify-center pm-text-gray-400 hover:pm-text-gray-600"
        :class="{ 'pm-invisible group-hover:pm-visible': !isSettingsActive }"
        @click="$emit('settings')"
      >
        <Icon name="MdiCog" />
      </button>
    </div>

    <!-- CONTENT -->
    <div v-if="isOpen">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    desc: String,
    index: Number,
    label: String,
    open: Boolean,
    uuid: String,
    isSettingsActive: Boolean,
  },
  data() {
    return {
      isOpen: this.open,
    };
  },

  computed: {
    ...mapState({
      activeSegmentModuleUuid: (state) => state.editor.activeSegmentModuleUuid,
    }),

    isActive() {
      return this.activeSegmentModuleUuid == this.uuid;
    },
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
