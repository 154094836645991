<template>
  <IAccordion title="Opacity">
    <ISlider :options="$options.opacity" prop="opacity" />
  </IAccordion>
</template>

<script>
import { opacity } from "@/config/prop-values";

export default {
  opacity,
};
</script>
