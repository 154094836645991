/**
 * locked
 * lockedGroups:
 * This defines the props to be disalbed by Editor.
 * We can also define props to disable dt segment level by adding locked & lockedGroup props
 * The reason to add it here is we can controll default locked props from here
 * While segment level settings are saved permenently in Module Segments.
 *
 */

export default {
  "pm-alert": {
    icon: "MdiAlert",
  },
  "pm-menu": {
    icon: "MdiMenu",
  },
  "pm-container": {
    icon: "MdiMonitorScreenshot",
  },
  "pm-text": {
    icon: "MdiFormatText",
  },
  "pm-grid": {
    icon: "MdiGridLarge",
  },
  "pm-grid-item": {
    icon: "MdiSquareMediumOutline",
  },
  "pm-row": {
    icon: "MdiTableRow",
  },
  "pm-media": {
    icon: "MdiImage",
    lockedGroups: ["background", "background-image"],
  },
  "pm-media-group": {
    icon: "MdiImage",
    lockedGroups: ["background", "background-image"],
  },
  "pm-button": {
    icon: "MdiCursorDefaultClick",
  },
  "pm-button-group": {
    icon: "MdiCursorDefaultClick",
  },
  "pm-wrapper": {
    icon: "MdiCardOutline",
  },
  "pm-repeater": {
    icon: "MdiRepeat",
  },
  "pm-countdown": {
    icon: "MdiTimer",
  },
  "pm-gallery": {
    icon: "MdiCameraBurst",
    lockedGroups: ["link"],
  },
  "pm-rating": {
    icon: "MdiStar",
  },
  "pm-field": {
    icon: "MdiFormTextbox",
  },
  "pm-form": {
    icon: "MdiFormSelect",
    lockedGroups: ["link"],
  },
  "pm-icon": {
    icon: "MdiStarFourPoints",
  },
  "pm-modal": {
    icon: "MdiOverscan",
    lockedGroups: ["link"],
  },
  "pm-code": {
    icon: "MdiCodeTags",
  },
  "pm-custom": {
    icon: "MdiCodeTags",
  },
  "pm-accordion": {
    icon: "MdiChevronDownBox",
  },
  "pm-shopify-product": {
    icon: "MdiShopify",
  },
  "pm-shopify-product-variant": {
    icon: "MdiPaletteSwatchVariant",
  },
  "pm-shopify-product-attribute": {
    icon: "MdiArrowDownDropCircleOutline",
  },
  "pm-select": {
    icon: "MdiArrowDownDropCircleOutline",
  },
  "pm-carousel": {
    icon: "MdiViewCarousel",
  },
  "pm-carousel-items": {
    icon: "MdiRepeat",
  },
  "pm-carousel-item": {
    icon: "MdiCardOutline",
  },
  "pm-carousel-prev": {
    icon: "MdiCursorDefaultClick",
  },
  "pm-carousel-next": {
    icon: "MdiCursorDefaultClick",
  },
  "pm-number-input": {
    icon: "MdiNumeric",
  },
  "pm-input": {
    icon: "MdiFormTextbox",
  },
};
