<template>
  <IuiField :label="label" :disabled="isDisabled" :responsive="hasResponsive">
    <IuiCode
      :class="inputClassList"
      :close="close"
      @fullscreen="fullscreen"
      @cancel="cancel()"
      @save="save()"
      @open="valueInModal = value"
    >
      <template #pcCode>
        <PCode :value="value" @input="update('value', $event)" language="html" />
      </template>
  
      <template #pcCodeFull>
        <PCode full-height language="html" v-model="valueInModal" />
      </template>
    </IuiCode>
  </IuiField>
</template>

<script>
import inspectorInput from "@/mixins/inspector-input";
import bus from "@/bus";
import { IuiCode, IuiField } from "inspector-ui"

export default {
  components: {
    IuiCode,
    IuiField,
  },
  mixins: [inspectorInput],
  data() {
    return {
      valueInModal: this.value,
    };
  },
  methods: {
    fullscreen() {
      bus.$emit("modal-open", "code-modal");
    },
    save() {
      this.update("value", this.valueInModal);
      bus.$emit("modal-close", "code-modal");
    },
    cancel() {
      bus.$emit("modal-close", "code-modal");
    },
  },
};
</script>
