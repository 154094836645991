<template>
  <div class="pagemaker-loader">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 229.27 152.84"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="3.28"
          y1="93.44"
          x2="181.15"
          y2="-9.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#f0f" />
          <stop offset="0.17" stop-color="#c600ff" />
          <stop offset="0.4" stop-color="#8000ff" />
          <stop offset="0.6" stop-color="#4900ff" />
          <stop offset="0.78" stop-color="#2100ff" />
          <stop offset="0.92" stop-color="#0900ff" />
          <stop offset="1" stop-color="blue" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="36.85"
          y1="172.54"
          x2="233.81"
          y2="58.83"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#f0f" />
          <stop offset="0.17" stop-color="#ff00c6" />
          <stop offset="0.4" stop-color="#fe0080" />
          <stop offset="0.6" stop-color="#fe0049" />
          <stop offset="0.78" stop-color="#fd0021" />
          <stop offset="0.92" stop-color="#fd0009" />
          <stop offset="1" stop-color="#fd0000" />
        </linearGradient>
      </defs>
      <polygon
        class="top"
        points="76.42 0 0 101.9 63.69 101.9 101.9 50.95 165.58 50.95 203.79 0 76.42 0"
        style="fill:url(#linear-gradient)"
      />
      <polygon
        class="bottom"
        points="165.58 50.95 127.37 101.9 95.53 101.9 76.42 127.37 44.58 127.37 25.47 152.84 152.84 152.84 229.27 50.95 165.58 50.95"
        style="fill:url(#linear-gradient-2)"
      />
    </svg>

    <div v-if="message" class="pagemaker-loader__message">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
  },
};
</script>

<style lang="scss">
.pagemaker-loader {
  @apply pm-flex pm-items-center pm-justify-center pm-fixed pm-inset-0 pm-z-50 pm-bg-white pm-flex-col;

  polygon {
    animation-name: heartbeat;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    transform-origin: center;
    animation-fill-mode: ease-in;
    &.top {
      animation-delay: 0.5s;
    }
    &.bottom {
      animation-delay: 0s;
    }
  }

  svg {
    @apply pm-w-24 pm-h-24;
    overflow: visible;
  }
}

.pagemaker-loader__message {
  @apply pm-text-center pm-text-gray-500 pm-text-xs pm-mt-4;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
