import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import editor from "./modules/editor";
import modules from "./modules/modules";
import template from "./modules/template";

const store = new Vuex.Store({
  modules: {
    editor,
    modules,
    template,
  },
});

export default store;
