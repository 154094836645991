<template>
  <div>
    <template v-for="module in modules">
      <!-- 
        Using v-show=false here to avoid re-rendering of DOM while the module is deleted.
        This improves the performance.
      -->
      <Module
        v-show="module.action !== 'remove'"
        :ref="module.uuid"
        :key="module.uuid"
        :uuid="module.uuid"
        :name="module.name"
        :theme="module.theme"
        :id="module.originalModuleId"
        :by="module.by"
        :status="moduleStatus(module)"
        :segments="segments[module.uuid]"
        @mouseenter.native="hover(module.uuid)"
        @mouseleave.native="hoverOut"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import bus from "@/bus";
import save from "@/gql/page/save.gql";
import Module from "./Module";

export default {
  components: {
    Module,
  },
  computed: {
    ...mapGetters({
      segments: "modules/segments",
      modules: "modules/modules",
      visibleModules: "modules/visibleModules",
      modules: "modules/modules",
      loading: "modules/loading",
    }),
  },

  inject: ["PAGE_DATA"],

  mounted() {
    bus.$on("save", (publish) => this.save(publish));
  },

  methods: {
    ...mapMutations({
      setUnsaved: "editor/setUnsaved",
    }),

    /**
     * Wait till all the modules are loaded.
     */
    moduleStatus(module) {
      return this.loading ? "loading" : module.status;
    },

    /**
     * Loops through all the modules to append the HTML and sends to parent window.
     */
    save(publish) {
      /**
       * Remove the modules which are marked as `remove`
       * and not saved server side by checking if id is generated.
       *
       * If module is marked as `remove`
       * and has the id which means it should be sent to server to remove.
       */
      const modules = this.modules
        .filter((module) => !(module.action == "remove" && !module.id))
        .map((module) => {
          if (module.action == "remove") {
            module.segments = [];
          } else {
            const ref = this.$refs[module.uuid][0];
            module.html = ref.generateHTML();
            module.segments = this.segments[module.uuid];
          }
          return module;
        })
        .map((module) => {
          const {
            id,
            originalModuleId,
            moduleId,
            segments,
            action,
            html,
            uuid,
            name,
            version,
          } = module;

          return {
            id,
            module_id: moduleId,
            original_module_id: originalModuleId,
            segments: JSON.stringify(segments),
            action,
            html,
            uuid,
            name,
            version,
          };
        });

      const { id, templateId, campaignId, workspaceId } = this.PAGE_DATA;
      this.$apollo
        .mutate(save, {
          id,
          templateId,
          campaignId,
          workspaceId,
          input: {
            modules,
            doPublish: publish,
          },
        })
        .then((res) => {
          bus.$emit("save:end");
          this.setUnsaved(false);
          this.$notify({
            message: publish ? "Published" : "Saved",
            type: "success",
          });
        })
        .catch((err) => {
          this.$sentry.catch(err);
          this.$notify({
            message: "There was an error! Please refresh the page & try again.",
            type: "danger",
            duration: 0,
          });
          console.error(err);
        });
    },

    hover(uuid) {
      bus.$emit("module-hover", uuid);
    },

    hoverOut() {
      bus.$emit("module-hover");
    },
  },
};
</script>
