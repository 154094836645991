<template>
  <div class="module-type">
    <Icon class="module-type__icon" :name="icon" />
    <h5 class="module-type__label">
      {{ label }}
    </h5>
    <p class="module-type__desc">{{ desc }}</p>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    label: String,
    desc: String,
  },
};
</script>

<style lang="scss">
.module-type {
  @apply pm-p-8 pm-bg-white pm-text-center pm-cursor-pointer hover:pm-bg-gray-100;

  &:hover {
    .module-type__icon {
      @apply pm-text-primary-500;
    }
  }
}
.module-type__icon {
  @apply pm-block pm-w-12 pm-h-12 pm-m-auto pm-text-gray-400;
}
.module-type__label {
  @apply pm-mt-1 pm-font-bold;
}
.module-type__desc {
  @apply pm-text-xs pm-text-gray-500;
}
</style>
