<template>
  <IAccordion title="Block">
    <div class="pm-space-y-3">
      <ISelect
        label="Display"
        prop="display"
        :options="$options.display"
        :disabled="displayDisabled"
      />

      <TextAlign />

      <!-- Flex -->
      <template v-if="isFlex">
        <IButtonIconGroup
          label="Direction"
          :options="$options.direction"
          prop="direction"
        />

        <AlignItems />

        <ISelect
          label="Justify Content"
          :options="$options.justify"
          prop="justify"
        />
      </template>

      <!-- Overflow -->
      <ISelect :options="$options.overflow" label="Overflow" prop="overflow" />
    </div>
  </IAccordion>
</template>

<script>
import { mapGetters } from "vuex";
import { startCase } from "lodash-es";
import TextAlign from "@/components/inspector/common/TextAlign";
import AlignItems from "@/components/inspector/common/AlignItems";

const display = [
  "block",
  "inline-block",
  "inline",
  "flex",
  "inline-flex",
  "grid",
  "inline-grid",
  "hidden",
].map((item) => {
  return {
    label: startCase(item),
    value: item,
  };
});

const direction = [
  {
    label: "row",
    value: "row",
    icon: "MdiArrowRight",
  },
  {
    label: "row-reverse",
    value: "row-reverse",
    icon: "MdiArrowLeft",
  },
  {
    label: "col",
    value: "col",
    icon: "MdiArrowDown",
  },
  {
    label: "col-reverse",
    value: "col-reverse",
    icon: "MdiArrowUp",
  },
];

const justify = ["start", "end", "center", "between", "around", "evenly"].map(
  (item) => {
    return {
      label: startCase(item),
      value: item,
    };
  }
);

const overflow = ["hidden", "visible", "scroll", "auto"].map((item) => {
  return {
    label: startCase(item),
    value: item,
  };
});

export default {
  direction,
  display,
  justify,
  overflow,

  components: {
    TextAlign,
    AlignItems,
  },

  computed: {
    ...mapGetters({
      propValue: "editor/activeSegmentPropValue",
    }),

    displayDisabled() {
      return [
        ["pm-grid", "pm-row"].includes(this.tag),

        //If media & ratio is defined
        (this.tag == "pm-media" || this.tag == "pm-media-group") &&
          (this.propValue("ratioW") || this.propValue("ratioH")),
      ].some((item) => item);
    },

    isFlex() {
      return ["flex", "inline-flex", "grid", "inline-grid"].includes(
        this.propValue("display")
      );
    },
  },
};
</script>
