<template>
  <IuiField
    :label="label"
    :desc="desc"
    :responsive="hasResponsive"
    :disabled="isDisabled"
  >
    <IuiTextarea
      class="pm-text-xxs"
      :value="value"
      @input="update(prop, $event)"
    />
</IuiField>
</template>

<script>
import inspectorInput from "@/mixins/inspector-input";
import { IuiField, IuiTextarea } from "inspector-ui"

export default {
  name: "Textarea",
  components: {
    IuiTextarea,
    IuiField
  },
  mixins: [inspectorInput],
};
</script>
