import { mapKeys, cloneDeep, clone } from "lodash-es";
import { getPropVariantConfigByTag } from "@pagemakerhq/segments/src-utils/index";

import { v4 as uuidv4 } from "uuid";
import bus from "../../bus";
import get from "@/gql/module/get.gql";

const state = {
  modules: [],
  segments: {},
  history: [],
  layers: {},
  redoHistory: [null],
  historyPointer: 0,
};

const getters = {
  history(state) {
    return state.history;
  },

  historyPointer(state) {
    return state.historyPointer;
  },

  loading(state) {
    return state.modules.reduce((acc, module) => {
      return module.status == "loading";
    }, false);
  },

  modules(state) {
    return state.modules;
  },

  order: (state) => (uuid) => {
    return state.modules.findIndex((module) => module.uuid == uuid);
  },

  getByUuid: (state) => (uuid) => {
    return state.modules.find((module) => module.uuid == uuid);
  },

  visibleModules(state, getters) {
    return getters.modules.filter((item) => {
      return item.action != "remove";
    });
  },

  visibleModulesCount(state, getters) {
    return getters.visibleModules.length;
  },

  isEmpty(state, getters) {
    return getters.visibleModules.length == 0;
  },

  segments(state) {
    return state.segments;
  },

  segment: (state, getters) => (uuid, name) => {
    return getters.segments[uuid]?.find((item) => item.name == name) || [];
  },

  canUndo(state) {
    return state.historyPointer > 0;
  },

  canRedo(state) {
    return state.historyPointer <= state.history.length - 1;
  },
};

const mutations = {
  set(state, modules) {
    state.modules = modules.map((item) => item.data);

    /**
     * Use for loop here for the synchronus data update.
     */
    for (var i = 0; i < modules.length; i++) {
      const module = modules[i];
      const segments = prepareSegments(module.segments, this);
      this._vm.$set(state.segments, module.data.uuid, segments);
    }
  },

  /**
   * This method is called after the "Save" action is done for template.
   * After saving, the template, we'll get updated list of modules.
   * We just update module information and keep segment data as-it-is
   * Hence, the status and action is manually updated.
   */
  update(state, modules) {
    state.modules = modules.map((item) => {
      item.data.status = "success";
      item.data.action = "keep";
      return item.data;
    });
  },

  insert(state, { uuid, module, order }) {
    const { name, by, tags, version, id, alias, category, moduleId } = module;
    const moduleData = {
      action: "add",
      moduleId,
      uuid,
      name,
      alias,
      by,
      category,
      originalModuleId: id,
      tags,
      version,
      status: "loading",
    };

    state.modules.splice(order, 0, moduleData);
  },

  updateSegment(state, data) {
    let {
      moduleUuid,
      segmentName,
      segmentIndex,
      props,
      modifiers,
      tag,
      isPreset,
    } = data;

    const segments = state.segments[moduleUuid];

    if (!segments) {
      const error = "Segments not found for module: " + moduleUuid;
      this._vm.$sentry.catch(new Error(error));
      console.error(error);
      return;
    }

    let segment = segments.find((item) => item.name == segmentName);

    if (!segment) {
      const error = "Segment Item not found for module: " + moduleUuid;
      this._vm.$sentry.catch(new Error(error));
      console.error(error);
      return;
    }

    let target;

    /**
     * If a group editing is being done or a segment is not defined,
     * We need to use props as it will apply to group as well as individual.
     *
     * When preset is true we remove all the old props
     */
    if (segmentIndex == null) {
      if (!segment.props || isPreset) {
        this._vm.$set(segment, "props", {});
      }
      target = segment.props;
    } else {
      if (!segment.propsByIndex) {
        this._vm.$set(segment, "propsByIndex", []);
      }
      if (!segment.propsByIndex[segmentIndex] || isPreset) {
        this._vm.$set(segment.propsByIndex, segmentIndex, {});
      }

      target = segment.propsByIndex[segmentIndex];
    }

    /**
     * Convert props to variant props with the help of modifiers:
     *
     * For Example, if prop is "color" and breakpoint is set to "sm", the output should be "sm:color"
     *
     * If preset is provided, no need to find prop variants.
     * as it has already variant based props
     */
    if (!isPreset) {
      props = mapKeys(props, (value, prop) => {
        const variants = getPropVariantConfigByTag(tag, prop);
        const isResponsive = variants.includes("responsive");
        const { breakpoint, hover } = modifiers;

        let variantProp = "";

        if (isResponsive && breakpoint && breakpoint != "base") {
          variantProp += breakpoint;
        }

        if (hover) {
          if (variantProp != "") {
            variantProp += ":";
          }
          variantProp += "hover";
        }

        if (variantProp) {
          return variantProp + ":" + prop;
        } else {
          return prop;
        }
      });
    }

    for (let prop in props) {
      this._vm.$set(target, prop, props[prop]);
    }

    /**
     * When a group editing is being done,
     * Need to remove all the keys that are being edited in group from individual segments
     */
    if (segmentIndex == null) {
      if (segment.propsByIndex) {
        segment.propsByIndex.forEach((propsAtIndex) => {
          if (propsAtIndex) {
            for (let prop in props) {
              this._vm.$delete(propsAtIndex, prop);
            }
          }
        });
      }
    }

    /**
     * When an individual editing is being done,
     * we need to remove that segment from common and place that at individual level.
     */
    if (segmentIndex != null) {
      /**
       * Copy the values from Group Level
       * To Individual Level
       */
      segment.propsByIndex.forEach((propsAtIndex, index) => {
        /**
         * TODO:
         * propsByIndex represents array size which may not be representing actual number of refIndexes available.
         * This may remove some customizations when props -> propsByIndex migration
         */

        //Need to skip the segmentIndex because that is already updated in above process
        if (propsAtIndex && index != segmentIndex) {
          for (let prop in props) {
            // The content and media value(src) prop should be always at index level so skipping.
            if (prop !== "content" && (segment.tag == "pm-media" && prop !== "value")) {
              // Copying value from group, not from the input
              if (Object.prototype.hasOwnProperty.call(segment.props, prop)) {
                this._vm.$set(propsAtIndex, prop, segment.props[prop]);
              }
            }
          }
        }
      });

      /**
       * Remove values from group level
       */
      for (let prop in props) {
        this._vm.$delete(segment.props, prop);
      }
    }
  },

  init(state, { uuid, segments, status }) {
    const module = state.modules.find((item) => item.uuid == uuid);
    if (module) {
      module.status = status;
    }
    if (module && segments) {
      this._vm.$set(state.segments, uuid, segments);
    }
  },

  setLayers(state, [uuid, layers]) {
    this._vm.$set(state.layers, uuid, layers);
  },

  remove(state, uuid) {
    delete state.segments[uuid];
    state.modules.find((item) => item.uuid == uuid).action = "remove";
  },

  sort(state, { currentOrder, newOrder }) {
    state.modules.splice(newOrder, 0, state.modules.splice(currentOrder, 1)[0]);
  },

  addHistory(state, { type, segmentData }) {
    try {
      let segment;
      if (segmentData) {
        const { moduleUuid, segmentName } = segmentData;
        const module = state.modules.find(
          (module) => module.uuid == moduleUuid
        );
        segment = state.segments[module.uuid].find(
          (segment) => segment.name == segmentName
        );
      }

      const target = type == "undo" ? state.history : state.redoHistory;

      if (state.historyPointer < target.length) {
        target.splice(state.historyPointer, target.length);
      }

      target.push(
        Object.freeze({
          modules: cloneDeep(state.modules),
          moduleUuid: segmentData?.moduleUuid,
          segment: segment && cloneDeep(segment),
        })
      );

      if (target.length >= 20) {
        target.shift();
      } else {
        state.historyPointer = state.history.length;
      }
    } catch (err) {
      this._vm.$sentry.catch(err);
      console.error(err);
    }
  },

  applyHistory(state, userAction) {
    let target;
    try {
      if (userAction == "undo") {
        state.historyPointer--;
        target = state.history;
      } else if (userAction == "redo") {
        state.historyPointer++;
        target = state.redoHistory;
      }

      const history = target[state.historyPointer];
      const { moduleUuid, segment, modules } = history;
      state.modules = cloneDeep(modules);

      if (moduleUuid && segment) {
        let targetIndex = state.segments[moduleUuid].findIndex(
          (item) => item.name == segment.name
        );
        this._vm.$set(
          state.segments[moduleUuid],
          targetIndex,
          cloneDeep(segment)
        );
      }
    } catch (err) {
      this._vm.$sentry.catch(err);
      console.error(err);
    }
  },
};

const actions = {
  remove({ commit }, uuid) {
    commit("addHistory", { type: "undo" });
    commit("remove", uuid);
    commit("editor/setUnsaved", true, { root: true });
    commit("editor/setActiveSegment", true, { root: true });
    commit("addHistory", { type: "redo" });
  },

  sort({ commit }, data) {
    commit("addHistory", { type: "undo" });
    commit("sort", data);
    commit("editor/setUnsaved", true, { root: true });
    commit("addHistory", { type: "redo" });
  },

  async insert({ commit }, { id, order, name}) {
    commit("addHistory", { type: "undo" });
    commit("editor/setLoading", true, { root: true });
    const module = await this._vm.$apollo
      .query(get, {
        id,
        name
      })
      .then((res) => {
        const { source, id, tags, name, version, category, uuid } =
          res.data.module;
        return {
          uuid,
          name,
          by: source,
          id,
          version,
          category,
          tags: tags.map((tag) => tag.name),
        };
      });

    let uuid;
    if (module.category === "user-global") {
      uuid = module.uuid || uuidv4();
    } else {
      uuid = uuidv4();
    }

    await commit("insert", { uuid, module, order });
    commit("editor/setLoading", false, { root: true });
    commit("editor/setUnsaved", true, { root: true });
    commit("addHistory", { type: "redo" });
  },

  updateSegment({ commit, rootGetters }, { props, config = {} }) {
    /**
     * NOTE:
     * We need to clone the non primitive default values
     * or else if we change those in later steps, the default values will be updated!
     */
    const defaultConfig = {
      moduleUuid: rootGetters["editor/activeSegmentModuleUuid"],
      segmentName: rootGetters["editor/activeSegmentName"],
      segmentIndex: rootGetters["editor/activeSegmentIndex"],
      tag: rootGetters["editor/activeSegmentTag"],
      segmentProps: cloneDeep(rootGetters["editor/activeSegmentProps"] || {}), //old props
      modifiers: cloneDeep(rootGetters["editor/modifiers"] || {}),
      isPreset: false,
    };

    const mergedConfig = {
      ...defaultConfig,
      ...config,
    };

    const { isPreset, segmentIndex, moduleUuid, tag, modifiers } = mergedConfig;

    let { segmentName } = mergedConfig;

    /**
     * Content updation should always be individual.
     * This is a case where pre-defined content is selected from editor.
     * For Amazon Pages, we can select predefined content from editor
     * and hence content passes from editor -> iframe.
     * In normal case content is reverse, it comes from iframe -> editor
     * due to inline editing.
     */
    if (props?.hasOwnProperty("isHidden")) {
      modifiers.responsive = false;
      modifiers.breakpoint = null;
      modifiers.hover = false;
    } else if (props?.hasOwnProperty("content")) {
      modifiers.responsive = false;
      modifiers.breakpoint = null;
      modifiers.hover = false;
    }

    const payload = {
      moduleUuid, //an Index of module is needed to determine which module to update
      segmentName, //After index, we need to identify a segment by name (ref of an element)
      segmentIndex, //If a segment is in for-loop, an Index of element
      modifiers,
      props,
      tag,
      isPreset, //When a preset is selected, we need to remove all the old props,
    };

    commit("addHistory", {
      type: "undo",
      segmentData: {
        moduleUuid,
        segmentName,
      },
    });

    commit("updateSegment", payload);

    commit("addHistory", {
      type: "redo",
      segmentData: {
        moduleUuid,
        segmentName,
      },
    });

    commit("editor/setUnsaved", true, {
      root: true,
    });

    bus.$emit("segment-highlight-refresh");
    if (config.refreshLayers) {
      bus.$emit("refresh-active-module-layers");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

/**
 * Use this function to prepare segments
 * for backward compatibility and other modifications.
 */
const prepareSegments = (segments, self) => {
  //TODO
  // This is a hack to prevent an error when segments are null MISTAKENLY from API.
  if (!segments) {
    const error = "Segments not available in API!";
    self._vm.$sentry.catch(new Error(error));
    return [];
  }

  return cloneDeep(segments).map((segment) => {
    const { props } = segment;

    /**
     * Props: link & linkTo
     *
     * Initially "linkTo" prop was not set and users can directly provide the URL of any page they want.
     * With the release of multi-pages feature, the "linkTo" prop was introduced.
     * When the URL needs to be the other page of website, the "link" prop will be set by pagemaker.
     * The user can not update "link" prop manually in this case.
     * To make it backward compatible, we need to check the URL set by user if it contains the "http"
     * In this case, the "linkTo" prop will be automatically set to "otherPage"
     * If linkTo value is already set, return as it is.
     * Links added after linkTo properties will have this value
     */
    if (!props?.linkTo && (typeof props?.link === 'string' && props?.link?.trim().startsWith("http"))) {
      segment.props.linkTo = "url";
    }

    return segment;
  });
};
