<template>
  <IAccordion title="Button" open prop-group="button">
    <div class="pm-space-y-3">
      <IField
        label="On Click"
        desc="To add a URL to button, use 'Link' section."
      >
        <ISelect
          prop="onClick"
          :side-effect="(props) => onClickSideEffect(props)"
          :options="options"
        />
      </IField>

      <ISelect
        v-if="propValueFinder('onClick') === 'integration'"
        placeholder="None"
        label="Integration"
        prop="integrationId"
        :request="integrationOptions"
        desc="Run an integration on click of a button."
      />
    </div>
  </IAccordion>
</template>

<script>
import { mapGetters } from "vuex";
import integrations from "@/api/integrations";

export default {
  inject: ["PAGE_DATA"],

  computed: {
    ...mapGetters({
      propValueFinder: "editor/activeSegmentPropValue",
    }),

    type() {
      return this.PAGE_DATA.type;
    },

    options() {
      const options = [
        {
          label: "Integrate",
          value: "integration",
        },
      ];

      if (this.type == "promo_page" || this.type == "product_page") {
        options.push({ label: "Open Product Page", value: "product_page" });
      }

      if (this.type == "promo_page") {
        options.push({
          label: "Open Coupon Redemption Popup",
          value: "open_modal",
        });
      }

      return options;
    },
  },
  methods: {
    integrationOptions() {
      return integrations(["payment"], this.PAGE_DATA.workspaceId);
    },

    onClickSideEffect(props) {
      const affected = {
        "xOn:click": null,
        link: null,
        integrationId: null,
      };

      switch (props.onClick) {
        case "product_page":
          affected.link = ":::redirection_url:::";
          affected["xOn:click"] = null;
          break;

        case "open_modal":
        case "integration":
        case "":
          affected.link = null;
          affected["xOn:click"] = null;
          break;
      }
      return { props: affected };
    },
  },
};
</script>
