import Vue from "vue";

/**
 * Auto Register Icons
 */
const icons = import.meta.glob("@/icons/*.vue");
Object.keys(icons).forEach(function (key) {
  const path = key.split("/");
  const name = path[path.length - 1].split(".")[0];
  Vue.component("Icon" + name, icons[key]);
});
