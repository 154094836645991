<template>
  <IAccordion title="Size">
    <div class="pm-grid pm-grid-cols-2 pm-gap-3">
      <ISelect label="Width" prop="w" :options="$options.w" />
      <ISelect label="Height" prop="h" :options="$options.h" />
    </div>
  </IAccordion>
</template>

<script>
import theme, { themeFn } from "@/config/tailwind-theme";

const hLabels = {
  auto: "Auto",
  px: "1px",
  full: "Full",
  screen: "Screen",
  min: "Min Content",
  max: "Max Content",
  fit: "Fit Content",
};

const h = Object.keys(theme.height({ theme: themeFn })).map((key) => {
  return {
    label: hLabels[key] || key,
    value: key,
  };
});

const wLabels = {
  auto: "Auto",
  px: "1px",
  full: "Full",
  screen: "Screen",
  min: "Min Content",
  max: "Max Content",
  fit: "Fit Content",
};

const w = Object.keys(theme.width({ theme: themeFn })).map((key) => {
  return {
    label: wLabels[key] || key,
    value: key,
  };
});

export default {
  w,
  h,
};
</script>
