export const uid = function () {
  return Math.random().toString(36).substr(2, 9);
};


/**
 * ^ - start of string
 * \[ - match [
 * () - capture group
 * \-? - optional negative sign
 * \d* - optional digits
 * (\w+|%)) - match word characters or % sign
 * \] - match ]
 * $ - end of string
 */
const arbitraryRegEx = /^\[(\-?\d*(\w+|%))\]$/;
export function isArbitrary(value) {
  if(typeof value !== 'string') return false
  return arbitraryRegEx.test(value);
}


/**
 * (\-?\d*) - optional negative sign followed by optional digits gives us the value in digits
 * (\w+|%) - match word characters or % sign gives us the unit
 */
const splitValueAndUnitRe = /\[(\-?\d*)(\w+|%)\]/g
export function arbitraryVariantValue(value) {
  if(!isArbitrary(value)) return [];
  return value.replace(splitValueAndUnitRe, '$1|$2').split('|');
}

