<template>
  <div class="pm-p-4 pm-space-y-3">
    <PField label="Embed Type">
      <PSelect
        :value="source"
        @input="$emit('input', { value: null, source: $event })"
        :options="$options.types"
      />
    </PField>

    <!-- EXTERNAL URL -->
    <PField label="URL" v-if="source == 'external'">
      <PTextbox
        :value="value"
        placeholder="e.g. https://images.unsplash.com/photo-1525547719571-a2d4ac8945e2"
        @input="$emit('input', { value: $event, source: 'external' })"
        language="html"
      />
    </PField>

    <!-- SVG -->
    <PField
      label="SVG"
      v-if="source == 'svg'"
      desc="Paste an inline SVG Code here."
    >
      <div class="pm-mt-1 pm-h-96">
        <PCode
          :value="value"
          @input="$emit('input', { value: $event, source: 'svg' })"
          language="html"
        />
      </div>
    </PField>

    <!-- Custom Code -->
    <PField
      label="Code"
      v-if="source == 'embed'"
      desc="You can paste 3rd party embed codes here."
    >
      <div class="pm-mt-1 pm-h-96">
        <PCode
          :value="value"
          @input="$emit('input', { value: $event, source: 'embed' })"
          language="html"
        />
      </div>
    </PField>
  </div>
</template>

<script>
export default {
  props: {
    source: String,
    value: String,
  },
  types: [
    {
      label: "External URL",
      value: "external",
    },
    {
      label: "Custom Code",
      value: "embed",
    },
    {
      label: "Inline SVG",
      value: "svg",
    },
  ],
};
</script>
