<template>
  <IuiField :label="label" :Responsive="hasResponsive" :disabled="isDisabled">
    <IuiColor :label="label" :value="value" @click="$slideover.toggle(`color-picker-${prop}`)" @update="update(prop)">
      <template #color>
        <Color class="pm-h-7 pm-w-7 pm-mr-2" :value="value" :label="`Brand - ${$options.startCase(value)}`" />
      </template>
    </IuiColor>

    <Slideover :id="`color-picker-${prop}`" title="Select Color" :size="300">
      <PColorPicker :key="prop" :value="value" @input="update(prop, $event)" />
    </Slideover>

  </IuiField>
</template>

<script>
import inspectorInput from "@/mixins/inspector-input";
import { startCase } from "lodash-es";
import Color from "@/components/interfaces/color-picker/Color.vue";
import { IuiColor, IuiField } from "inspector-ui"

export default {
  startCase,
  mixins: [inspectorInput],
  components: {
    Color,
    IuiColor,
    IuiField
  },
  props: {
    label: {
      type: String,
      default: "Color",
    },
  },
};
</script>
