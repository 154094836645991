import Vue from "vue";
import PField from "@/components/interfaces/Field";
import PTextarea from "@/components/interfaces/Textarea";
import PTextbox from "@/components/interfaces/Textbox";
import PCode from "@/components/interfaces/Code";
import PButton from "@/components/interfaces/Button";
import PSelect from "@/components/interfaces/Select";
import PLabel from "@/components/interfaces/Label";
import PToggle from "@/components/interfaces/Toggle";
import PBadge from "@/components/interfaces/Badge";
import PColorPicker from "@/components/interfaces/color-picker/Index";
import Icon from "@/components/interfaces/Icon";
import Tab from "@/components/interfaces/Tab";
import PRadioButton from "@/components/interfaces/RadioButton";

Vue.component("PField", PField);
Vue.component("PTextarea", PTextarea);
Vue.component("PTextbox", PTextbox);
Vue.component("PCode", PCode);
Vue.component("PButton", PButton);
Vue.component("PSelect", PSelect);
Vue.component("PLabel", PLabel);
Vue.component("PToggle", PToggle);
Vue.component("PBadge", PBadge);
Vue.component("PColorPicker", PColorPicker);
Vue.component("Icon", Icon);
Vue.component("Tab", Tab);
Vue.component("PRadioButton", PRadioButton);
