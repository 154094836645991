<template>
  <Loader
    :message="
      buildingAssets ? 'Hold it right there. We are building assets...' : null
    "
    v-if="loading"
  />
  <div
    v-else
    class="pagemaker"
    :class="{
      'pagemaker--hide-panes': !panes,
    }"
    id="pagemaker"
  >
    <TheHeader />
    <Navigator v-show="panes" />
    <Editor />
    <Inspector :key="inspectorKey" v-show="panes" />
    <AddModuleTypeSelector />
    <ShortcutModal />
    <ModuleList />
    <Confirm />
    <BrowserCheck />

    <div class="pm-relative pm-z-20">
      <PortalTarget id="modal-container" name="modal-container" />
      <PortalTarget id="confirm-container" name="confirm-container" />
      <PortalTarget id="slideover-container" name="slideover-container" />
      <Notification />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import bus from "@/bus";
import fetchTemplate from "@/api/fetchTemplate";
import TheHeader from "./header/Index";
import Editor from "./editor/Index";
import Navigator from "./navigator/Index";
import Inspector from "./inspector/Index";
import ModuleList from "./add-module/List";
import AddModuleTypeSelector from "./add-module/TypeSelector";
import Confirm from "./Confirm";
import Notification from "./Notification";
import Loader from "./Loader";
import ShortcutModal from "../components/inspector/Shortcuts.vue";
import BrowserCheck from "./BrowserCheck";

/**
 * required keys in response:
 * "modules", "type", "hash", "colors", "font", "css", "variables"
 *
 * type
 * type: String,
 * Type of a landing page defines some of the features of editor like
 * Promo Code Model Opening
 * Content Switching option when variables are defined
 * Possible values are "product_page", "promo_page","landing_page"
 *
 * variables
 * type: Object,
 * The variables contains key value pairs which can be used for anything for placing content dynamically
 * currently it is used for text content.
 * When content is written in variable mode, the values will be rendered from this object.
 */


export default {
  props: {
    pageData: Object,
  },

  provide() {
    return {
      PAGE_DATA: this.pageData,
      HEADER: this.header,
    };
  },

  components: {
    TheHeader,
    Editor,
    Navigator,
    Inspector,
    ModuleList,
    AddModuleTypeSelector,
    Confirm,
    Notification,
    Loader,
    ShortcutModal,
    BrowserCheck,
  },

  computed: {
    ...mapGetters({
      activeSegmentName: "editor/activeSegmentName",
      activeSegmentModuleUuid: "editor/activeSegmentModuleUuid",
      activeSegmentIndex: "editor/activeSegmentIndex",
      modulesLoading: "modules/loading",
      interruptReload: "editor/interruptReload",
      modules: "modules/modules",
    }),

    inspectorKey() {
      return `${this.activeSegmentModuleUuid}--${this.activeSegmentName}--${this.activeSegmentIndex}`;
    },
  },

  data() {
    return {
      /**
       * Create object here to keep
       * reactivity in the Injection.
       */
      header: {
        name: null,
        slug: null,
        draftUrl: null,
        shopifyUrl: null
      },
      loading: true,
      variables: null,
      buildingAssets: false,
      error: false,
      panes: true,
    };
  },

  created() {
    this.start();

    bus.$on("restart", () => {
      this.setInterruptReload(false);
      this.$nextTick(() => {
        window.location.reload();
      });
    });
  },

  mounted() {
    bus.$on("panes-toggle", () => (this.panes = !this.panes));
  },

  watch: {
    modulesLoading(newValue) {
      this.$emit("loading", newValue);
    },
  },

  methods: {
    ...mapMutations({
      setTemplate: "template/set",
      setModules: "modules/set",
      setInterruptReload: "editor/setInterruptReload",
      setRefreshingModules: "modules/setRefresh",
      setUnsaved: "editor/setUnsaved",
    }),

    async start() {
      this.loading = true;

      try {

        const res = await fetchTemplate(this.pageData);

        const {
          campaignCss,
          commonJs,
          template: { css, fonts, colors, js },
          campaign: { hash, type },
          name,
          slug,
          modules,
          variables,
          draftUrl,
          shopifyUrl,
        } = res;

        this.header.name = name;
        this.header.slug = slug;
        this.header.draftUrl = draftUrl;
        this.header.shopifyUrl = shopifyUrl;

        //Hydrate Store
        this.setTemplate({
          campaignCss,
          fonts,
          css,
          type,
          hash,
          colors,
          variables,
          commonJs,
          js
        });
        this.setModules(modules);
      } catch (err) {
        this.$sentry.catch(err);
        console.error(err);
        this.$notify({
          title: "There was an error!",
          message:
            "Please refresh & try again. If the issue persist, contact our support.",
          type: "danger",
          duration: 3000,
        });
      }

      this.setUnsaved(false);

      /**
       * Testing this timeout to see if the content update issue is created by
       * the time taken for segments to be updated in store.
       *
       * If that is the case, the module may not find the segments and emit the default segments.
       * By this scenario, the segments returned from API may be ignored and user will face the issue of
       * content not updating or content lost.
       */
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss">
.pagemaker {
  --pmHeaderSize: 36px;
  --pmInspectorSize: 200px;
  --pmNavigatorSize: 200px;

  --pmHighlightLightest: #ddffcc;
  --pmHighlightLighter: #b3ff99;
  --pmHighlightLight: #80ff66;
  --pmHighlight: #00ff00;
  --pmHighlightDark: #00b71f;
  --pmHighlightDarker: #009326;
  --pmHighlightDarkest: #007a2a;
  --pmHighlightSize: 1px;

  display: grid;
  grid-template-columns: var(--pmNavigatorSize) 1fr var(--pmInspectorSize);
  grid-template-rows: var(--pmHeaderSize) 1fr;
  @apply pm-fixed pm-inset-0 pm-font-content;
}

.pagemaker--hide-panes {
  --pmNavigatorSize: 0px;
  --pmInspectorSize: 0px;
  .editor,
  .header {
    grid-column: 1 / 4;
  }
}

.pagemaker__reloading {
  @apply pm-fixed pm-inset-0 pm-bg-white pm-bg-opacity-80 pm-z-20;
}
</style>
