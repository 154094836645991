<template>
  <div >
    <ISwitch
      label="Variable"
      prop="variable"
    />
    <ITextarea
      v-if="isVariable"
      class="pm-mt-4"
      prop="content"
      name="content"
    />
    <IuiButton
      class="pm-text-xxs"
      label="How to use variables ?"
      @click.native="$modal.open('variable-info-modal')"
    />
    <VariableInfo />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { IuiButton } from 'inspector-ui';
import VariableInfo from "../../VariableInfo.vue";
export default {
  components: {
    IuiButton,
    VariableInfo
  },
  data() {
    return {
      options: [
        { value: ":::desc:::", id: "content-desc", label: "Description" },
        { value: ":::features:::", id: "content-features", label: "Features" },
        { value: ":::name:::", id: "content-name", label: "Product Name" },
        {
          value: ":::sale_price:::",
          id: "content-sale_price",
          label: "Sale Price",
        },
        {
          value: ":::list_price:::",
          id: "content-list_price",
          label: "List Price",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      activeSegmentPropValue: "editor/activeSegmentPropValue",
    }),
    isVariable() {
      return this.activeSegmentPropValue("variable");
    },
  },
  methods: {
    valueAdaptor(value) {
      //If the predefined text is not used, the custom string will be provided
      // Hence return "" to select proper value from dropdown
      if (value?.startsWith(":::")) {
        return value;
      } else {
        return "";
      }
    },
  },
};
</script>
