<template>
    <div>
      <PButton
        label="Open Editor"
        class="pm-mb-2"
        size="sm"
        icon="MdiFullscreen"
        @click.native="$emit('fullscreen')"
        theme="gray"
        full
      />
      <div class="pm-h-64">
        <slot name="pcCode"></slot>
      </div>
      <Modal id="code-modal" #default="{close}" @open="$emit('open')">
        <ModalShell footer title="Code Editor" size="fs" :close="close">
          <template #default>
            <div class="pm-p-2 pm-h-full">
              <slot name="pcCodeFull"></slot>
            </div>
          </template>
    
          <template #footer-actions>
            <PButton label="Cancel" @click.native="$emit('cancel')" theme="gray" />
            <PButton label="Save" @click.native="$emit('save')" />
          </template>
        </ModalShell>
      </Modal>
    </div>
</template>

<script>


export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      valueInModal: this.value,
    };
  },
  methods: {
    fullscreen() {
      bus.$emit("modal-open", "code-modal");
    },
    save() {
      this.update("value", this.valueInModal);
      bus.$emit("modal-close", "code-modal");
    },
    cancel() {
      bus.$emit("modal-close", "code-modal");
    },
  },
};
</script>
