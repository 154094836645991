<template>
  <div class="navigator-header">
    <img
      class="pm-h-full pm-cursor-pointer"
      src="/img/icon.svg"
      alt=""
      @click="exit"
    />
    <div class="flex flex-col">
      <h1
        class="pm-pl-2 pm-text-xs pm-truncate pm-font-medium pm-leading-none pm-overflow-hidden"
      >
        {{ HEADER.name }}
      </h1>
    </div>
  </div>
</template>

<script>
import bus from "@/bus";

export default {
  inject: ["HEADER"],
  methods: {
    exit() {
      bus.$emit("exit");
    },
  },
};
</script>

<style lang="scss">
.navigator-header {
  height: var(--pmHeaderSize);
  @apply pm-flex pm-items-center pm-border-b pm-border-gray-200 pm-p-2 pm-flex-nowrap;
}
</style>
