<template>
    <div class="pm-flex pm-flex-nowrap pm-mt-1">
        <IuiButtonIcon
          v-for="option in options"
          :key="`button-${option.value}`"
          :icon="option.icon"
          :active="value == option.value"
          :title="option.label"
          @click.native="$emit('click', option)"
        />
    </div>
</template>

<script>

import IuiButtonIcon from "./ButtonIcon.vue";
import IuiButton from "./Button.vue";

export default {
  components: {
    IuiButtonIcon,
    IuiButton
  },
  props: {
    options: Array,
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
  },
};
</script>
