<template>
  <Slideover
    id="field-manager"
    :title="`Field - ${localValue && localValue.name}`"
    @close="afterClose()"
    @open="afterOpen()"
  >
    <ValidationObserver
      v-if="localValue"
      tag="form"
      ref="form"
      v-slot="{ invalid }"
    >
      <div class="pm-space-y-4 pm-p-4">
        <ValidationProvider
          :rules="{
            required: true,
            regex: /^[A-Za-zÀ-ÿ0-9_-]+$/,
            fieldName: otherFieldNames,
          }"
          name="Name"
          slim
        >
          <PField label="Name">
            <PTextbox
              theme="underline"
              v-model="localValue.name"
              :disabled="locked.includes('name')"
              desc="Value should only include: Alphanumeric, - & _"
            />
          </PField>
        </ValidationProvider>

        <ValidationProvider rules="required" name="Type" slim>
          <PField label="Type">
            <PSelect
              theme="underline"
              :options="fieldTypes"
              v-model="localValue.type"
              :deselect="false"
              :disabled="locked.includes('type')"
            />
          </PField>
        </ValidationProvider>

        <PField v-if="hasPlacehoder" label="Placeholder">
          <PTextbox
            type="text"
            theme="underline"
            v-model="localValue.placeholder"
            :disabled="locked.includes('placeholder')"
          />
        </PField>

        <PField v-if="hasValue" label="Value">
          <PTextbox
            type="text"
            theme="underline"
            v-model="localValue.value"
            :disabled="locked.includes('value')"
          />
        </PField>

        <ValidationProvider
          v-if="hasOptions"
          rules="required"
          name="Options"
          slim
        >
          <PField
            label="Options"
            theme="underline"
            desc="Provide comma separated values. E.g. India,Canada,America "
          >
            <PTextarea
              :rows="5"
              :value="localValue.options && localValue.options.join(',')"
              @input="localValue.options = $event.split(',')"
              :disabled="locked.includes('options')"
            />
          </PField>
        </ValidationProvider>

        <PField
          inline
          label="Required"
          desc="Users will not be able to submit form without filling this field."
        >
          <PToggle
            v-model="localValue.required"
            :id="`required-${localValue.name}`"
            label=""
            size="xxs"
            :disabled="locked.includes('required')"
          />
        </PField>

        <PField
          inline
          label="Unique"
          desc="Allow only single value for each submission. For example, enable this if you want users to submit an email only once."
        >
          <PToggle
            v-model="localValue.unique"
            :id="`unique-${localValue.name}`"
            label=""
            size="xxs"
            :disabled="locked.includes('unique')"
          />
        </PField>
      </div>

      <div class="pm-grid pm-grid-cols-2 pm-gap-2 pm-px-4">
        <PButton
          :disabled="invalid"
          color="primary"
          size="sm"
          label="Save"
          @click.native="save()"
        />
        <PButton
          :disabled="localValue.removable === false"
          theme="gray"
          label="Remove"
          size="sm"
          @click.native="remove(localValue.index)"
        />
      </div>
    </ValidationObserver>
  </Slideover>
</template>

<script>
import inspectorInput from "../../../../mixins/inspector-input";
import { mapGetters, mapActions } from "vuex";

const defaultFieldValues = {
  name: "new_field",
  type: "text",
  placeholder: null,
  options: null,
  required: false,
  unique: false,
  removable: true,
};

export default {
  mixins: [inspectorInput],
  props: {
    field: Object,
  },

  watch: {
    field: {
      deep: true,
      handler(newValue) {
        this.init(newValue);
      },
    },
    localValue: {
      deep: true,
      handler(newValue) {
        /*
         *  If hidden type field has default value and type of the field
         *  is changed to another then it will add the default value
         *  which will create an issue. So here the value field is set null
         *  if type is not hidden.
         * */
        if (newValue.type != "hidden" && newValue.value) {
          newValue.value = null;
        }
      },
    },
  },

  data() {
    return {
      localValue: null,
      fieldTypes: [
        {
          label: "Text",
          value: "text",
        },
        {
          label: "Email",
          value: "email",
        },
        {
          label: "Number",
          value: "number",
        },
        {
          label: "Date",
          value: "date",
        },
        {
          label: "Dropdown",
          value: "select",
        },
        {
          label: "Radio",
          value: "radio",
        },
        {
          label: "Checkbox",
          value: "checkbox",
        },
        {
          label: "Textarea",
          value: "textarea",
        },
        {
          label: "Hidden",
          value: "hidden",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      propValueFinder: "editor/activeSegmentPropValue",
    }),

    fields() {
      return this.propValueFinder("fields");
    },

    hasOptions() {
      return ["select", "radio", "checkbox"].includes(this.localValue.type);
    },

    hasPlacehoder() {
      return ["select", "text", "email", "number", "textarea"].includes(
        this.localValue.type
      );
    },

    locked() {
      return this.localValue.locked || [];
    },

    otherFieldNames() {
      /**
       * Need to use this.value.name for comparison here because
       * this.localValue.name is used in v-model and value may
       * change with the inputs given by user
       */
      return this.fields
        ?.map((item) => item.name)
        .filter((name) => name != this.field?.name);
    },

    hasValue() {
      return ["hidden"].includes(this.localValue.type);
    },
  },

  created() {
    this.init();
  },

  methods: {
    init(newValue) {
      this.$set(this, "localValue", {
        ...defaultFieldValues,
        name: `new_field_${this.fields.length + 1}`,
        ...(newValue || {}),
      });
    },

    async save() {
      const isValid = await this.$refs.form.validate();
      if (isValid) {
        this.input(this.localValue);
      }
    },

    input(field) {
      const newValue = [...this.fields];

      /**
       * Need to remove index here because that should not be added in final config.
       * Index was used to find the field position only
       */
      const index = field.index;
      delete field.index;

      if (index != null) {
        newValue[index] = field;
      } else {
        newValue.push(field);
      }

      this.update("fields", newValue);
      this.$slideover.close("field-manager");
    },

    afterOpen() {
      this.$nextTick(() => {
        this.$refs.form?.validate();
      });
    },

    afterClose() {
      this.init();
      this.$emit("close");
    },

    remove(index) {
      const newValue = [...this.propValueFinder("fields")];
      newValue.splice(index, 1);
      this.update("fields", newValue);
      this.$slideover.close("field-manager");
    },
  },
};
</script>
