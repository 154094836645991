<template>
  <div class="module-tags">
    <TabButton
      label="All"
      :active="!value"
      @click.native="$emit('input')"

    />
    <TabButton
      v-for="item in items"
      :key="item.value"
      @click.native="$emit('input', item.id)"
      :active="value == item.id"

      :label="item.name"
    />
  </div>
</template>

<script>
import TabButton from "../interfaces/TabButton";

export default {
  components: {
    TabButton,
  },
  props: {
    items: Array,
    value: [Number, String],
  },
};
</script>

<style lang="scss">
.module-tags {
  @apply pm-font-semibold pm-pt-2;
}
</style>
