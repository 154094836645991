<template>
  <div>
    <div
      class="pm-group pm-flex pm-h-7 pm-w-full pm-items-stretch pm-whitespace-nowrap pm-text-left"
      @mouseenter="hover(layer)"
      @mouseleave="hoverOut(layer)"
      :class="{
        'before:pm-content-[\' \'] pm-bg-primary-50 pm-text-primary-500 before:pm--ml-px before:pm-w-px before:pm-bg-primary-500':
          isActive,
        'pm-text-gray-600 hover:pm-bg-gray-100': !isActive,
      }"
    >
      <!-- TOGGLE -->
      <button
        v-if="layer.children.length"
        class="pm-flex pm-w-5 pm-items-center pm-justify-center"
      >
        <Icon
          class="pm-h-4 pm-w-4 hover:pm-text-primary-500"
          :class="{
            'pm-rotate-0': isOpen,
            'pm--rotate-90': !isOpen,
          }"
          name="MaterialSymbolsArrowDropDown"
          @click.native="isOpen = !isOpen"
        />
      </button>

      <!-- SELECT -->
      <button
        class="pm-flex pm-grow pm-items-center pm-space-x-1 pm-text-left pm-text-xxs"
        :class="{ 'pm-pl-3': !layer.children.length }"
        @click="select()"
      >
        <Icon class="pm-h-3 pm-w-3" :name="icon" />
        <span>{{ layer.label }}</span>
        <span v-if="layer.refIndex != null"> {{ layer.refIndex + 1 }}</span>
      </button>

      <!-- SELECT GROUP -->
      <button
        v-if="isGroup && activeSegmentProps?.disableGroupEditing != true"
        class="pm-invisible pm-ml-auto pm-flex pm-h-7 pm-w-7 pm-items-center pm-justify-center pm-text-gray-500 group-hover:pm-visible"
        @click="select(true)"
        v-tooltip="{
          content: 'Select Similar Items',
          delay: { show: 1000 },
        }"
      >
        <Icon class="pm-h-3 pm-w-3" name="MdiGroup" />
      </button>

      <!-- VISIBILITY -->
      <button
        class="pm-flex pm-h-7 pm-w-7 pm-items-center pm-justify-center"
        @click="toggle()"
        :class="{
          'pm-invisible pm-text-gray-500 group-hover:pm-visible': !isHidden,
          'pm-text-warning-500': isHidden,
        }"
        v-tooltip="{
          content: isHidden ? 'Show Layer' : 'Hide Layer',
          delay: { show: 1000 },
        }"
      >
        <Icon class="pm-h-3 pm-w-3" :name="isHidden ? 'MdiEyeOff' : 'MdiEye'" />
      </button>
    </div>

    <slot v-if="isOpen" />
  </div>
</template>

<script>
import bus from "@/bus";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import segmentConfig from "@/config/segments";

export default {
  props: {
    layer: {
      type: Object,
    },
    isActive: {
      type: Boolean,
    },
    moduleUuid: {
      type: String,
    },
  },

  data() {
    return {
      isOpen: true,
    };
  },

  computed: {
    ...mapState({
      activeSegmentIndex: (state) => state.editor.activeSegmentIndex,
      activeSegmentName: (state) => state.editor.activeSegmentName,
      activeSegmentModuleUuid: (state) => state.editor.activeSegmentModuleUuid,
    }),

    ...mapGetters({
      segments: "modules/segments",
      activeSegmentProps: "editor/activeSegmentProps",
    }),

    icon() {
      return segmentConfig[this.layer.tag]?.icon;
    },

    isGroup() {
      return this.layer.refIndex != null;
    },

    isHidden() {
      const segment = this.segments[this.moduleUuid].find(
        (segment) => segment.name == this.layer.name
      );

      /**
       * We need to give first priority to "isHidden" prop set at common level and
       * after that look for it at individual level by using the ref index.
       */
      if (
        segment?.props.isHidden ||
        segment?.propsByIndex?.[this.layer.refIndex]?.isHidden
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    ...mapMutations({
      setActiveSegment: "editor/setActiveSegment",
    }),

    ...mapActions({
      updateSegment: "modules/updateSegment",
    }),

    toggle() {
      // Check group editing is on or not
      var group = false;
      if (
        this.activeSegmentModuleUuid == this.moduleUuid &&
        this.layer.name == this.activeSegmentName
      ) {
        if (this.activeSegmentIndex == null) {
          group = true;
        }
      }

      this.updateSegment({
        props: {
          isHidden: !this.isHidden,
        },
        config: {
          moduleUuid: this.moduleUuid,
          segmentName: this.layer.name,
          segmentIndex: this.layer.refIndex,
          tag: this.layer.tag,
          group,
        },
      });
    },

    hover() {
      bus.$emit("segment-hover", {
        moduleUuid: this.moduleUuid,
        segmentName: this.layer.name,
        segmentIndex: this.layer.refIndex,
      });
    },

    hoverOut() {
      bus.$emit("segment-hover");
    },

    select(group = false) {
      const payload = {
        moduleUuid: this.moduleUuid,
        segmentName: this.layer.name,
        segmentIndex: group ? null : this.layer.refIndex,
      };

      this.setActiveSegment(payload);
      this.$nextTick(() => {
        bus.$emit("segment-scroll", payload);
      });
    },
  },
};
</script>
