<template>
  <Portal v-if="isOpen" to="slideover-container" slim>
    <div
      :id="id"
      class="slideover"
      :class="[`slideover--${position}`]"
      :style="{ width: size + 'px' }"
    >
      <div
        class="pm-flex pm-items-center pm-justify-between pm-border-gray-200 pm-border-b"
      >
        <p class="pm-text-gray-500 pm-text-xs pm-px-2">{{ title }}</p>
        <IButtonIcon icon="MdiClose" @click.native="close(id)" />
      </div>

      <!-- Loading -->
      <div
        class="pm-flex pm-justify-center pm-items-center pm-h-full"
        v-if="loading"
      >
        <Icon
          name="MdiLoading"
          class="pm-animate pm-animate-spin pm-w-8 pm-h-8 pm-text-gray-300"
        />
      </div>

      <!-- Slots -->
      <template v-else>
        <div class="pm-flex-grow pm-overflow-auto">
          <slot />
        </div>

        <div v-if="$scopedSlots.footer" class="pm-border-t pm-p-2">
          <slot name="footer" />
        </div>
      </template>
    </div>
  </Portal>
</template>

<script>
import bus from "@/bus";

export default {
  props: {
    id: String,
    size: {
      type: Number,
      default: 200,
    },
    position: {
      type: String,
      default: "right",
    },
    title: String,
    loading: Boolean,
  },
  data() {
    return {
      isOpen: false,
      scope: null,
      autoClose: true,
    };
  },

  mounted() {
    bus.$on("slideover-open", (id, scope) => {
      this.open(id);
      this.scope = scope;
    });

    bus.$on("slideover-close", (id) => {
      this.close(id);
      this.scope = null;
    });

    bus.$on("slideover-toggle", (id) => {
      this.toggle(id);
    });
  },

  methods: {
    open(id) {
      if (!id) return;
      if (this.id == id) {
        this.isOpen = true;
        this.$emit("open");
      } else {
        this.isOpen = false;
      }
    },
    close(id) {
      let shouldClose = false;
      if (id) {
        if (this.id == id) {
          shouldClose = true;
        }
      } else {
        shouldClose = true;
      }
      if (shouldClose) {
        this.isOpen = false;
        this.$emit("close");
      }
    },
    toggle(id) {
      if (this.id == id) {
        bus.$emit("slideover-close");
        if (this.isOpen) {
          this.close(id);
        } else {
          this.open(id);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.slideover {
  width: 200px;
  top: var(--pmHeaderSize);
  bottom: 0;
  @apply pm-fixed pm-bg-white pm-flex-col pm-flex;
}

.slideover--left {
  box-shadow: 30px 30px 30px 0 rgba(0, 0, 0, 0.2);
  left: var(--pmNavigatorSize);
}
.slideover--right {
  box-shadow: -30px 30px 30px 0 rgba(0, 0, 0, 0.2);
  right: var(--pmInspectorSize);
}
</style>
