<template>
  <div v-if="presetsAvailableFor.includes(tag)">
    <div
      class="
        pm-border-b pm-border-gray-200 pm-p-3
        hover:pm-bg-gray-100
        pm-cursor-pointer
      "
      @click="$slideover.open('presets')"
    >
      <div class="pm-flex pm-flex-nowrap pm-items-center">
        <Icon
          name="MdiCreation"
          class="pm-w-4 pm-h-4 pm-mr-1 pm-text-gray-500"
        />
        <p class="pm-text-xs pm-font-medium pm-leading-none">Presets</p>
      </div>
      <p class="pm-text-xxs pm-text-gray-500 pm-leading-none pm-mt-1">
        Select from pre-designed styles
      </p>
    </div>

    <List />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import bus from "@/bus";
import List from "./List";

export default {
  components: {
    List,
  },

  data() {
    return {
      presetsAvailableFor: ["pm-button"],
    };
  },

  computed: {
    ...mapGetters({
      tag: "editor/activeSegmentTag",
    }),
  },
};
</script>
