<template>
  <Portal v-if="isOpen" to="modal-container" slim>
    <div :id="id" class="modal" :class="classList">
      <div class="modal__overlay" @click.self="overlayClose"></div>
      <div class="modal__wrapper">
        <slot :close="close" :data="scope"></slot>
      </div>
    </div>
  </Portal>
</template>

<script>
import bus from "@/bus";

export default {
  props: {
    id: String,
    closeOnOverlay: {
      type: Boolean,
      default: true,
    },
    closeOnEsc: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
      scope: null,
    };
  },

  computed: {
    classList() {
      const classes = [];
      if (this.isOpen) classes.push(`modal--show`);
      return classes;
    },
  },

  mounted() {
    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 27 && this.closeOnEsc) {
        this.close();
      }
    });

    bus.$on("modal-open", (id, scope) => {
      this.scope = scope;
      this.open(id);
    });

    bus.$on("modal-close", (id) => {
      this.scope = null;
      this.close(id);
    });
  },

  methods: {
    open(id) {
      if (!id) return;
      if (this.id == id) {
        this.isOpen = true;
        this.$emit("open", this.scope);
      } else {
        this.isOpen = false;
      }
    },
    close(id) {
      let shouldClose = false;
      if (id) {
        if (this.id == id) {
          shouldClose = true;
        }
      } else {
        shouldClose = true;
      }
      if (shouldClose) {
        this.isOpen = false;
        this.$emit("close");
      }
    },
    overlayClose() {
      if (this.closeOnOverlay) {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss">
.modal {
  @apply pm-fixed pm-inset-0 pm-z-40;
}
.modal__overlay {
  @apply pm-fixed pm-inset-0 pm-bg-black pm-bg-opacity-80;
}
.modal__wrapper {
  @apply pm-absolute pm-inset-0 pm-z-50 pm-m-auto pm-flex pm-items-center;
}
</style>
