<template>
  <div class="pm-text-center pm-p-12">
    <Icon
      class="pm-w-24 pm-h-24 pm-m-auto pm-text-gray-400"
      name="MdiAlertDecagram"
    />
    <h4 class="pm-text-gray-500 pm-text-2xl pm-font-bold">
      Feeling Empty
    </h4>

    <div class="pm-text-right">
      <p class="pm-text-gray-500">
        Start building a page by adding a module.
      </p>
    </div>

    <div class="pm-flex pm-justify-center pm-mt-4">
      <PButton icon="MdiPuzzle" @click.native="add()" label="Add Module" />
    </div>
  </div>
</template>

<script>
import bus from "@/bus";

export default {
  methods: {
    add() {
      bus.$emit("modal-open", "module-type-selector", 0);
    },
    template() {
      this.$modal.open("template-list");
    },
  },
};
</script>
