<template>
  <div
    class="field"
    :class="{ 'field--inline': inline, 'field--disabled': disabled }"
  >
    <PLabel>{{ label }}</PLabel>
    <div class="field__input">
      <slot />
    </div>
    <p class="field__error" v-if="errorToDisplay">{{ errorToDisplay }}</p>
    <p v-if="desc" class="field__desc">{{ desc }}</p>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    desc: String,
    error: Array,
    inline: Boolean,
    disabled: Boolean,
  },
  computed: {
    errorToDisplay() {
      if (this.error) {
        return this.error;
      } else if (this.$parent.errors?.[0]) {
        return this.$parent.errors[0];
      }
      return "";
    },
  },
};
</script>

<style lang="scss">
.field__desc {
  @apply pm-text-xxs pm-text-gray-500 pm-mt-1;
}
.field__error {
  @apply pm-text-xxs pm-text-danger-500 pm-mt-1;
}

.field--inline {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: min-content min-content min-content;

  .label {
    grid-column: 1 / 2;
  }
  .field__input {
    grid-column: 2 / 3;
  }

  .field__desc {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    margin: 0;
  }

  .field__error {
    grid-row: 3 / 4;
    grid-column: 1 / 3;
  }
}
</style>
