<template>
  <Modal id="module-type-selector" #default="{ close, data }">
    <ModalShell title="What do you want to add?" :close="close">
      <div class="pm-grid pm-grid-cols-2 pm-bg-gray-200 pm-gap-px">
        <ModuleType
          v-for="(option, index) in options"
          :key="`option-${index}`"
          :icon="option.icon"
          :label="option.label"
          :desc="option.desc"
          @click.native="option.action(data)"
        />
      </div>
    </ModalShell>
  </Modal>
</template>

<script>
import bus from "../../bus";
import { mapActions } from "vuex";
import ModuleType from "./Type";

export default {
  components: {
    ModuleType,
  },

  data() {
    return {
      loading: false,
      options: [
        {
          label: "New Module",
          icon: "MdiToyBrick",
          desc: "Choose from hundreds of ready to use modules develop by Pagemaker Team.",
          action: (insertIndex) => {
            bus.$emit("modal-open", "module-list", { insertIndex });
          },
        },
        {
          label: "Saved Module",
          icon: "MdiFolderStar",
          desc: "Choose from already saved a module with your own customizations.",
          action: (insertIndex) => {
            bus.$emit("modal-open", "module-list", {
              insertIndex,
              category: "user-saved",
            });
          },
        },
        {
          label: "Global Module",
          icon: "MdiGlobeModel",
          desc: "Already created a Global Module? Quickly choose from a its list.",
          action: (insertIndex) => {
            bus.$emit("modal-open", "module-list", {
              insertIndex,
              category: "user-global",
            });
          },
        },
        {
          label: "Custom Code",
          icon: "MdiCodeNotEqualVariant",
          desc: "Coding Ninja? Write your own HTML, CSS and Javascript.",
          action: (insertIndex) => {
            this.insertModule({
              name: "custom-code",
              order: insertIndex,
            });
            bus.$emit("modal-close", "module-type-selector");
          },
        },
      ],
    };
  },

  methods: {
    ...mapActions({
      insertModule: "modules/insert",
    }),
  },
};
</script>
