<template>
  <div
    v-if="
      activeSegmentIndex != null &&
      activeSegmentProps.disableGroupEditing != true
    "
    class="pm-border-b pm-border-gray-200 pm-p-2"
  >
    <div class="pm-flex pm-items-center">
      <PButton
        label="Select Similar Layers"
        icon="MdiGroup"
        theme="gray"
        size="sm"
        full
        @click.native="setGroupEditing()"
        v-tooltip="{
          content:
            'Selects the similar items in module and updates all at once. Kindly note that it will overwrite the individual editing.',
          delay: { show: 1000 },
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState({
      activeSegmentIndex: (state) => state.editor.activeSegmentIndex,
    }),
    ...mapGetters({
      activeSegmentProps: "editor/activeSegmentProps",
    }),
  },
  methods: {
    ...mapMutations({
      setGroupEditing: "editor/setGroupEditing",
    }),
  },
};
</script>
