<template>
  <IAccordion :title="isGroup ? 'Media Group' : 'Media'" open>
    <div class="pm-space-y-3">
      <!-- When source is the gallery, this is the preview image and hence the value will come from the selected gallery items -->
      <div v-if="activeSegmentProps.source == 'gallery'">
        <p class="pm-text-xxs pm-text-warning-500">
          The gallery preview will be automatically displayed from the selected
          gallery items.
        </p>
      </div>

      <template v-else>
        <!-- Multiple Media -->
        <template v-if="isGroup">
          <IMediaGroup prop="value" />
          <INumber label="Items in Row" prop="itemsInRow" :min="1" :max="12" />
        </template>

        <!-- Single Media -->
        <template v-else>
          <IMedia prop="value" />
          <ITextbox label="Alt Attribute" prop="alt" />
        </template>

        <!-- SVG Color -->
        <IColor
          v-if="activeSegmentProps.source == 'svg'"
          label="SVG Color"
          prop="color"
        />
      </template>

      <!-- Ratio -->
      <div class="pm-grid pm-grid-cols-2 pm-gap-2">
        <ISelect label="Ratio Width" prop="ratioW" :options="$options.ratio" />
        <ISelect label="Ratio Height" prop="ratioH" :options="$options.ratio" />
      </div>

      <!-- Fit -->
      <ISelect label="Fit" :options="$options.mediaFit" prop="mediaFit" />

      <!-- Position -->
      <IPositionButton
        label="Position"
        prop="mediaPosition"
      />
    </div>
  </IAccordion>
</template>

<script>
import { mapGetters } from "vuex";
import { startCase } from "lodash-es";


const mediaFit = ["contain", "cover", "fill", "scale-down"].map((item) => {
  return {
    label: startCase(item),
    value: item,
  };
});

const ratio = [...Array(16)].map((_, i) => {
  return {
    label: i + 1,
    value: i + 1,
  };
});

export default {
  mediaFit,
  ratio,

  computed: {
    ...mapGetters({
      activeSegmentProps: "editor/activeSegmentProps",
      props: "editor/activeSegmentProps",
      tag: "editor/activeSegmentTag",
    }),
    isGroup() {
      return this.tag == "pm-media-group";
    },
  },
};
</script>
